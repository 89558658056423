<template>
    <el-dialog :show-close="false"
               element-loading-background="rgba(0, 0, 0, 0)"
               style="margin-top: 8vh; text-align: center"
               :close-on-click-modal="false"
               @close="()=>{ $emit('input', false); $emit('close', true) }"
               :visible="value">
        <div style="position: absolute; top: 50%; left: 0px; width: 100%">
            {{ $t('TEXT0207') }}
        </div>
    </el-dialog>
</template>

<script>
export default {
    name: "SuccessDialog",
    props: {
        value: {
            type: Boolean,
            default: false
        },
    },
}
</script>

<style scoped lang="scss">
::v-deep .el-loading-mask {
    background-color: transparent !important;
}

//::v-deep .el-loading-spinner {
//    position: absolute;
//    top: calc(50% + 40px);
//}

//body{
//    margin: 0px;
//}

::v-deep .el-dialog {
    width: 400px;
    height: 400px;
}

@media screen and (max-width: 992px) { // mobile
    ::v-deep .el-dialog {
        width: calc(100% - 20px);
        width: calc(100vw - 20px);
    }
}
</style>
