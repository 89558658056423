<template>
    <div id="app">
        <component :is="$route.meta.layout || 'MainLayout'">
            <!--        <component :is="$route.meta.layout || 'MainLayout'">-->
            <RouterView/>
        </component>
    </div>
</template>


<script>
    import MainLayout from "@/layout/MainLayout";
    import NoNavBarLayout from "@/layout/NoNavBarLayout";
    import NoNavBarLayout2 from "./layout/NoNavBarLayout2";
    import PrintLayout from "@/layout/PrintLayout";
    import Print from "@/views/Print";

    export default {
        components: {MainLayout, NoNavBarLayout, PrintLayout, Print, NoNavBarLayout2},
        mounted() {
            console.log("port: ")
            console.log(process.env.VUE_APP_API_PORT)
        }
    }
</script>

<style scoped lang="scss">
    @import "style/variables";


    #app {
        min-height: 100vh;
        background-color: #ebeef5;
    }

    ::v-deep .container {
        margin: auto;
    }

    ::v-deep .el-divider {
        margin-top: 0px !important;
    }

    ::v-deep .el-select .el-input.is-disabled .el-input__inner {
        -webkit-text-fill-color: #4F4F4F;
        opacity: 1 !important;
    }

    //::v-deep .container {
    //    width: calc(100vw - 20px);
    //}

    @media screen and (min-width: 576px) {
        ::v-deep .container {
            width: 540px;
        }
    }

    @media screen and (min-width: 768px) {
        ::v-deep .container {
            width: 720px;
        }
    }

    @media screen and (min-width: 992px) {
        ::v-deep .container {
            width: 996px;
        }
    }

    @media screen and (min-width: 1200px) {
        ::v-deep .container {
            width: 1140px;
        }
    }

    @media screen and (min-width: 1400px) {
        ::v-deep .container {
            width: 1320px;
        }
    }

    @media screen and (min-width: 1600px) {
        ::v-deep .container {
            width: 1500px;
        }
    }

    ::v-deep .el-dialog__body {
        word-break: normal;
    }

    ::v-deep .login-form {
        max-width: 400px;
        padding: 20px 32px;
        background-color: white;
        margin: auto;
        margin-top: 15%;
    }

    ::v-deep .clickable:hover {
        cursor: pointer;
    }

    ::v-deep .view-content {
        margin: 12px 55px;
        padding: 10px;
        background-color: white;
        min-height: calc(100vh - 250px);
    }

    ::v-deep .el-button--primary {
        background-color: map-get($color, bgPrimary);
        border-color: #44BCFF;
    }

    ::v-deep .el-button--primary:hover {
        background-color: map-get($color, bgPrimaryHover);
        border-color: #44BCFF;
    }

    ::v-deep .pointer {
        cursor: pointer;
    }

    ::v-deep .out-tooltip {
        position: absolute;
        right: 25px;
        top: -2px;
    }

    ::v-deep i.tooltip {
        color: map-get($color, bgPrimary);
        opacity: 0.7;
    }

    ::v-deep .required > label.el-form-item__label::after {
        /*position: absolute;*/
        /*bottom: 0px;*/
        margin-left: 2px;
        content: "*";
        margin-top: 50px;
        color: red;

    }

    ::v-deep .login-form h3 {
        text-align: center;
    }

    ::v-deep .login-form button {
        width: 100%;
        margin-bottom: 20px;
    }

    ::v-deep .el-dialog__title {
        color: #333333;
    }

    ::v-deep .el-form-item__label {
        color: #828282;
    }

    ::v-deep .el-input__inner {
        color: #4F4F4F;
    }

    ::v-deep .el-input .el-input__inner {
        border-radius: 6px;
    }

    ::v-deep .el-input__prefix {
        top: 0px;
        left: 9px;
        font-size: 26px;
    }

    ::v-deep .el-input__prefix > * {
        height: map-get(map-get($pc, input), height);
        line-height: map-get(map-get($pc, input), height);
    }

    ::v-deep .el-form-item__content {
        line-height: 20px;
    }

    ::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
        color: #606266;
    }

    ::v-deep .el-input--prefix .el-input__inner {
        padding-left: 36px;
    }

    ::v-deep .el-rate__icon {
        font-size: 24px;
    }

    ::v-deep .el-dialog__close {
        font-size: 24px;
    }

    ::v-deep .el-dialog__headerbtn {
        top: 19px;
    }

    ::v-deep input::-webkit-input-placeholder {
        text-transform: capitalize !important;
    }

    ::v-deep .shop-title {
        font-size: 23px;
        color: #333333;
    }

    ::v-deep .category-name {
        color: #4f4f4f;
    }
</style>
