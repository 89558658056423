<template>
    <div style="display: flex; height: calc(100vh - 76px); width: 100%">
<!--        <div class="container view-container" style="position: relative; display: flex">-->
<!--            <img class="free-image" style="position: absolute; top: 0px; left: 0px; "-->
<!--                 :style="{top: freeImg.top, left: freeImg.left}"-->
<!--                 :src="input.data.markImage"/>-->
<!--            <img class="animation" :src="input.data.startImage"-->
<!--                 style="vertical-align: middle"-->
<!--                 :style="{opacity: input.data.startImageOpacity}"-->
<!--                 @load="onStartImageLoad"/>-->
<!--        </div>-->
        <div class="loading" v-if="!input.data.eightSecPassed ">
            <img class="loading-image" :src="input.data.loadingImage" @load="onLoadingImageLoaded"/>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import {isMobile} from 'mobile-device-detect';
import EventBus from "@/config/EventBus";

export default {
    name: 'Home',
    computed: {
        foxMan: function () {
            return require("@/assets/fox-man.png")
        },


    },
    watch: {
        $route(to, from) {
            console.log(to)
            console.log(from)
            this.getLoadingImage()
        },
    },
    mounted() {
        EventBus.$on('localeChange', () => {
            if (isMobile) {
                this.input.data.startImageOpacity = 0;
            }
            this.getStartImage()
            this.getMarkImage()
        })
        this.input.data.loadingImage = null;
        this.getLoadingImage()
        this.getMarkImage()
    },
    data() {
        return {
            input: {
                data: {
                    eightSecPassed: false,
                    markImage: null,
                    startImage: null,
                    startImageOpacity: 1,
                    loadingImage: null,
                },
                styleEn: {
                    hideEn: this.hideEn
                },
                styleJp: {
                    hideJp: this.hideJp
                },
                hideEn: true,
                hideJp: false
            },
            showAnimation: false,
            pc: false,
            freeImg: {
                top: "0px",
                left: "0px",
            }
        }
    },
    methods: {
        onStartImageLoad: function () {
            console.log("loaded")
            this.input.data.startImageOpacity = 1;
            setTimeout(()=>{
                this.$router.push("/top");
            }, 7000);
        },

        preloadImage: function () {
            const image1 = new Image();
            image1.src = this.input.data.startImageJP;
            const image2 = new Image();
            image2.src = this.input.data.startImageEn;
        },
        getMarkImage: function () {

            if ((JSON.parse(localStorage.getItem('lang') || '{}').locale || 'en-us').toLocaleLowerCase() == 'en-us') {
                this.input.data.markImage = require('@/assets/free_EN.png');
                this.freeImg = {
                    top: "0px",
                    left: "0px",
                }
            } else {
                this.input.data.markImage = require('@/assets/free_JP.png');
                this.freeImg = {
                    top: "0px",
                    left: "-6px",
                }
            }
        },

        getStartImage: function () {
            if (!isMobile) {
                this.input.data.startImage = require('@/assets/gif/修正___1100x700__PC.gif');
            } else if ((JSON.parse(localStorage.getItem('lang') || '{}').locale || 'en-us').toLocaleLowerCase() == 'en-us') {
                this.input.data.startImage = require('@/assets/gif/修正___320x570__EN_mobile.gif') + "?time=" + Date.now();
            } else {
                this.input.data.startImage = require('@/assets/gif/Start_mobile_jp_E.gif') + "?time=" + Date.now();
            }
        },
        getLoadingImage: function () {
            this.input.data.loadingImage = null;
            if (!isMobile) {
                this.input.data.loadingImage = require('@/assets/gif/1100x700__最終提出gif20210225_2(0.gif') + '?time=' + Date.now()
            } else {
                this.input.data.loadingImage = require('@/assets/gif/loadinggif20210225_2(0.15).gif') + '?time=' + Date.now()
            }
        },
        onLoadingImageLoaded: function () {
            setTimeout(() => {
                this.input.data.startImageOpacity = 0;
                this.getStartImage()
                this.input.data.eightSecPassed = true
                this.showAnimation = true
                this.$router.push("/convert-katakana");
            }, 8000)
        }
    }
}
</script>
<style scoped lang="scss">
.view-container {
    background-color: white;
    margin-top: 40px;
    margin-bottom: 40px;
    padding: 60px 0px;
}

.animation {
    margin: auto;
    display: block;
    max-height: 500px;
    min-height: 500px;
    max-width: 100%;
}

.path {
    text-align: left;
    margin-left: auto;
    max-width: 580px;
    font-size: 14px;
    padding: 20px;
}

.loading {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: white;
    display: flex;
}

.loading-image {
    margin: auto;
    display: block;
    max-width: 100%;
    max-height: 70vh;
}

.hide {
    display: none;
}

.free-image {
    width: 150px;
    height: 140px
}

@media screen and (max-width: 991px) {

    .view-container {
        margin-top: 20px;
        margin-bottom: 20px;
        padding: 20px 0px;
    }

    .path {
        text-align: center;
        max-width: none;
    }

    .free-image {
        width: 80px;
        height: 76px
    }
}

</style>
