import axios from "@/config/axios";

export default {
    getToken:function (data){
        return axios({
            url: "/api/survey-end-user/token/check",
            method: 'post',
            data: data
        });
    }


}