<template>
    <div>
        <NavBar/>
        <RouterView/>

    </div>
</template>

<script>
import NavBar from "@/layout/component/NavBar";

export default {
    name: "MainLayout",
    components: {NavBar}
}
</script>

<style scoped>

</style>
