<template>
    <el-dialog :visible="value"
               class="policy-dialog"
               style="background-color: transparent"
               :width="dialogWidth"
               @close="$emit('input', false)">
        <div slot="title" id="content" class="content" ref="content">
            <div class="certificate-wrapper" style="position: relative; display: block; ">
                <div :style="size"></div>
                <div id="certificate" ref="certificate" class="certificate" :style="scale"
                     style="position: absolute; top: 50%; left: 50%;">
                    <div class="top-layout">
                        <div style="font-size: 32px; line-height: 33px;  font-weight: bold">CERTIFICATE</div>
                        <div style="font-size: 20px; line-height: 21px;  margin-top: 5px">認定証</div>
                        <div style="font-size: 24px; line-height: 25px; font-weight: bold; margin-top: 100px"> {{
                                "Dear " +
                                profile.fullName
                            }}
                        </div>
                        <div style="font-size: 16px; line-height: 17px;  font-weight: bold; margin-top: 15px">
                            {{ 'IDNo: ' + profile.id }}
                        </div>
                        <div style="font-size: 16px; line-height: 17px; margin-top: 60px; color: #4f4f4f;">
                            We will named the kanji name for you.
                        </div>

                        <div style="margin-top: 10px;">
                            <table cellspacing="0" style="margin: auto">
                                <tr>
                                    <td style="font-size: 16px; line-height: 17px; font-weight: bold; width: 125px; padding: 10px">
                                        Kanji
                                    </td>
                                    <td style=" font-size: 16px; line-height: 17px; font-weight: bold; width: 220px; padding: 10px;">
                                        Meaning
                                    </td>
                                </tr>
                                <tr v-for="(item, index) in kanjis" :key="index">
                                    <td style="font-size: 24px; line-height: 25px; font-weight: bold; padding: 10px">
                                        {{ item.kanji }}
                                    </td>
                                    <td style=" font-size: 16px; line-height: 17px; padding: 10px; line-height: 1.25; text-transform: lowercase">
                                        {{ item.meaning }}
                                    </td>
                                </tr>
                            </table>
                        </div>

                        <div style="margin-top: 10px;">
                            <table cellspacing="0" style="margin: auto">
                                <tr>
                                    <td style="font-size: 16px; line-height: 17px; width: 125px; padding: 10px; color: #4f4f4f;">
                                        Total number of strokes
                                    </td>
                                    <td style="font-size: 16px; line-height: 17px; width: 220px; padding: 10px; color: #4f4f4f;">
                                        {{
                                            kanjis.reduce((a, b) => a + b.strokeCount, 0)
                                        }}
                                    </td>
                                </tr>
                            </table>


                            <div style="margin-top: 10px; ">
                                <div
                                    style="padding: 10px; width: 450px; border: 1px solid #4f4f4f; margin: auto; text-align: left; ">
                                    <div style="font-size : 16px; line-height: 17px; color: #4f4f4f;">
                                        {{
                                            'Fortune of ' + kanjis.reduce((a, b) => a + b.strokeCount, 0)
                                            + ' strokes in total (' + meaning.meaning + ')'
                                        }}
                                    </div>
                                    <div style="font-size : 14px; color: #4f4f4f; line-height: 1.25; margin-top: 10px">
                                        {{
                                            stroke.description
                                        }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="bottom-layout">
                        <div style="font-size: 16px; line-height: 17px; margin-top: 10px;">{{ date }}</div>
                        <div style="font-size: 24px; line-height: 25px; font-weight: bold; margin-top: 10px;">Senjyu
                            Co.,Ltd.
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </el-dialog>
</template>

<script>
import moment from "moment";

export default {
    name: "PopupCertificate",
    props: {
        value: {
            type: Boolean,
            required: true
        },
        kanjis: {
            type: Array,
            required: true
        },
        profile: {
            type: Object,
            required: true
        },
        meaning: {
            type: Object,
            required: true
        },
        stroke: {
            type: Object,
            required: true
        }
    },
    computed: {
        date: function() {
            return moment(new Date()).format('MMMM DD, YYYY')
        },
        certificate() {
            console.log("kanji: ")
            console.log(this.kanjis)
            return {
                kanjiFontWeight: this.kanjis.length < 3 ? '24px' : (this.kanjis.length < 6 ? '18px' : '10px'),
                descriptionTitleFontWeight: this.kanjis.length < 3 ? '18px' : (this.kanjis.length < 6 ? '14px' : '8px'),
                descriptionFontWeight: this.kanjis.length < 3 ? '14px' : (this.kanjis.length < 6 ? '10px' : '8px'),
                padding: this.kanjis.length < 3 ? '2px' : '0px',
            }
        },
        gender() {
            return this.profile.gender;
        },
    },
    created() {
        window.addEventListener('resize', this.windownEventHandler)
    },
    destroyed() {
        window.removeEventListener('resize', this.windownEventHandler)
    },
    // updated() {
    //     console.log("mount nay")
    //     this.windownEventHandler()
    // },
    watch: {
        value: function() {
            console.log("watch nay")
            setTimeout(()=>{
                this.windownEventHandler()
            }, 1)
        }
    },
    // mounted() {
    //     console.log("mount nay")
    //     // setTimeout(() => {
    //     console.log("chay timeout nay")
    //     this.windownEventHandler()
    //     // }, 5000)
    //     // this.windownEventHandler()
    // },
    data() {
        return {
            loading: {
                fullscreen: true
            },
            scale: 1,
            dialogWidth: '50%',
            size: '',
        }
    },
    methods: {
        mmToPixel: function(mm) {
            console.log(this.$refs)
            return mm * this.$refs["29mm"].offsetWidth / 29;
        },
        windownEventHandler: function() {
            var elHeight = this.$refs['certificate'].clientHeight
            var elWidth = this.$refs['certificate'].clientWidth
            console.log(elWidth)
            console.log(this.$refs['certificate'].offsetWidth)


            var scale = this.$refs['content'].clientWidth / elWidth
            if (scale > 1) scale = 1;
            this.size = 'height:' + scale * elHeight + 'px; width:' + +scale * elWidth + 'px';
            this.scale = 'transform: translate(-50%, -50%) scale(' + scale + ')'
            console.log(this.scale);
            console.log("width: ")
            console.log(window.innerWidth)
            console.log("window width:")
            console.log(window.innerWidth < 768)
            if (window.innerWidth < 768) {
                this.dialogWidth = '80%'
            } else {
                this.dialogWidth = '50%'
            }

        }
    }
}
</script>

<style scoped lang="scss">


.content {
    text-align: center;
    color: white;

}

.certificate-wrapper {
    display: inline-block;

    .certificate {
        position: absolute;
        background-image: url("~@/assets/certificate/certificate-bg3.png");
        background-size: 100% 100%;
        width: 210mm;
        height: 297mm;
        color: #4f4f4f;
        font-family: "Noto Serif CJK JP";
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .top-layout {
            margin-top: 215px;
        }

        .bottom-layout {
            margin-bottom: 150px;
        }
    }
}

table, td, th {
    border: 1px solid black;
}

table {
    border-collapse: collapse;
}

.btn-print:hover {
    text-decoration: underline !important;
}

::v-deep .el-dialog {
    background-color: transparent;
    max-width: 210mm;
}

::v-deep .el-dialog__header {
    padding: 0px;
    background-color: transparent;
    border: none;
}


::v-deep .el-dialog__body {
    display: none;
}


</style>

