import axios from "@/config/axios";

export default {
    getSurveys: function () {
        return axios({
            url: "/api/survey/guest/surveys/list",
            method: "get",
        });
    },
    getAll: function () {
        return axios({
            url: "/api/survey/guest/all",
            method: "post",
        });
    },
    sumitSurvey(data) {
        return axios({
            url: "/api/survey/guest/submit2",
            method: "post",
            data: data,
        });
    },
    getActiveSurvey(data) {
        return axios({
            url: "/api/survey-end-user/detail",
            method: "post",
            data: data,
        });
    },
    getResponseSurvey(data) {
        return axios({
            url: "/api/survey-end-user/submit",
            method: "post",
            data: data,
        })
    }
};
