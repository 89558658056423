var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"survey-detail",attrs:{"loading":_vm.loading.loader}},[_c('div',{staticClass:"survey"},[_c('div',{staticClass:"header wrap-text-parent"},[_c('h1',{staticClass:"title wrap-text"},[_vm._v(_vm._s(_vm.surveys.surveyName))]),_c('div',{staticClass:"wrap-text"},[_c('p',{staticClass:"desc"},[_vm._v(_vm._s(_vm.surveys.surveyDescription))])])])]),_c('el-form',{ref:"questions",staticClass:"body",attrs:{"model":_vm.surveys}},[_vm._l((_vm.surveys.questions),function(question,index){return _c('div',{key:index,staticClass:"survey"},[_c('div',{staticClass:"survey-item"},[_c('el-form-item',{staticClass:"question"},[_c('span',{staticClass:"num-ques"},[_vm._v(_vm._s(index + 1)+".")]),_c('div',{staticClass:"ques-text"},[_c('span',{staticClass:"wrap-text ques-content"},[_vm._v(" "+_vm._s(question.questionContent)+" ")])])]),(question.questionType && question.questionType === 'OPTIONAL')?_c('el-form-item',{staticClass:"answer-optional",attrs:{"prop":'questions.' + index + '.answerSelected',"rules":[
            {
              required: true,
              message: _vm.$t('TEXT0253'),
              trigger: 'change',
            } ]}},_vm._l((question.answerOptional),function(answer,index){return _c('div',{key:index,staticClass:"answer-radio wrap-text"},[_c('el-radio-group',{attrs:{"rules":[
                {
                  type: 'array',
                  required: true,
                  message: _vm.$t('TEXT0253'),
                  trigger: 'change',
                } ]},model:{value:(question.answerSelected),callback:function ($$v) {_vm.$set(question, "answerSelected", $$v)},expression:"question.answerSelected"}},[_c('el-radio',{attrs:{"label":answer.id}},[_vm._v(_vm._s(answer.content))])],1)],1)}),0):_vm._e(),(question.questionType && question.questionType === 'TEXT')?_c('el-form-item',{staticClass:"question-text",attrs:{"rules":[{ required: true, message: _vm.$t('TEXT0254') },
                  {validator: _vm.checkEmpty, trigger: ['blur', 'change'],} ],"prop":'questions.' + index + '.answerText'}},[_c('el-input',{staticClass:"answer-input",attrs:{"type":"textarea","maxlength":"300","autosize":{ minRows: 2, maxRows: 4 }},model:{value:(question.answerText),callback:function ($$v) {_vm.$set(question, "answerText", $$v)},expression:"question.answerText"}})],1):_vm._e()],1)])}),_c('div',{},[_c('el-button',{staticClass:"submit-btn",attrs:{"loading":_vm.loading.saving,"type":"primary"},on:{"click":function($event){return _vm.submitForm('questions')}}},[_vm._v(_vm._s(_vm.$t('TEXT0005')))])],1)],2),_c('ConfirmDialog',{attrs:{"title":_vm.$t('TEXT0255'),"message":_vm.$t('TEXT0256')},on:{"ok":function($event){return _vm.submit()}},model:{value:(_vm.popup.submitConfirm.show),callback:function ($$v) {_vm.$set(_vm.popup.submitConfirm, "show", $$v)},expression:"popup.submitConfirm.show"}}),_c('SuccessDialog',{attrs:{"title":_vm.$t('TEXT0257'),"message":_vm.$t('TEXT0258')},on:{"ok":function($event){return _vm.$router.push('/kanji/convert/certificate')},"close":function($event){return _vm.$router.push('/kanji/convert/certificate')}},model:{value:(_vm.popup.successMessage.show),callback:function ($$v) {_vm.$set(_vm.popup.successMessage, "show", $$v)},expression:"popup.successMessage.show"}}),_c('ErrorDialog',{attrs:{"title":_vm.$t('TEXT0259'),"message":_vm.$t('TEXT0260')},model:{value:(_vm.popup.errorMessage.show),callback:function ($$v) {_vm.$set(_vm.popup.errorMessage, "show", $$v)},expression:"popup.errorMessage.show"}}),_c('ErrorDialog',{attrs:{"message":_vm.$t('TEXT0261')},on:{"ok":function($event){return _vm.$router.push('/kanji/convert/certificate')}},model:{value:(_vm.popup.reSubmit.show),callback:function ($$v) {_vm.$set(_vm.popup.reSubmit, "show", $$v)},expression:"popup.reSubmit.show"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }