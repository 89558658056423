import axios from "@/config/axios";

export default {
    romajiKanjiConvert: function(data) {
        return axios({
            url: "/api/kanji/guest/romaji-kanji-convert",
            method: 'post',
            data: data
        });
    },
    getRomajiKanjiConvertResult: function(data) {
        return axios({
            url: "/api/kanji/guest/get-romaji-kanji-convert-result",
            method: 'post',
            data: data
        });
    },
    rateService: function(data) {
        return axios({
            url: "/api/kanji/guest/rate-service",
            method: 'post',
            data: data
        });
    },
    decideKanji: function(data) {
        return axios({
            url: "/api/kanji/guest/decide-result",
            method: 'post',
            data: data
        });
    },

    getConvertResult: function(data) {
        return axios({
            url: "/api/kanji/user/get-convert-result",
            method: 'post',
            data: data
        });
    },
    downloadCertificate: function(data) {
        return axios({
            url: "/api/kanji/guest/gggg",
            method: 'get',
            data: data
        });
    },
    previewManualCertificate: function(data) {
        return axios({
            url: "/api/kanji/guest/preview-manual-certificate",
            method: 'post',
            data: data
        });
    },
    saveManualCertificate: function(data) {
        return axios({
            url: "/api/kanji/guest/save-manual-certificate",
            method: 'post',
            data: data
        });
    }

}
