<template>
    <el-dialog :title="title"
               style="margin-top: 20vh; text-align: center"
               @close="()=>{ $emit('input', false); $emit('close', true) }"
               :visible="value">
        {{ message }}
        <br/>
        <div style="text-align: center; margin-top: 20px">
            <el-button @click.prevent="()=>{$emit('ok', true); $emit('input', false); $emit('close', true)}"
                       type="primary">OK
            </el-button>
        </div>

    </el-dialog>
</template>

<script>
export default {
    name: "ChangeKanjiConfirm",
    props: {
        value: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: "title"
        },
        message: {
            type: String,
            default: "message"
        }
    },
}
</script>


<style scoped lang="scss">
::v-deep .el-dialog {
    width: 400px;
}

@media screen and (max-width: 992px) { // mobile
    ::v-deep .el-dialog {
        width: calc(100% - 20px);
    }
}
</style>
