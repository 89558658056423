<template>
    <div class="">
        <div class="container view-container qr-code">
            <div class="container view-container certificate-wrap" 
             >
            <Certificate :value="value" class="certificate"/>
            </div>
            <div style="background-color: #fff; margin-top: 20px;" class="container view-container">
                <div class="qr-item">
                    <div v-if="!isOtherQR" style="display:contents;" class="">
                        <div class="">
                        <p class="qr-header">{{ $t('TEXT0335') }}</p>
                        <p class="qr-header">{{ $t('TEXT0310') }}</p>
                        </div>
                        <img class="img" :src="urlImg" alt="">
                        <p class="fs-16 text-align mb-0">{{ $t('TEXT0334') }}</p>
                        <p class="fs-16 text-align">{{ $t('TEXT0334_1') }}</p>
                        <div>
                            <el-button width="100%" type="primary" icon="el-icon-download">
                                <a href="#" @click.prevent="downloadPNG"
                                style="text-decoration: none; color: white; 
                            margin-left: 3px" class="btn-print">
                            {{ $t('TEXT0250') }}</a></el-button>
                        </div>
                        <el-button class="switch-qr" type="text" @click="switchQRcode()">{{ $t('TEXT0332') }}</el-button>
                    </div>
                    <div v-if="isOtherQR" style="display:contents;" class="">
                        <div class="">
                        <p class="qr-header">{{ $t('TEXT2048') }}</p>
                        <p class="qr-header">{{ $t('TEXT0310') }}</p>
                        </div>
                        <img class="img" :src="urlTattooQR" alt="">
                        <p class="fs-16 text-align mb-0">{{ $t('TEXT0333') }}</p>
                        <p class="fs-16 text-align">{{ $t('TEXT0333_1') }}</p>
                        <div>
                            <el-button width="100%" type="primary" icon="el-icon-download">
                                <a href="#" @click.prevent="downloadTattooQRCode"
                                style="text-decoration: none; color: white; 
                                margin-left: 3px" class="btn-print">
                            {{ $t('TEXT0250') }}</a></el-button>
                        </div>
                        <el-button class="switch-qr" type="text" @click="switchQRcode()">{{ $t('TEXT0332') }}</el-button>
                    </div>
                </div>
            </div>
            
            <div class="container view-container ">
                <div class="title-craft-art">
                    <h3>{{ $t('TEXT0324') }}</h3>
                </div>
                <div class="intro-craft-art">
                    <div class="craft-img">
                        <div class="craft-art-title">
                            <p class="craft-header">{{ $t('TEXT0304') }}</p>
                        </div>
                        <div class="craft-body">
                            <div class="left-container" >
                                <div style="position: relative; margin: auto;" class="artist-wrap">
                                    <img class="artist-img" src='../assets/artist.png'>
                                    <div class="artist-des">
                                        <p class="artist-title">{{ $t('TEXT0292') }}</p>
                                        <h4 class="artist-name">{{ $t('TEXT0293') }}</h4>
                                        <p style="display: block; position: relative;" class="artist-intro ">{{ $t('TEXT0294') }} <img class="mark" src="../assets/mark.png" alt=""> </p>
                                        <p class="artist-intro mobile-hidden">{{ $t('TEXT0295') }}</p>
                                        <p class="artist-intro">{{ $t('TEXT0296') }}</p>
                                    </div>
                                </div>
                                <div class="">
                                    <h4 class="right-text">{{ $t('TEXT0297') }}</h4>
                                    <h4 class="right-text">{{ $t('TEXT0298') }}</h4>
                                    <h4 class="right-text">{{ $t('TEXT0299') }}</h4>
                                </div>
                            </div>
                            <div class="right-container">
                                <h4 style="margin-top: 0;" class="right-text paragraph" >{{ $t('TEXT0300') }} <span class="highlight-text">{{ $t('TEXT0300_1') }}</span>{{ $t('TEXT0300_2') }}</h4>
                                <h4 class="right-text">{{ $t('TEXT0301') }}</h4>
                                <div  class="brush-mobile">
                                    <Product @setHide="(val) => { hideSuggestShop = val}"/>
                                </div>
                                <h4 style="margin-top: 35px;" class="right-text highlight">{{ $t('TEXT0302') }} <span class="highlight-text">{{ orderPrice }}</span>{{ $t('TEXT0302_1') }}  </h4>
                                <h4 class="right-text highlight">{{ $t('TEXT0303') }}<span class="highlight-text">{{ outDate }}</span>{{ $t('TEXT0303_1') }} </h4>
                                <div class="right-text">
                                    <a style="color:#fff" href="#" @click.prevent="popup.paymentPolicy.show = true">{{
                                        $t("TEXT0268")
                                    }}</a>
                                </div>
                            </div>
                        </div>
                        <div class="brush-mobile">
                            <Brush @setHide="(val) => { hideSuggestShop = val}"/>
                        </div>
                    </div>
                    <div style="display: flex; justify-content: center; width: 100% ;">
                        <el-button :disabled="Boolean(orderDetail.id)" type="primary" @click="popup.orderCraftConfirm.show=true" class="request-btn">{{ $t('TEXT0246') }}</el-button>
                    </div>
                </div>
            </div>
        </div>

        <RequestDialogConfirm
                v-model="popup.orderCraftConfirm.show"
                :message="$t('TEXT2047', { cost: orderPrice })"
                @close="popup.orderCraftConfirm.show = false"
        ></RequestDialogConfirm>
        <PaymentPolicy :cost="orderPrice" :date="outDate" v-model="popup.paymentPolicy.show" />
    </div>
</template>

<script>
import axios from 'axios';
import Certificate from "@/components/kanji/Certificate";
import RequestDialogConfirm from "@/components/RequestDialogConfirm";
import GuestApi from "@/api/GuestApi";
import 'element-ui/lib/theme-chalk/display.css';
import ConfigApi from "@/api/ConfigApi";
import { getDetailOrder } from '@/api/Order'
import Brush from '../components/Brush.vue'
import PaymentPolicy from '../views/payment/PaymentPolicy.vue'
import Product from '../components/Product.vue'


 
export default {
    name: "QRcode",
    components:{ 
        Certificate, 
        RequestDialogConfirm,
        Brush,
        PaymentPolicy,
        Product
    },
    async created() {
       await this.getProfile();
    },
    async mounted() {
        window.scrollTo(0, 0)
        this.getConfig()
    },
    data() {
        return {
            value: true,
            urlImg: null,
            urlTattooQR: null,
            payload: null,
            orderDetail: {},
            kanji: null,
            orderPrice: 30,
            outDate: 5,
            kanjiStyles:[
                {
                    name: "tensho",
                    jp: "篆書",
                    description:"The clear and bold style of tensho made it work well for titles and this tradition of using tensho only for titles is still around today.",
                    image:"../assets/craft-art1.jpg"
                },
                {
                    name: "reisho",
                    jp: "隷書",
                    description:"The clerical script or scribe's script (reisho) is a very bold and commanding style of Chinese calligraphy; each of the strokes are greatly exaggerated at the beginning and end.",
                    image:'../assets/craft-art2.jpg'
                },
                {
                    name: "gyōsho",
                    jp: "行書",
                    description:"The style of gyosho utilizes a softer and more rounded technique, staying away from sharp corners and angles. In Japan many works were made using the gyosho technique during the early Heian Period.",
                    image:'../assets/craft-art3.jpg'
                },
                
            ],
            popup: {
                orderCraftConfirm:{
                    show: false
                },
                paymentPolicy: {
                    show: false
                }
            },
            isOtherQR: true
        }
      
    },
    computed: {
        qrSwitchContent(){
            return this.isOtherQR ? 'Click here to get QR code for Customer questionnaire' : 'Click here to get QR code for Tattoo sticker machine'
        }
    },
    methods: {
        switchQRcode(){
            this.isOtherQR = !this.isOtherQR
        },
        gotoCertificate: function() {
            this.$router.push('/kanji/convert/certificate');
            localStorage.removeItem("fullname");
            localStorage.removeItem("email");
            localStorage.removeItem("gender");
            localStorage.removeItem('country');

        },
        getInfor() {
            let kanjiName = localStorage.getItem('kanji');
            let id = localStorage.getItem('id')
            this.payload = {
                "kanjiName" : kanjiName,
                "id" : id
            }
            this.getQRcode();
            this.getTattooQRcode();

        },
        getDetailOrder() {
            getDetailOrder({idNo: this.payload.id, kanjiName: this.payload.kanjiName}).then((res)=>{
                this.orderDetail = res.data
            }).catch((err) =>{ console.log(err);})
        },
        getProfile: async function(next) {
            await GuestApi.getProfile().then(resp => {
               localStorage.setItem('id', resp.data.id)
               localStorage.setItem('fullname',resp.data.fullName);
               localStorage.setItem('gender',resp.data.gender);
            //    this.kanji = resp.data.kanji;
               localStorage.setItem('kanji',resp.data.kanji);
               localStorage.setItem('country',resp.data.countryId);
               localStorage.setItem('email',resp.data.email);
               this.getInfor()
                this.getDetailOrder()
                if (next) {
                    next();
                }
            }).catch(e => {
                console.log(e)
            })
        },
        getConfig: function () {
            ConfigApi.getConfig().then(resp => {
                this.orderPrice = resp.data.orderPrice
                this.outDate = resp.data.outDate
            }).catch((err)=>{
                console.log(err);
            })
        },
        downloadPNG: function () {
            axios.post(location.protocol + '//' + process.env.VUE_APP_API_PREFIX + location.hostname  + process.env.VUE_APP_API_PORT + "/api/kanji/qr-code/questionnaire/download", this.payload, {
                headers: {
                    Authorization: localStorage.getItem('token'),
                },
                responseType: 'blob',
            }).then((response) => {
                var blob = new Blob([response.data], { type: "image/png" });
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'survey-QR.png'); //or any other extension
                document.body.appendChild(link);
                link.click();
            }).catch((err)=>{
                console.log(err);
            })
        },
        downloadTattooQRCode: function () {
            axios.post(location.protocol + '//' + process.env.VUE_APP_API_PREFIX + location.hostname  + process.env.VUE_APP_API_PORT + "/api/kanji/qr-code/tattoo/download", this.payload, {
                headers: {
                    Authorization: localStorage.getItem('token'),
                },
                responseType: 'blob',
            }).then((response) => {
                var blob = new Blob([response.data], { type: "image/png" });
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'sticker-QR.png'); //or any other extension
                document.body.appendChild(link);
                link.click();
            }).catch((err)=>{
                console.log(err);
            })
        },
        getQRcode: function() { 
            axios.post(location.protocol + '//' + process.env.VUE_APP_API_PREFIX + location.hostname + process.env.VUE_APP_API_PORT + "/api/kanji/qr-code/questionnaire/generate", this.payload, {
                headers: {
                    'Authorization': localStorage.getItem('token'),
                    'content-type': 'application/json',
                    'accept': 'image/png'
                },
                responseType: 'blob',
            }).then((response) => {
                // let a = btoa(String.fromCharCode.apply(null, response.data.replace(/\r|\n/g, "").replace(/([\da-fA-F]{2}) ?/g, "0x$1 ").replace(/ +$/, "").split(" ")));
                // let b = 'data:image/jpeg;base64,' + a;
                const urlCreator = window.URL || window.webkitURL
                this.urlImg = urlCreator.createObjectURL(response.data)
            }).catch((err)=>{
                console.log(err);
            })
        },
        getTattooQRcode: function() { 
            axios.post(location.protocol + '//' + process.env.VUE_APP_API_PREFIX + location.hostname + process.env.VUE_APP_API_PORT + "/api/kanji/qr-code/tattoo/generate", this.payload, {
                headers: {
                    'Authorization': localStorage.getItem('token'),
                    'content-type': 'application/json',
                    'accept': 'image/png'
                },
                responseType: 'blob',
            }).then((response) => {
                // let a = btoa(String.fromCharCode.apply(null, response.data.replace(/\r|\n/g, "").replace(/([\da-fA-F]{2}) ?/g, "0x$1 ").replace(/ +$/, "").split(" ")));
                // let b = 'data:image/jpeg;base64,' + a;
                const urlCreator = window.URL || window.webkitURL
                this.urlTattooQR = urlCreator.createObjectURL(response.data)
            }).catch((err)=>{
                console.log(err);
            })
        },
    }
}
</script>

<style scoped lang="scss">
.title-craft-art{
    margin-top: 25px;
    padding: 25px 25px 0;
}
.title-craft-art > h3{
    margin: 0;
}

.qrcode {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 80%!important;
    background-color: white;
}

.qr-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #4f4f4f;
    font-family: "Noto Serif CJK JP";
}

.img{
    width: 25%; 
    object-fit: cover;
    object-position: 25% 25%; 
}

.qr-wrap{
    display: flex;
}
.certificate-wrap{
    flex: 1;
    position: relative;
    background: #ffffff 50%;
    justify-content: center;
    align-items: center;
    display: flex;
}
.certificate{
    width: fit-content;
    color: #888888;
     .btn-print {
        color: #888888 !important;
    }
    margin: 15px;
}
.qr-code{
    flex: 1;
    background: #ffffff 50%;
    margin-bottom: 20px;
    height: 100vh;

}
.craft-art-card{
    padding: 0 30px;
    display:flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;
}
.craft-image{
    height: 200px;
    padding: 0 20px;
}
.craft-card{
    margin-bottom: 25px;
}
.left-container{
    padding: 20px;
    flex: 1;
    position: relative;
}

.left-img{
    width: 100%;
    height: auto;
}
// .craft-img{
//     display: block;
//     margin-left: auto;
//     margin-right: auto;
//     width: 50%;
//     }
.request-btn{
    margin-top: 15px;
    height: 60px;
    width: 50%;
    min-width: 280px;
}
.price{
    padding-top: 20px !important;
}
.text-align{
    text-align: center;
}
.switch-qr {
    color: #44bcff;
    border: none;
    background-color: transparent !important;
    font-size: 16px;
    font-weight: 700;
    padding: 12px 0;
    margin: auto;
    display: block;
}
.mb-0{
    margin-bottom: 0;
}
.fs-16{
    font-size: 16px;
    margin-top:0;
}
@media screen and (max-width: 520px) { // mobile
    .img{
        width: 70%;
    }
    .qr-header{
        margin: 0 20px;
    }
}
.craft-img{
    display: flex;
    flex-direction: column;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 90%;
    margin-top: 15px;
    background-image: url('../assets/background-craft.png');
    height: -webkit-fill-available;

}
.craft-header{
    font-size: 36px;
    font-weight: 900;
    font-family: inherit;
    color: #fff;
    margin: 20px;
    letter-spacing: 2px;
}
.artist-img{
    width: 100%;
    height: auto;
    filter: brightness(0.6);
}
.craft-body{
    display: flex;
}

.intro-craft-art{
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.artist-des{
    width: 90%;
    filter: brightness(1.1);
    position: absolute;
    bottom: 0;
    padding: 10px;
    margin-bottom: 10px;
}
.artist-title{
    color: #d89b23;
    // text-shadow: 2px 2px #000, -2px -2px #000, 2px -2px #000, -2px 2px #000,
    //           3px 3px #000, -3px -3px #000,3px -3px #000, -3px 3px #000;
    font-size: 24px;
    font-weight: bolder;
    -webkit-text-stroke: 0.2px #000;
    margin: 0;
}
.brush-wrap{
    position: relative;
    height: 300px;
}
.paragraph{
    margin-top: 30px;
}
.brush-img{
    height: 100%;
    width: auto;
}
.brush{
    display: flex; 
    justify-content: space-between;
}
.artist-intro{
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    margin:  10px 0;
    -webkit-text-stroke: 0.3px #000;
}
.mark{
    margin-left: 4px;
    position: absolute;
    width: 22px;
    height: 22px;
    bottom: 2px;
}
.artist-name{
    color: #fff;
    font-size: 22px;
    font-weight: 500;
    margin:  8px 0;
}
.right-text{
    color: #dcdfe6;
    font-size: 16px;
    margin:  8px 0;
    font-weight: 100;
    line-height: 32px;
}
.highlight{
    font-weight: 600;
    font-size: 18px;
}
.paragraph {
    margin-top: 30px;
}
.view-container{
    background-color: #fff;
}
.right-container{
    padding: 10px 30px 30px 30px;
    flex: 1;
    display: flex;
    flex-direction: column;
}
.highlight-text{
    color: #e94b19 ;
    font-weight: bold;
    font-size: 20px;
}
.switch-qr {
    color: #44bcff;
    border: none;
    background-color: transparent;
    font-size: 16px;
    font-weight: 700;
    white-space:inherit;
}
@media screen and (max-width: 535px)  {
    .paragraph{
        margin-top: 0px;
    }
    .mark{
    margin-left: 4px;
    position: absolute;
    width: 16px !important;
    height: 16px !important;
    bottom: 2px;
    }
    .highlight-text{
    color: #e94b19 ;
    font-weight: bold;
    font-size: 17px;
}
}
@media screen and (min-width: 535px) and (max-width: 992px)  {
    .img{
        width: 50%;
    }
    .qr-header{
        padding: 0 30px;
    }
    
    
}
@media screen and (min-width: 535px) and (max-width: 1199px) {
    .left-container{
        display: flex;
        flex-direction: column;
    }
    .artist-wrap{
        width: 80%;
    }
    .artist-img{
        width: 90% ;
        height: auto;
        margin: auto;
    }
    .highlight-text{
        color: #e94b19 ;
        font-weight: bold;
        font-size: 17px;
    }
    .artist-des{
        width: 90%;
        filter: brightness(1.1);
        position: absolute;
        bottom: 0;
        left: 0;
        padding: 10px;
        margin-bottom: 10px;
    }
    .paragraph{
        margin-top: 20px;
    }
}
@media screen and (max-width: 1199px) { // mobile
    .craft-img{
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    height: -webkit-fill-available;
    }
    .request-btn{
    margin-top: 15px;
    height: 60px;
    width: 90%;
    min-width: 280px;
    }
    .brush{
        display: none; 
        justify-content: space-between;
    }
    .craft-header{
        font-size: 23px;
        font-weight: 900;
        font-family: inherit;
        color: #fff;
        margin: 20px;
        letter-spacing: 2px;
    }
    .craft-body{
        display: flex;
        flex-direction: column;
    }
    .artist-title{
        font-size: 18px;
    }
    .artist-name{
        font-size: 16px;
    }
    .artist-intro{
        font-size: 11px;
        margin: 0;
    }
    .artist-des{
        margin: 0;
    }
    .mobile-hidden{
        display:none;
    }
    .right-container{
        margin: 0 20px;
        padding: 0 !important;

    }
    .right-text{
        color: #dcdfe6;
        font-size: 13px;
        margin:  8px 0;
        font-weight: 500;
        line-height: 20px;
        width: 100%;
    }
    .highlight{
    font-weight: 600;
    font-size: 15px;
    }
    .craft-header{
        font-size: 20px;
        font-weight: 600;
        font-family: inherit;
        color: #fff;
        margin: 10px;
        letter-spacing: 2px;
    }
    .paragraph{
        margin-top: 0px;
    }
    
}
</style>
