<template>
    <el-dialog :title="title"
               style="margin-top: 8vh; text-align: left"
               @close="()=>{ $emit('input', false); $emit('close', true); setNull(input.model); this.setNull(this.input.error); }"
               :visible="value"
    >
        <ElForm class="sent-mail-convert-katakana-form">
            <el-form-item :error="input.error.firstName" :label="$t('TEXT0236')" class="required">
                <el-input
                        v-model="input.model.firstName"
                        maxlength="128"
                >
                </el-input>
            </el-form-item>
            <el-form-item :error="input.error.email" :label="$t('TEXT0019')" class="required">
                <el-input
                    v-model="input.model.email"
                    maxlength="128"
                >
                </el-input>
            </el-form-item>
            <p>{{ $t('TEXT0237') }}</p>
            <div class="button-action">
              <ElButton type="info" class="button-cancel"
                        @click.prevent="()=>{
                          $emit('ok', true);
                          $emit('input', false);
                          $emit('close', true)
                          setNull(input.model)
                          this.setNull(this.input.error)
                        }">
                {{ $t('TEXT0238') }}
              </ElButton>
              <ElButton type="primary" class="button-sent"
                        :loading="loading.convert"
                        @click.prevent="sentMail()">{{ $t('TEXT0078') }}
              </ElButton>
            </div>
        </ElForm>
    </el-dialog>
</template>

<script>

import ConvertKatakanaApi from "@/api/ConvertKatakanaApi";
import EventBus from "@/config/EventBus";

export default {
    name: "SentMailConvertKatakanaDialog",
    props: {
      value: {
        type: Boolean,
        default: false
      },
      title: {
        type: String,
        default: 'title'
      },
      convertKatakanaName: {
        type: String,
        default: null
      },
      countryId: {
        type: Number,
        default: null
      }
    },
    mounted() {
        EventBus.$on('localeChange', () => {
            this.setNull(this.input.error)
        })
    },
    data() {
        return {
            input: {
                model: {
                    firstName: null,
                    email: null
                },
                error: {
                    firstName: null,
                    email: null
                }
            },
            loading: {
                convert: false
            }
        }
    },
    methods: {
        sentMail: function () {
            this.loading.convert = true
            this.setNull(this.input.error)

            const data = this.trimObject(Object.assign({
              convertKatakanaName: this.convertKatakanaName,
              countryId: this.countryId,
            }, this.input.model))
            ConvertKatakanaApi.sentmailKatakana(data).then(resp => resp.data).then(resp => {
              console.log(resp)
              this.setNull(this.input.model)
              this.$emit('ok', true)
              this.$emit('input', false)
              this.$emit('close', true)
              this.$emit('sentComplete')
            }).catch(resp => {
                if (resp.status === 412) {
                    for (let item of resp.data) {
                        this.input.error[item.key] = item.value
                    }
                    this.input.error.__ob__.dep.notify()
                }
            }).finally(() => {
                this.loading.convert = false
            })
        }
    }
}
</script>

<style scoped lang="scss">
@import "./src/style/variables.scss";
//

//
::v-deep .fa-imput-icon .el-input__prefix {
    font-size: 20px;
    left: 12px;
}

//
::v-deep .el-input--prefix .el-input__inner {
    padding-left: 40px !important;
}

@media screen and (max-width: 992px) { // mobile
  ::v-deep .el-dialog {
    width: calc(100% - 20px);
  }
}

.sent-mail-convert-katakana-form {
  .button-action {
    margin: 2rem 0 1rem 0;
    text-align: center;
    .button-cancel {
      width: 35%;
      margin-right: 5%;
    }
    .button-sent {
      width: 35%;
    }
  }

  ::v-deep .el-input.result-katakana .el-input__inner {
    background-color: #F5F7FA;
    border-color: #E4E7ED;
    color: #C0C4CC;
  }
}

@media screen and (max-width: 992px) { // mobile
    ::v-deep .el-input--prefix .el-input__inner {
        padding-left: 45px !important;
    }
}

</style>
