import axios from "@/config/axios";

export default {
    getStrokeConverted: function (data) {
        return axios({
            url: "/api/stroke/guest/get-converted",
            method: 'post',
            data: data
        });
    },

    getByStrokeCount(data) {
        return axios({
            url: "/api/stroke/guest/get-en-stroke-by-stroke-count",
            method: 'post',
            data: data
        });
    }
}
