import axios from "@/config/axios";

export default {
    all: function (data) {
        return axios({
            url: "/api/meaning/guest/all",
            method: 'post',
            data: data
        });
    },
}
