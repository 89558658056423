<template>
<div>
  <el-dialog :title="title" @close="$emit('input', false)" :visible="value">
    <div class="craft-confirm">
      <img
        src="https://www.invaluable.com/blog/wp-content/uploads/sites/77/2018/09/what-is-japanese-calligraphy-used-for-1-1.jpg"
        alt=""
      />
      <h3>
        {{ message }}
      </h3>
    </div>
    <br />
    <stripe-checkout
      ref="checkoutRef"
      mode="payment"
      :pk="publishableKey"
      :success-url="successUrl"
      :cancel-url="cancelUrl"
      :session-id="sessionId"
      @loading="(v) => (loading = v)"
      style="display: flex; justify-content: end"
    >
    </stripe-checkout>
    <div class="btn-container">
      <div class="payment-policy">
        <el-checkbox class="checkbox-policy" v-model="agreeTerm">
              <a href="#" @click.prevent="popup.securityPolicy.show = true">{{
                  $t("TEXT0056")
              }}</a>
              {{ $t("TEXT0057") }}
          </el-checkbox>
        <el-checkbox class="checkbox-policy" v-model="agreePaymentPolicy">
            <a href="#" @click.prevent="popup.paymentPolicy.show = true">{{
                $t("TEXT0268")
            }}</a>
            {{ $t("TEXT0057") }}
        </el-checkbox>
      </div>
      <el-button :disabled="!(agreeTerm && agreePaymentPolicy)" v-loading="load.payment" class="payment-btn" type="primary" @click="payment()"
        >{{ $t("TEXT0309") }}</el-button
      >
    </div>
  </el-dialog>
  <SecurityPolicy  v-model="popup.securityPolicy.show" />
  <PaymentPolicy :cost="view.cost" :date="view.date" v-model="popup.paymentPolicy.show" />

  </div>
</template>

<script>
// import axios from 'axios';
import { StripeCheckout } from "@vue-stripe/vue-stripe";
import PaymentApi from "../api/PaymentApi";
import SecurityPolicy from '@/views/kanji/SecurityPolicy'
import PaymentPolicy from '../views/payment/PaymentPolicy.vue'
import ConfigApi from "@/api/ConfigApi";

export default {
  name: "ConfirmDialog",
  components: {
    StripeCheckout,
    SecurityPolicy, 
    PaymentPolicy
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
    },
    message: {
      type: String,
      default: "message",
    },
  },
  data() {
    return {
      view: {
        showing: null,
        config: null,
        cost: 3000,
        date: 5,
      },
      load: {
        payment: false
      },
      loading: false,
      successUrl: "",
      cancelUrl: "",
      sessionId: "",
      agreeTerm: false,
      agreePaymentPolicy: false,
      popup:{
        securityPolicy: {
          show: false
        }, 
        paymentPolicy: {
          show: false
        }
      }
    };
  },
  computed:{
    publishableKey(){
      return process.env.VUE_APP_PAYMENT_PUBPISHABE_KEY
    }
  },
  created() {
    this.getConfig()
  },
  methods: {
    // async getSessionId() { 
    //   return await axios.post(location.protocol + '//' + process.env.VUE_APP_API_PREFIX + location.hostname + process.env.VUE_APP_API_PORT + "/api/stripe/create-checkout-session", {
    //       headers: {
    //         "Content-Type": "application/json"
    //       }
    //   }).then((response) => {
    //         return response.data.sessionId;
    //     })
    //     .then((data) => {
    //       console.log("Success", data);
    //     })
    //     .catch((error) => {
    //       console.log("Error:", error);
    //       // return error.sessionId;
    //     });
    //     },

    async getCheckoutSession() {
      // let payload = localStorage.getItem('email')
      // console.log('payloaf', localStorage.getItem('email'));
      let head = {
        headers: { "Content-Type": "application/json" },
      };
      return await PaymentApi.orderCraftArt('', head)
        .then((response) => response.data.sessionId)
        .catch((error) => {
          console.log("Error:", error);
          // return error.sessionId;
        });
      //   return "cs_test_a1m5frzDDMXcb3lL5VpXgzNrGM1EUY8AiSqSqXrOWNdMR2zvWDaPRGWo8h";
      //   try {
      //     let res = await PaymentApi.orderCraftArt(null, head);
      //     console.log("q12", res);
      //   } catch (err) {
      //     console.log("err", err);
      //   }
    },

    async payment() {
      this.load.payment = true;
      this.sessionId = await this.getCheckoutSession();
      if (this.sessionId) {
        //stripe checkout page
        this.$refs.checkoutRef.redirectToCheckout();
        this.load.payment = false;
      } else {
        this.load.payment = false;
        alert('Payment failed. Try again')
      }
    }, 
    getConfig: function () {
            ConfigApi.getConfig().then(resp => {
                console.log("view: " + resp.data.view)
                this.view.showing = resp.data.view
                this.view.config = resp.data.view
                this.view.cost = resp.data.orderPrice
                this.view.date = resp.data.outDate
            })
        },
  }
  
};
</script>

<style scoped lang="scss">
@media screen and (max-width: 992px) {
  // mobile
  ::v-deep .el-dialog {
    width: calc(100% - 50px);
  }
}
@media screen and (max-width: 500px) {
  // mobile
  ::v-deep .el-dialog {
    width: calc(100% - 50px);
  }
  // .payment-policy{
  //   word-wrap: break-word;
  //   display: block !important;
  //   width: 100%;
  // }

}
.btn-container{
    display: flex; 
    justify-content: end; 
    align-items: center; 
    flex-wrap: wrap;
    gap: 20px
  }
.craft-confirm {
  img {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    margin-bottom: 20px;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
}
.payment-btn {
  height: 50px;
  padding: 0 30px;
}
.payment-policy{
  display: flex;
  flex-direction: column;
}
.checkbox-policy.el-checkbox{
  white-space: inherit;

  .el-checkbox__label {
    display: initial !important;
  }
}
::v-deep .el-checkbox, .el-checkbox__input{
  display: inherit !important;
}
::v-deep .el-dialog__title{
  width: calc(100% - 48px);
  display: inline-block;
}
</style>
