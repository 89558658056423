import en from "element-ui/src/locale/lang/en";

export default {
    ...en,
    TEXT0001: 'Country',
    TEXT0002: 'first name',
    TEXT0003: 'Gender',
    TEXT0004: 'Good luck you want',
    TEXT0005: 'Submit',
    TEXT0006: 'Male',
    TEXT0007: 'Female',
    TEXT0008: 'convert name',
    TEXT0009: 'PDF download(for free)',
    TEXT0010: 'Introduction of name entry service',
    TEXT0011: 'Search',
    TEXT0012: 'List products',
    TEXT0013: 'Home page',
    TEXT0014: 'Print request',
    TEXT0015: 'Pieces',
    TEXT0016: 'Total',
    TEXT0017: 'Request details',
    TEXT0018: 'Name',
    TEXT0019: 'Email address',
    TEXT0020: 'Phone number',
    TEXT0021: 'Address',
    TEXT0022: 'Order',
    TEXT0023: 'Thank you for your order.',
    TEXT0024: 'New password',
    TEXT0025: 'Confirm password',
    TEXT0026: 'Add account',
    TEXT0027: 'Sign In',
    TEXT0028: 'Register',
    TEXT0029: 'Change Language',
    TEXT0030: 'Japanses',
    TEXT0031: 'English',
    TEXT0032: 'Password',
    TEXT0033: 'Click here to reset your password',
    TEXT0034: 'Send',
    TEXT0035: 'Password reset procedure',
    TEXT0036: 'Send completely',
    TEXT0037: 'Please confirm that we have sent you an email to reset your password.',
    TEXT0038: 'Top page',
    TEXT0039: 'Password reset',
    TEXT0040: 'Password change completed',
    TEXT0041: 'The new password setting is complete. Please log in from below',
    TEXT0042: 'Login screen',
    TEXT0043: 'Phone number (Optional)',
    TEXT0044: 'Address (Optional)',
    TEXT0045: 'Register',
    TEXT0046: 'Please enter your information',
    TEXT0047: 'Privacy policy agreement',
    TEXT0048: 'Old Password',
    TEXT0049: 'Save',
    TEXT0050: 'Change Password',
    TEXT0051: 'Profile',
    TEXT0052: 'Full name',
    TEXT0053: 'Send password confirm',
    TEXT0054: 'One email will be send to your email',
    TEXT0055: '',
    TEXT0056: 'Privacy policy ',
    TEXT0057: 'agreement',
    TEXT0058: 'English notation name',
    TEXT0059: 'Gender',
    TEXT0060: 'English Full Name  e.g Micheal Jackson',
    TEXT0061: 'English First Name  e.g Micheal',
    TEXT0062: 'Good luck you want',
    TEXT0063: '(Hereinafter referred to as "our company") has the following privacy policy regarding the handling of user\'s personal information in the service provided on this website (hereinafter referred to as "this service"). Hereinafter referred to as  "this policy").Article 1 (Personal information)',
    TEXT0064: 'Personal information refers to "personal information" as defined in the Personal Information Protection Law, and is information about living individuals, including the name, date of birth, address, telephone number, and contact information contained in the information. Information that can identify a specific individual by other descriptions, etc., and refers to information that can identify a specific individual (personal identification information) from the relevant information alone, such as appearance, fingerprints, data on voice prints, and insurer number of health insurance certificate. I will.Article 2 (How to collect personal information)',
    TEXT0065: 'We may ask for personal information such as name, date of birth, address, telephone number, email address, bank account number, credit card number, driver\'s license number, etc. when the user registers for use. ， Information on transaction records and payments made between users and business partners, including personal information of users, including our business partners (including information providers, advertisers, advertisement distribution destinations, etc. It may be collected from  "destination".Article 3 (Purpose of collecting and using personal information)',
    TEXT0066: 'The purposes for which we collect and use personal information are as follows.',
    TEXT0067: 'This name (First name) changes to kanji.',
    TEXT0068: 'Since ancient times in Japan, it has been believed that the total number of strokes of the kanji used in the name will affect the future fortune of the person. Choose one of the eight lucks you want most Let\'s think about a combination of kanji with the number of strokes that leads to that luck We will give you the best kanji name.',
    TEXT0069: '* Click Send to send the URL to your address.',
    TEXT0070: 'Please enter your information',
    TEXT0071: 'Mail send completed',
    TEXT0072: 'An email has been sent to your email. You can convert later by clicking the button in the email.',
    TEXT0073: 'Loading',
    TEXT0074: 'Contact Us',
    TEXT0075: 'Name',
    TEXT0076: 'Content of inquiry',
    TEXT0077: 'If you can\'t decide on a kanji name by yourself, please tell us what you want to put in the kanji name and what you value most in your life.',
    TEXT0078: 'send',
    TEXT0079: 'Kanji name decision',
    TEXT0080: 'Create another kanji name',
    TEXT0081: 'Click here if you want to create another kanji.',
    TEXT0082: 'Your kanji name has been decided! Congratulations.',
    TEXT0083: 'Congratulations',
    TEXT0084: 'Why don\'t you make your own original product using this wonderful kanji name! !!',
    TEXT0085: 'next',
    TEXT0086: 'Are you satisfied with this kanji naming service?',
    TEXT0087: 'Very satisfied',
    TEXT0088: 'completed',
    TEXT0089: 'Determine the area',
    TEXT0090: 'Specify the area',
    TEXT0091: 'Can\'t you decide on a kanji? Contact the administrator.',
    TEXT0092: 'Total number of strokes',
    TEXT0093: 'IDNo',
    TEXT0094: 'If you forget your IDNo click ',
    TEXT0095: 'here',
    TEXT0096: 'Redisplay IDNo',
    TEXT0097: 'one email has been send to your account. please check your email',
    TEXT0098: 'Kanji name',
    TEXT0099: 'Level of satisfaction',
    TEXT0100: 'Welcome to Japan',
    TEXT0101: 'This site provides a service to name the best kanji name from your English name according to Japanese conventions.',
    TEXT0102: 'And we will show you the service to make only one original product in the world using the kanji name.',
    TEXT0103: 'For those who want to decide on a kanji name',
    TEXT0104: 'click',
    TEXT0105: 'If you have already decided on a name (who has already issued an ID No) click',
    TEXT0106: 'here',
    TEXT0107: '',
    TEXT0108: 'IDNo sent to the email.Please check in your email.',
    TEXT0109: 'English Meaning',
    TEXT0110: 'If you can\'t decide the kanji name by yourself, or if you want advice from Japanese staff, please click here.',
    TEXT0111: 'Start over kanji naming',
    TEXT0112: 'We can\'t find any suitable kanji for your name',
    TEXT0113: 'Logout',
    TEXT0114: 'Action day',
    TEXT0115: 'Action Store',
    TEXT0116: 'Can be shipped overseas',
    TEXT0117: 'Cannot be shipped overseas',
    TEXT0118: 'Inquiry transmission completed',
    TEXT0119: 'The inquiry has been sent to the administrator.',
    TEXT0120: 'Very dissatisfied',
    TEXT0121: 'Dissatisfaction',
    TEXT0122: 'normal',
    TEXT0123: 'Satisfaction',
    TEXT0124: 'Very satisfied',
    TEXT0125: 'Profile change completed',
    TEXT0126: 'The profile change is complete.',
    TEXT0127: 'Recommended stores',
    TEXT0128: '* Please enter this ID No. When ordering products',
    TEXT0129: 'Senjyu Co.,Ltd Website Policy',
    TEXT0130: 'This website is managed and operated by Senjyu Co., Ltd. (hereinafter referred to as the "Company"). When you use this website (hereinafter referred to as the "Website"), please read the following items (hereinafter referred to as the "Website Policy ") in advance and use it only if you agree. This website policy is subject to change without notice. Please always check the latest contents.',
    TEXT0131: 'In addition, if the terms and conditions of use of the website are stipulated separately on the Company\'s website, please understand that both this website policy and the terms of use apply, and use our website in compliance with these terms and conditions. In addition, if the provisions of this website policy and the terms of use are different, the terms of use shall be applied in priority to this website policy.',
    TEXT0132: 'About copyright',
    TEXT0133: 'The copyright of all content, including text, photographs, videos, music, and designs posted on the Company\'s website, belongs to the Company or the right holder who has those who have lyeed the use of the content. These copyrights are protected by copyright laws, treaties, and other laws of each country. Other uses, including copying, distribution, posting, transmission, deletion, change, adaptation, etc., are strictly prohibited, except for downloading, printing out and using each content to the limits as allowed by law, in accordance with the express limitations.',
    TEXT0134: 'Disclaimer',
    TEXT0135: 'The Company operates and manages the Company\'s website with great care at all time, but does not guarantee the accuracy or completeness of information and operation. The Company shall not be liable for any damages (including software or hardware problems) caused by your use of our website or the inad-use for any reason.											 ',
    TEXT0136: 'About links',
    TEXT0137: 'In principle, you are free to link to our website. However, please refrain from linking directly to images or PDF files. In addition, please be sure to specify that it is a link to our website when linking, and refrain from descriptions and expressions that may cause misunderstandings that information such as products, services, companies, etc. posted on websites linked to our website (hereinafter referred to as "linked sites") may have special relationships such as alliances with the Company.',
    TEXT0138: 'In addition, we do not provide banners for links, so please link from the text.',
    TEXT0139: 'In addition, link sites linked from or to our website are not under our control, and we are not responsible for any damage caused by using them.',
    TEXT0140: 'About the handling of customer information',
    TEXT0141: 'We respect your privacy and take care of your information as an important responsibility. The Company will handle the information obtained from customers regarding the use of the Company\'s website (hereinafter referred to as "Customer Information") as follows.',
    TEXT0142: '1. Information we obtain from you',
    TEXT0143: 'The Company acquires the following customer information regarding the use of our website.',
    TEXT0144: '1.1 Purchase information',
    TEXT0145: 'The Company acquires customer information (your name, email address, your birthday, country of citizenship, sex, etc) through "KANJI　naming service".',
    TEXT0146: '1.2 Contact Information',
    TEXT0147: 'We will obtain contact information, etc. when we receive inquiries and opinions from customers.',
    TEXT0148: '1.3 History of actions on our website',
    TEXT0149: 'The Company may acquire the usage history, browsing information, etc. of the Company\'s website when the customer uses the Company\'s website.',
    TEXT0150: '1.4 Information about terminals and connection environments',
    TEXT0151: 'The Company may acquire information such as product models, information terminal settings, IP addresses, and other information terminal-specific identifiers from the customer\'s information terminal.',
    TEXT0152: '2. Purpose of use of information',
    TEXT0153: 'We use customer information obtained in connection with our website for the following purposes: In addition, we will obtain your consent in advance when we use your information for purposes that are not related to the purposes stipulated in this website policy. However, this does not do so if it is based on laws and regulations.',
    TEXT0154: '2.1 Notice of Our Services, etc.',
    TEXT0155: 'To provide and guide various information such as information on services, etc. of the Company, its affiliates, and its affiliates, campaigns, etc.',
    TEXT0156: '2.2 Providing our services, etc.',
    TEXT0157: 'To provide, plan, develop and improve our services, investigate and prevent illegal activities and misconduct, etc.',
    TEXT0158: '2.3 Marketing activities of our services',
    TEXT0159: 'For sales, sales, marketing activities, etc. of our services',
    TEXT0160: '2.4 Contact Us',
    TEXT0161: 'To confirm the contents of inquiries, respond to inquiries, etc.',
    TEXT0162: '2.5 Creation and providing non-specific information',
    TEXT0163: 'To provide customer information to third parties such as business partners for the purpose of improving the convenience of the company or products or referring to products, etc., in a format that cannot identify an individual by itself and cannot identify an individual even by matching it with the usually assumed external information, and prohibits the act of identifying a specific individual based on such information.',
    TEXT0164: '2.6 Creating and providing statistical information',
    TEXT0165: 'To process customer information into a statistical format that does not identify you personally, and to be used by us or a third party disclosed by us for the purpose of improving the convenience of our services, our sales or marketing activities, the business and products of us or third parties, or for reference of products, etc.',
    TEXT0166: '3． Providing information',
    TEXT0167: 'We may provide you with information in the following cases:',
    TEXT0168: '3.1 When entrusting handling',
    TEXT0169: 'The Company may outsource the handling of customer information to the subcontractor company based on the company\'s instructions.',
    TEXT0170: '3.2 With your consent',
    TEXT0171: 'We may, with your consent, provide your information to third parties.',
    TEXT0172: '3.3 In accordance with laws and regulations',
    TEXT0173: 'In accordance with laws and regulations, we may provide your information to third parties without your consent.',
    TEXT0174: '4． Disclosure, correction, etc. of customer information',
    TEXT0175: 'Customers can request disclosure, correction, addition, deletion, etc. of customer information acquired by the Company through the Company\'s website by requesting the following customer consultation desk. However, if disclosure falls under any of the following, we may not disclose all or part of it, and if we decide not to disclose it, we will notify you without delay.',
    TEXT0176: 'When there is a risk of harming the life, body, property, or other rights and interests of the person or a third party',
    TEXT0177: 'When there is a risk of causing a significant hindrance to the proper implementation of the Company\'s business',
    TEXT0178: 'In case of violation of laws and regulations',
    TEXT0179: 'Questions by email',
    TEXT0180: 'Senjyu Co., Ltd. Customer Consultation Desk',
    TEXT0181: 'Email address: shop@azanafuda.com',
    TEXT0182: 'URL: http://azanafuda.co.jp',
    TEXT0183: '5． Protecting your information',
    TEXT0184: 'We will take reasonable steps to protect your information from loss, theft, misuse, unauthorized access, leakage, alteration and damage.',
    TEXT0185: 'Use of cookies ',
    TEXT0186: 'Our website uses cookies (including similar technologies) for the purpose of improving customer convenience, enhancing content, and statistically grasping usage status. Cookies are data files that a website transmits to your computer so that when you visit the same website again, you are aware that the website is accessed from your computer. We do not use cookies to obtain personally identifiable information unless we have obtained your consent.',
    TEXT0187: 'Installation of "buttons" such as SNS (social network services)',
    TEXT0188: 'On our website, we have installed "buttons" such as the following SNS on some pages. If a customer using these SNS, etc. views a page with an installed "button " such as the SNS, information such as when the customer views the page may be automatically transmitted to the sns, etc. For details, please check the following policies such as SNS.',
    TEXT0189: 'Facebook (Data Policy)',
    TEXT0190: 'Twitter(Twitter Privacy Policy)',
    TEXT0191: 'LINE(LINE Privacy Policy)',
    TEXT0192: 'Please choose one of your favorite kanji names',
    TEXT0193: 'For those who decide the kanji name for the first time, ',
    TEXT0194: 'or those who have decided the kanji name once but want to change it to another kanji name click',
    TEXT0195: 'here',
    TEXT0196: '',
    TEXT0197: 'Birthday',
    TEXT0198: 'Selected Survey',
    TEXT0199: 'Please answer the survey.',
    TEXT0200: 'have already answered the survey.',
    TEXT0201: 'Store introduction',
    TEXT0202: 'The previously decided kanji name will be deleted, is that okay?',
    TEXT0203: 'This kanji name has already been registered',
    TEXT0204: 'area',
    TEXT0205: 'The selected item does not exist either. Please start over.',
    TEXT0206: 'Selected survey',
    TEXT0207: 'The name certificate is being downloaded.',
    TEXT0208: 'Searching',
    TEXT0209: 'Our website provides information about products and participating shops. Our website does not sell products.',
    TEXT0210: 'Shopping is done directly between the customer and each shop, and we are not involved with individual shopping.',
    TEXT0211: 'If you have any ploblems with your shopping, you will have to solve them directly between the customer and the shop.',
    TEXT0212: 'When you purchase the products, please be sure to check the price, shipping fee, shipping method, terms of use, etc. on the shop\'s website.',
    TEXT0213: 'Our website does not sell products',
    TEXT0214: 'Kanji naming starts button',
    TEXT0215: 'What kind of things from Japan you are interested in.',
    TEXT0216: 'You can chose as much as you want to.',
    TEXT0217: 'Survey',
    TEXT0218: 'Takes a few minutes',
    TEXT0219: '...',
    TEXT0220: 'Full name',
    TEXT0221: 'Year',
    TEXT0222: 'Month',
    TEXT0223: 'Day',
    TEXT0224: 'New certificate',
    TEXT0225: 'English full name',
    TEXT0226: 'Kanji name',
    TEXT0227: 'Good luck you want',
    TEXT0228: 'Create date',
    TEXT0229: 'Preview',
    TEXT0230: 'Register',
    TEXT0231: 'Other',
    TEXT0232: 'Full name',
    TEXT0233: 'Convert katakana',
    TEXT0234:'English name for katakana conversion',
    TEXT0235:'Your katakana name is shown below.',
    TEXT0236:'Your full name in English.',
    TEXT0237:'A conversion error has occurred. We will fix our database for your name. Please give us your e-mail address. We will inform you as soon as our database becomes ready.',
    TEXT0238:'Cancel',
    TEXT0239: 'The infomation has been sent to the administrator.',
    TEXT0240: 'You can make a kanji name by using your katakana name! !',
    TEXT0241: 'Convert kanji',
    TEXT0242: 'Converts each sentences. Please enter first name only. Then middle name only and last name at last.',
    TEXT0243: 'Service to write your name in Calligraphy letter',
    TEXT0244: 'Calligraphy is considered one of the unique arts of Japan.',
    TEXT0245: 'Calligraphy was considered one of the important cultural features for samurai and aristocrats at that time. Like many other cultures, arts and entertainments, according to the times, calligraphy was not only for samurai and aristocrats, but also for common people.',
    TEXT0246: 'Click here to order brush art',
    TEXT2047: 'You are requesting a calligraphic writing name service. Price per use is ${cost}. Do you agree to pay?',
    TEXT2048: 'This is a QR code that contains information about your kanji name.',
    TEXT0249: 'Go to the home page',
    TEXT0250: 'Download(for free)',
    TEXT0251: 'Scan QR code here',
    TEXT0252: 'Click to get QR code',
    TEXT0253: 'Please choose an answer',
    TEXT0254: 'Please enter your answer',
    TEXT0255: 'Submit confirmation',
    TEXT0256: 'Are you sure you want to submit your answers?',
    TEXT0257: 'Submit complete',
    TEXT0258: 'Thank you for answering our survey!',
    TEXT0259: 'Error',
    TEXT0260: 'Be sure to answer all the questions.',
    TEXT0261: 'Thank you for completing our survey!',
    TEXT0262: 'Please do not insert space.',
    TEXT0263: 'Order detail',
    TEXT0264: 'Kanji name',
    TEXT0265: 'Order status',
    TEXT0266: 'Estimated delivery date',
    TEXT0267: 'Price',
    TEXT0268: 'Act on specified commercial transactions',
    TEXT0269: 'Company name',
    TEXT0270: 'Senjyu Co., Ltd.',
    TEXT0271: 'Seller',
    TEXT0272: 'Yoshinobu Koizumi',
    TEXT0273: 'Address',
    TEXT0274: 'Inaridai 18-11, Itabashi-ku, Tokyo 173-0002',
    TEXT0275: 'Phone',
    TEXT0276: '03-5939-6097',
    TEXT0277: 'Mail address',
    TEXT0278: 'info@azanafuda.com',
    TEXT0279: 'Website',
    TEXT0280: 'https://kanji-japan.tokyo/',
    TEXT0281: 'Paymet methods',
    TEXT0282: 'Credit card',
    TEXT0283: 'Other expenses',
    TEXT0284: 'Nothing',
    TEXT0285: 'Delivery time of goods',
    TEXT0286: 'We will send you the brush art within {date} days as soon as it is completed.',
    TEXT0287: 'About canceling an order',
    TEXT0288: 'As a general rule, we do not accept cancellations or changes to order details after the order has been confirmed',
    TEXT0289: 'Price (Consumption tax included)',
    TEXT0290: '{cost} dollars',
    TEXT0291: 'Result image',
    TEXT0292: 'Artist Introduction',
    TEXT0293: 'Hikonari Adachi',
    TEXT0294: 'Born in Aichi, Japan in 1969',
    TEXT0295: 'Graduated from Musashino Art University, Faculty of Art and Design',
    TEXT0296: 'Adachi is an up-and-coming artist who specializes in spatial design using brush strokes,and continues to create numerous brush stroke art works.',
    TEXT0297: 'Mr. ADACHI, a famous Japanese brush artist, captures the message emanating from your kanji name and breathes life into it.',
    TEXT0298: 'He will then carefully write an inspiring, one-of-a-kind work of art for you, detail by detail.',
    TEXT0299: 'We hope you will look forward to it!',
    TEXT0300: 'Data will be sent in ',
    TEXT0300_1: ' JPG',
    TEXT0300_2: ' format.',
    TEXT0301: 'It can be used in any way you like, for example, printed on T-shirts, used as original artwork for tattoos, etc…',
    TEXT0302: 'Price (brush art creation): ',
    TEXT0302_1:' dollars',
    TEXT0303: 'Time from order to delivery: ',
    TEXT0303_1: ' days',
    TEXT0304: 'Brushstroke art breathes new life into your kanji name.',
    TEXT0305: 'Get kanji name and bring in happiness!',
    TEXT0306: 'Hachisen',
    TEXT0307: 'Our web site will offer you a wide choice of kanji names based on your original English Name.',
    TEXT0308: 'Choose one of them and good fortune will come your way.',
    TEXT0309: 'Payment',
    TEXT0310: 'Please download',
    TEXT0311: 'Back',
    TEXT0312: 'Successfully Payment',
    TEXT0313: 'Notice',
    TEXT0314: 'Your brushstroke art creation payment has been completed.',
    TEXT0315: 'Your payment could not be completed',
    TEXT0316: 'To do',
    TEXT0317: 'Writing',
    TEXT0318: 'Resolved',
    TEXT0319: 'Done',
    TEXT0320: 'Please enter the one time password',
    TEXT0321: 'Submit',
    TEXT0322: 'Didn\'t get the code',
    TEXT0323: 'Resend',
    TEXT0324: 'Introduction of brush art creation service(paid service)',
    TEXT0325: 'T-shirts image1',
    TEXT0326: 'T-shirts image2',
    TEXT0327: 'Tattoo image1',
    TEXT0328: 'Tattoo image2',
    TEXT0329: 'Brush art example',
    TEXT0330: 'Generate Japanese Kanji Name for free',
    TEXT0331: 'Generate Japanese Kanji Name for free.',
    TEXT0332: 'Other QR Code',
    TEXT0333: 'QR code 1',
    TEXT0333_1: 'For Tattoo sticker machine',
    TEXT0334: 'QR code 2',
    TEXT0334_1: 'For Customer questionnaire',
    TEXT0335: 'This is QR Code for customer questionnaire.',
    TEXT0336: '6. Use of Google Analytics',
    TEXT0337: `This site uses Google Analytics to provide better service and improve usability.
We collect and analyze data such as the usage status of this site. At that time, Google may collect information such as your IP address through "cookies".
The information collected by "cookies" cannot identify individuals. Collected data is managed by Google's privacy policy.
By using this site, we assume that you have consented to the data processing performed by Google and this site for the above methods and purposes.

Google's privacy policy`,
    TEXT0338: 'Start converting your Kanji name',
    TEXT0339: 'Confirm the entered information',
    TEXT0340: 'Are there any errors in the entered information?',
    TEXT0341: 'Yes',
    TEXT0342: 'No',
    TEXT0343: 'Confirm the Kanji name',
    TEXT0344: 'Do you want to choose this Kanji?',
}
