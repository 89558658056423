<template>
    <div class="survey">
        <div style="margin: 20px">
            <div style="margin-bottom: 15px; font-size: 20px">{{ $t('TEXT0217') }}</div>
            <div>{{ $t('TEXT0215') }}</div>
            <div style="margin-bottom: 20px">{{ $t('TEXT0216') }}</div>
            <el-form style="">
                <el-checkbox-group v-model="input.model.surveys">
                    <el-checkbox v-for="(item, index) in surveys" :label="item.id" :key="index">{{ item.question }}
                    </el-checkbox>
                </el-checkbox-group>
                <el-form-item class="survey-btn">
                    <el-button type="primary"
                               style="margin-top: 2em"
                               :loading="input.data.loading"
                               :disabled=" input.model.surveys.length < 1"
                               @click="submitSurvey">{{ $t('TEXT0214') }}
                    </el-button>
                </el-form-item>
            </el-form>
            <SuccessDialog title=""
                           :message="$t(popup.error.messageCode)"
                           v-model="popup.error.show"
                           @cancel="handleOKPress"
                           @ok="handleOKPress"/>
        </div>

    </div>
</template>

<script>
import SurveyApi from "../api/SurveyApi";
import EventBus from "../config/EventBus";
import SuccessDialog from "../components/SuccessDialog";
import LanguageApi from "../api/LanguageApi";

export default {
    name: "Survey",
    components: {SuccessDialog},
    async mounted() {
        let langs = await LanguageApi.all();
        if (langs && langs.data && langs.data.find(item => item.locale == this.$route.query.locale)) {
            localStorage.setItem('lang', JSON.stringify(langs.data.find(item => item.locale == this.$route.query.locale)))
            this.$i18n.locale = this.$route.query.locale
        } else {
            this.$i18n.locale = 'en-us'
        }
        this.getSurvey();
        EventBus.$on('localeChange', () => {
            this.getSurvey()
        })
    },
    data() {
        return {
            input: {
                model: {
                    surveys: []
                },
                data: {
                    loading: false,
                    errorCode: null,
                }
            },
            popup: {
                error: {
                    show: false,
                    messageCode: 'TEXT0200'
                }
            },
            lang: {
                id: null,
                name: null,
                nameLang: null,
                locale: null,
                icon: null
            },
            surveys: []

        }
    },
    methods: {
        getSurvey: async function () {
            SurveyApi.getAll().then(resp => {
                this.surveys = resp.data
                console.log(this.surveys)
            }).catch(err => {
                console.log(err)
                // this.$router.push("/");
            })
        },
        handleOKPress: function () {
            if (this.popup.error.messageCode == 'TEXT0200') {
                this.$router.push('/login')
            } else if (this.popup.error.messageCode == 'TEXT0205') {
                this.input.model.surveys = []
                this.getSurvey()
            }
        },
        submitSurvey: function () {
            this.input.data.loading = true
            SurveyApi.sumitSurvey({
                token: this.$route.query.token,
                surveys: this.input.model.surveys
            }).then(resp => {
                console.log(resp)
                this.$router.push("/kanji/convert/result?token=" + this.$route.query.token)
            }).catch(err => {
                this.input.data.errorCode = err.status
                switch (err.status) {
                    case 4061: {
                        this.popup.error.messageCode = 'TEXT0200'
                        break;
                    }
                    case 4041: {
                        this.popup.error.messageCode = 'TEXT0205'
                    }
                }
                this.popup.error.show = true
            }).finally(()=>{
                this.input.data.loading = false
            })
        }
    }
}
</script>

<style scoped lang="scss">
::v-deep .el-checkbox {
    display: flex;
    white-space: initial;
}

.survey {
    display: block;
    width: 70% !important;
    margin: 20px auto;
    overflow: auto;
    background-color: white;
}

::v-deep .el-checkbox__label {
    display: inline;
}

.survey-btn {
    text-align: center;
}

@media screen and (max-width: 992px) { // mobile
    .survey {
        display: block;
        width: calc(100% - 40px) !important;
        margin: 20px 20px;
    }
}
</style>
