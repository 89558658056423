<template>
  <div :loading="loading.loader" class="survey-detail">
    <div class="survey">
      <div class="header wrap-text-parent">
        <h1 class="title wrap-text">{{ surveys.surveyName }}</h1>
        <div class="wrap-text">
          <p class="desc">{{ surveys.surveyDescription }}</p>
        </div>
      </div>
    </div>
    <el-form ref="questions" :model="surveys" class="body">
      <div
        class="survey"
        v-for="(question, index) in surveys.questions"
        :key="index"
      >
        <div class="survey-item">
          <el-form-item class="question">
            <span class="num-ques">{{ index + 1 }}.</span>
            <div class="ques-text">
              <span class="wrap-text ques-content">
                {{ question.questionContent }}
              </span>
            </div>
          </el-form-item>
          <el-form-item
            :prop="'questions.' + index + '.answerSelected'"
            v-if="question.questionType && question.questionType === 'OPTIONAL'"
            class="answer-optional"
            :rules="[
              {
                required: true,
                message: $t('TEXT0253'),
                trigger: 'change',
              },
            ]"
          >
            <div
              v-for="(answer, index) in question.answerOptional"
              :key="index"
              class="answer-radio wrap-text"
            >
              <el-radio-group
                :rules="[
                  {
                    type: 'array',
                    required: true,
                    message: $t('TEXT0253'),
                    trigger: 'change',
                  },
                ]"
                v-model="question.answerSelected"
              >
                <el-radio :label="answer.id">{{ answer.content }}</el-radio>
              </el-radio-group>
            </div>
          </el-form-item>

          <el-form-item
            v-if="question.questionType && question.questionType === 'TEXT'"
            class="question-text"
            :rules="[{ required: true, message: $t('TEXT0254') },
                    {validator: checkEmpty, trigger: ['blur', 'change'],},]"
            :prop="'questions.' + index + '.answerText'"
          >
            <el-input
              class="answer-input"
              v-model="question.answerText"
              type="textarea"
              maxlength="300"
              :autosize="{ minRows: 2, maxRows: 4 }"
            />
          </el-form-item>
        </div>
      </div>
      <div class="">
        <el-button
          :loading="loading.saving"
          type="primary"
          @click="submitForm('questions')"
          class="submit-btn"
          >{{ $t('TEXT0005') }}</el-button
        >
      </div>
    </el-form>
    <ConfirmDialog
      v-model="popup.submitConfirm.show"
      :title="$t('TEXT0255')"
      :message="$t('TEXT0256')"
      @ok="submit()"
    />
    <SuccessDialog
      v-model="popup.successMessage.show"
      :title="$t('TEXT0257')"
      :message="$t('TEXT0258')"
      @ok="$router.push('/kanji/convert/certificate')"
      @close="$router.push('/kanji/convert/certificate')"
    />
    <ErrorDialog
      v-model="popup.errorMessage.show"
      :title="$t('TEXT0259')"
      :message="$t('TEXT0260')"
    />
    <ErrorDialog
      v-model="popup.reSubmit.show"
      :message="$t('TEXT0261')"
      @ok="$router.push('/kanji/convert/certificate')"
    />
  </div>
</template>

<script>
import EventBus from "../../config/EventBus";
import SurveyApi from "../../api/SurveyApi";
import GuestApi from "@/api/GuestApi";
import ConfirmDialog from "../../components/ConfirmDialog.vue";
import ErrorDialog from "../../components/SuccessDialog.vue";
import SuccessDialog from "../../components/ErrorDialog.vue";
import CheckTokenApi from "../../api/CheckTokenApi";
export default {
  components: { SuccessDialog, ErrorDialog, ConfirmDialog, },
  data() {
    var checkEmpty = (rule, value, callback) => {
            if (!value || value.trim().length === 0) {
                callback(new Error(this.$t('TEXT0262')))
            } else {
                callback()
            }
        }
    return {
      checkEmpty: checkEmpty,
      answer: "",
      radio: "1",
      profile: {},
      lang: 2,
      popup: {
        submitConfirm: {
          data: null,
          show: false,
        },
        errorMessage: {
          data: null,
          show: false,
        },
        successMessage: {
          data: null,
          show: false,
        },
        reSubmit:{
          data: null,
          show: false,
        }

      },
      loading: {
        loader: false,
        saving: false
      },
      reload: {},
      error: [],
      surveys: {
        surveyId: 25,
        surveyName: "",
        surveyDescription: "",
        questions: [],
      },
    };
  },

  async created() {
    this.reload = this.$loading({
      lock: true,
      text: 'Loading',
      spinner: 'el-icon-loading',
      background: 'rgba(255, 255, 255, 0.7)'
    });

    await this.checkToken()
    EventBus.$on('localeChange', this.handler);
    EventBus.$emit('updateNavBar')
    setTimeout(() => { this.reload.close() }, 5000);
  },

  destroyed() {
    // Stop listening the event hello with handler
    EventBus.$off('localeChange', this.handler);
  },

  computed: {},

  watch: {},


  methods: {
    handler() {
      this.getSurvey()
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.popup.submitConfirm.show = true;
        } else {
          this.popup.errorMessage.show = true;
          return false;
        }
      });
    },
    async checkToken() {
      try {
        let resp = await CheckTokenApi.getToken({
          id : this.$route.params.id,
        });
        localStorage.setItem("token", resp.data.token);
        await this.getSurvey();
      } catch (e) {
        console.log(e);
      }
    },
    async getProfile() {
      try {
        let resp = await GuestApi.getProfile();
        this.profile = resp.data;
      } catch (err) {
        console.log(err);
      }
    },

    async getSurvey() {
      await this.getProfile();
      this.$nextTick(() => {
        this.lang = JSON.parse(localStorage.getItem('lang'))
        const req = {
          token: this.$route.query.token,
          languageId: this.lang && this.lang.id ? this.lang.id : 2,
        };
        SurveyApi.getActiveSurvey(this.trimObject(req))
          .then((resp) => {
            let res = resp.data;
            this.surveys = res;
          })
          .catch((err) => {
            if(err.status === 412){
              console.log(err.data);
              this.popup.reSubmit.data = this.$t('TEXT0261')
              this.popup.reSubmit.show = true;
            }
          }).finally(() => {
            this.reload.close()
          });
      });
      
    },
    submit() {
      this.loading.saving = true
      const req = {
        id: this.profile.id,
        surveyId: this.surveys.surveyId,
        surveyName: this.surveys.surveyName,
        surveyDescription: this.surveys.surveyDescription,
        questions: this.surveys.questions,
      };
      SurveyApi.getResponseSurvey(this.trimObject(req))
        .then((resp) => {
          console.log(resp);
          this.popup.successMessage.show = true;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading.saving = false
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
}
::v-deep .el-input__inner {
  width: 85%;
  margin-left: 30px;
}

.question-right {
  display: flex;
  column-gap: 150px;
}

.question-left {
  display: flex;
  align-items: center;
  margin-top: 15px;
}

.text {
  width: 19rem;
}

.text-answer {
  width: 220px;
}

.answer-container {
  display: flex;
  flex-direction: column;
}
::v-deep .el-radio {
  margin: 5px;
}

.num-ques {
  font-size: 14px;
  position: static;
  box-sizing: border-box;
  white-space: nowrap;
  float: left;
  padding: 5px 8px 0px;
  color: #909090;
}
.ques-text {
  position: static;
  box-sizing: border-box;
  white-space: nowrap;
  padding: 5px 8px 0;
  font-size: 15px;
}
.answer {
  padding: 12px 16px;
  margin: 0;
}
.el-input__inner {
  margin: 0 !important;
}
::v-deep .el-textarea__inner {
  font-size: 16px;
  appearance: none;
  position: static;
  margin: 5px;
  color: #161616;
  background-color: #f9f9f9;
  border-radius: 3px;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.15);
}
.answer-radio {
  margin: 5px 0;
}
.desc {
  font-style: italic;
  color: #666666;
}
.survey {
  position: relative;
  display: block;
  width: 60% !important;
  margin: 20px auto;
  background-color: white;
  border-radius: 7px;
  box-shadow: 0 2px 3px rgba($color: #000000, $alpha: 0.2);
  padding: 20px;
}
.submit-btn {
  display: block;
  width: 20% !important;
  margin: 25px auto;
  border-radius: 7px;
  box-shadow: 1px 2px 3px rgba($color: #000000, $alpha: 0.2);
  padding: 20px 20px 20px;
  font-size: 16px;
  font-weight: 600;
}
@media screen and (max-width: 991px) {
  .survey {
    display: block;
    width: 90% !important;
    margin: 20px auto;
    background-color: white;
    border-radius: 7px;
    box-shadow: 0 2px 3px rgba($color: #000000, $alpha: 0.2);
    padding: 10px;
  }
  .desc {
    margin: 0 0 10px;
  }
  .submit-btn {
    display: block;
    width: 40% !important;
    margin: 25px auto;
    border-radius: 7px;
    box-shadow: 1px 2px 3px rgba($color: #000000, $alpha: 0.2);
    padding: 15px;
    font-size: 16px;
    font-weight: 600;
  }
}
::v-deep .el-radio__label {
  display: inline-table;
  white-space: break-spaces;
  padding-right: 20px;
}
.wrap-text {
  width: 100%;
  word-break: break-all;
  // word-wrap: unset;
  display: inline-table;
}
.wrap-text-parent {
  width: 100%;
}
.ques-content {
  white-space: break-spaces;
  padding-right: 20px !important;
  width: 100%;
  display: block;
}
</style>
