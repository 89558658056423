import axios from "@/config/axios";

export default {
    getConfig:function (data){
        return axios({
            url: "/api/settings/guest/get",
            method: 'post',
            data: data
        });
    }


}
