<template>
    <div id="content" class="content" ref="content" style="padding-bottom: 10px">
        <div class="certificate-wrapper" style="position: relative; display: inline-block;">
            <div style="font-size: 16px; padding: 10px;">{{ $t('TEXT0082') }}</div>
            <div :style="size"></div>
            <div id="certificate" ref="certificate" class="certificate" :style="scale">
                <div class="top-layout">
                    <div style="font-size: 32px; line-height: 33px;  font-weight: bold">CERTIFICATE</div>
                    <div style="font-size: 20px; line-height: 21px;  margin-top: 5px">認定証</div>
                    <div style="font-size: 24px; line-height: 25px; font-weight: bold; margin-top: 100px"> {{
                            "Dear " +
                            profile.fullName
                        }}
                    </div>
                    <div style="font-size: 16px; line-height: 17px;  font-weight: bold; margin-top: 15px">
                        {{ 'IDNo: ' + profile.id }}
                    </div>
                    <div style="font-size: 16px; line-height: 17px; margin-top: 60px; color: #4f4f4f;">
                        We will named the kanji name for you.
                    </div>

                    <div style="margin-top: 10px;">
                        <table cellspacing="0" style="margin: auto">
                            <tr>
                                <td style="font-size: 16px; line-height: 17px; font-weight: bold; width: 125px; padding: 10px">
                                    Kanji
                                </td>
                                <td style=" font-size: 16px; line-height: 17px; font-weight: bold; width: 220px; padding: 10px;">
                                    Meaning
                                </td>
                            </tr>
                            <tr v-for="(item, index) in kanjis" :key="index">
                                <td style="font-size: 24px; line-height: 25px; font-weight: bold; padding: 10px">
                                    {{ item.kanji }}
                                </td>
                                <td style=" font-size: 16px; line-height: 17px; padding: 10px; line-height: 1.25; text-transform: lowercase">
                                    {{
                                        item.meaning
                                    }}
                                </td>
                            </tr>
                        </table>
                    </div>

                    <div style="margin-top: 10px;">
                        <table cellspacing="0" style="margin: auto">
                            <tr>
                                <td style="font-size: 16px; line-height: 17px; width: 125px; padding: 10px; color: #4f4f4f;">
                                    Total number of strokes
                                </td>
                                <td style="font-size: 16px; line-height: 17px; width: 220px; padding: 10px; color: #4f4f4f;">
                                    {{
                                        kanjis.reduce((a, b) => a + b.strokeCount, 0)
                                    }}
                                </td>
                            </tr>
                        </table>


                        <div style="margin-top: 10px; ">
                            <div style="padding: 10px; width: 450px; border: 1px solid #4f4f4f; margin: auto; text-align: left; ">
                                <div style="font-size : 16px; line-height: 17px; color: #4f4f4f;">
                                    {{
                                        'Fortune of ' + kanjis.reduce((a, b) => a + b.strokeCount, 0)
                                        + ' strokes in total (' + meaning.meaning + ')'
                                    }}
                                </div>
                                <div style="font-size : 14px; color: #4f4f4f; line-height: 1.25; margin-top: 10px">
                                    {{
                                        stroke.description
                                    }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="bottom-layout">
                    <div style="font-size: 16px; line-height: 17px; margin-top: 10px;">{{ date }}</div>
                    <div style="font-size: 24px; line-height: 25px; font-weight: bold; margin-top: 10px;">Senjyu
                        Co.,Ltd.
                    </div>
                </div>
            </div>


            <div style="padding-top: 10px; display: flex; justify-content: space-between">
                <div style="text-align: left">
                    <div style="font-size: 14px; border-bottom: 1px solid white; display: inline; ">
                        IDNo : {{ profile.id }}
                    </div>
                    <div style="font-size: 12px; margin-top: 5px ">{{ $t('TEXT0128') }}</div>
                </div>
                <div style="display: inline-block; white-space: nowrap">
                    <i class="fa fa-download" aria-hidden="true"
                       :element-loading-text="$t('TEXT0207')"
                       style="font-size: 12px; height: 14px"></i>
                    <a href="#" @click.prevent="downloadCertificate"
                       ref="download_btn"
                       style="text-decoration: none; color: white; margin-left: 3px" class="btn-print ">{{
                            $t('TEXT0009')
                        }}</a>
                </div>
            </div>
        </div>
        <CertificateDownloadDialog v-model="popup.certificateDownload.show"/>
    </div>
</template>
<script>


import KanjiApi from "@/api/KanjiApi";
import GuestApi from "@/api/GuestApi";
import axios from "axios";
import EventBus from "@/config/EventBus";
import moment from 'moment'
import CertificateDownloadDialog from "@/components/CertificateDownloadDialog";
// import EventBus from "@/config/EventBus";

export default {
    name: "Certificate",
    components: {CertificateDownloadDialog},
    props: {
        value: {
            type: Boolean,
            default: false
        },
    },
    computed: {
        date: function () {
            return moment(new Date()).format('MMMM DD, YYYY')
        },
        certificate() {
            console.log("kanji: ")
            console.log(this.kanjis)
            return {
                kanjiFontWeight: this.kanjis.length < 3 ? '24px' : (this.kanjis.length < 6 ? '18px' : '10px'),
                descriptionTitleFontWeight: this.kanjis.length < 3 ? '18px' : (this.kanjis.length < 6 ? '14px' : '8px'),
                descriptionFontWeight: this.kanjis.length < 3 ? '14px' : (this.kanjis.length < 6 ? '10px' : '8px'),
                padding: this.kanjis.length < 3 ? '2px' : '0px',
            }
        },
        gender() {
            return this.profile.gender;
        }
    },
    mounted() {
        this.getConvertResult()
        this.getProfile()
        this.windownEventHandler()
        EventBus.$on('localeChange', () => {
            if (this.$route.path == '/kanji/convert/certificate') {
                this.getConvertResult()
                this.getProfile()
            }
        });
        // this.downloadCertificate()
        if(this.value){
            this.$refs.download_btn.classList.add("certificate123");
        }
    },
    created() {
        window.addEventListener('resize', this.windownEventHandler)
    },
    destroyed() {
        window.removeEventListener('resize', this.windownEventHandler)
    },
    data() {
        return {
            kanjis: [],
            stroke: {},
            meaning: {},
            profile: {},
            loading: {
                fullscreen: true
            },
            scale: '',
            size: '',
            popup: {
                certificateDownload: {
                    show: false
                }
            }
        }
    },
    methods: {
        getConvertResult: function () {
            KanjiApi.getConvertResult({
                token: localStorage.getItem('token')
            }).then(resp => {
                console.log(resp);
                this.kanjis = resp.data.kanjis
                this.stroke = resp.data.stroke || {}
                this.meaning = resp.data.meaning
            })
        },
        getProfile: function () {
            GuestApi.getProfile().then(resp => {
                this.profile = resp.data
            })
        },
        downloadCertificate: function () {
            this.popup.certificateDownload.show = true
            axios.post(location.protocol + '//' + process.env.VUE_APP_API_PREFIX + location.hostname + process.env.VUE_APP_API_PORT + "/api/kanji/user/download-certificate", {}, {
                headers: {
                    Authorization: localStorage.getItem('token'),
                },
                responseType: 'blob',
            }).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'certificate.pdf'); //or any other extension
                document.body.appendChild(link);
                link.click();
            }).finally(() => {
                this.popup.certificateDownload.show = false
            })
        },
        windownEventHandler() {
            var elHeight = this.$refs['certificate'].clientHeight
            var elWidth = this.$refs['certificate'].clientWidth
            var scale = this.$refs['content'].clientWidth / elWidth
            if (scale > 1) scale = 1;
            this.size = 'height:' + scale * elHeight + 'px; width:' + +scale * elWidth + 'px';
            this.scale = 'transform: translate(-50%, -50%) scale(' + scale + ')'
            console.log(this.scale);
        }
    }
}
</script>

<style scoped lang="scss">


.content {
    text-align: center;
    color: white;

}

.certificate-wrapper {
    display: inline-block;

    .certificate {
        position: absolute;
        left: 50%;
        top: 50%;
        background-image: url("~@/assets/certificate/certificate-bg3.png");
        background-size: 100% 100%;
        width: 210mm;
        height: 297mm;
        color: #4f4f4f;
        font-family: "Noto Serif CJK JP";
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .top-layout {
            margin-top: 215px;
        }

        .bottom-layout {
            margin-bottom: 150px;
        }
    }
}

table, td, th {
    border: 1px solid black;
}

table {
    border-collapse: collapse;
}

.btn-print:hover {
    text-decoration: underline !important;
}
.certificate123{
 color: #888888 !important;
}
</style>
