<template>
    <div class=" kanji-convert-input">

        <el-form :inline="true"
                 class="kanji-convert-form"
                 :label-position="labelPosition"
                 :label-width="labelWidth">
            <el-form-item style="text-align: center; font-weight: bold">
                <h3 class="title33">{{ $t('TEXT0224') }}</h3>
            </el-form-item>

            <el-form-item :label="$t('TEXT0225')"
                          :error="input.error.fullName"
                          class="required">
                <el-input v-model="input.model.fullName"/>
            </el-form-item>

            <el-form-item :label="$t('TEXT0226')"
                          :error="input.error.kanji"
                          class="required">
                <el-input v-model="input.model.kanji"/>
            </el-form-item>

            <el-form-item :label="$t('TEXT0227')"
                          :error="input.error.meaningId"
                          class="required">
                <el-select popper-class="sl-tall" v-model="input.model.meaningId"
                           style="width: 100%"
                           :placeholder="$t('TEXT0062')">
                    <el-option v-for="(item, index) in meanings"
                               :key="index"
                               :label="item.meaning"
                               class="meaning-option"
                               :value="item.id">
                        <h3 class="meaning-title">{{ item.meaning }}</h3>
                        <div class="meaning-description">{{ item.description }}</div>
                    </el-option>
                </el-select>
                <el-tooltip class="tooltip out-tooltip"
                            :tabindex="-1"
                            slot="default"
                            effect="dark"
                            :content="$t('TEXT0068')"
                            placement="top-end">
                    <i class="el-input__icon el-icon-question pointer"></i>
                </el-tooltip>
            </el-form-item>


            <el-form-item :label="$t('TEXT0228')">
                <el-date-picker
                    class="register-date"
                    readonly
                    :value="new Date()"
                    style="width: 100%"
                    type="date">
                </el-date-picker>
                <!--                <el-input v-model="input.model.birthdate"/>-->
            </el-form-item>
            <el-form-item style="margin-bottom: 10px;">
                <div style="display: flex; justify-content: flex-end; margin-top: 10px">
                    <el-button @click="preview"
                               :loading="loading.preview"
                               type="primary"
                               style="background-color: #FE9738; border-color: #FE9738"
                               class="btn-update">
                        {{ $t('TEXT0229') }}
                    </el-button>
                    <el-button @click="saveManualCertificate"
                               :loading="loading.saving"
                               type="primary"
                               class="btn-update">
                        {{ $t('TEXT0230') }}
                    </el-button>
                </div>
            </el-form-item>

        </el-form>
        <PopupCertificate :profile="userProfile"
                          :stroke="stroke || {}"
                          :kanjis="kanjis"
                          :meaning="enMeaning"
                          v-model="popup.certificate.show"/>

        <ChangeKanjiConfirm v-model="popup.changeKanjiConfirm.show"
                            title=""
                            :message="$t('TEXT0202')"
                            @ok="saveManualCertificateForce"/>
        <ChangeKanjiConfirm v-model="popup.kanjiDecidedConfirm.show"
                            title=""
                            @ok="saveManualCertificateOld"
                            :message="$t('TEXT0203')"/>
    </div>
</template>

<script>
import PopupCertificate from "./PopupCertificate";
import KanjiApi from "../../../api/KanjiApi";
import MeaningApi from "../../../api/MeaningApi";
import GuestApi from "../../../api/GuestApi";
import StrokeApi from "../../../api/StrokeApi";
import EventBus from "../../../config/EventBus";
import ChangeKanjiConfirm from "../../../components/ChangeKanjiConfirm";

export default {
    name: "CertificateManualInsert",
    components: { PopupCertificate, ChangeKanjiConfirm },
    computed: {
        labelPosition: function() {
            return window.innerWidth > 992 ? 'left' : "top"
        },
        labelWidth: function() {
            return window.innerWidth > 992 ? '150px' : "180px"
        },
        userProfile: function() {
            return {
                id: '********',
                fullName: this.input.model.fullName,
                gender: this.input.model.gender,
                birthdate: this.input.model.birthdate,

            }
        },
        selectedMeaning: function() {
            return this.meanings.find(item => item.id == this.input.model.meaningId) || {}
        },

    },
    created() {
        this.getMeanings()
        this.getGuestDetail()
        EventBus.$on('localeChange', () => {
            if (this.$route.path == '/certificate/manual/insert') {
                this.getMeanings()
                this.setNull(this.input.error)
            }
        })
    },
    data() {
        return {
            popup: {
                certificate: {
                    show: false
                },
                changeKanjiConfirm: {
                    show: false
                },
                kanjiDecidedConfirm: {
                    show: false
                }
            },
            input: {
                model: {
                    kanji: null,
                    meaning: null,
                    // birthdate: null,
                    meaningId: null,
                    fullName: null,
                    countryId: null,
                    gender: null,
                    email: null,
                },
                error: {
                    fullName: null,
                    kanji: null,
                    meaning: null,
                    // birthdate: null
                },
            },
            kanjis: [],
            meanings: [],
            stroke: null,
            loading: {
                preview: false,
                saving: false
            },
            enMeaning:{}
        }
    },
    methods: {
        preview: function() {
            this.loading.preview = true
            this.setNull(this.input.error)
            KanjiApi.previewManualCertificate({
                kanji: this.input.model.kanji,
                fullName: this.input.model.fullName,
                meaningId: this.input.model.meaningId,
            }).then(async resp => {
                this.kanjis = resp.data.kanjis
                this.enMeaning = resp.data.meaning
                console.log("getting stroke")
                this.stroke = (await StrokeApi.getByStrokeCount({ strokeCount: this.kanjis.reduce((a, b) => a + b.strokeCount, 0) }) || {}).data
                this.popup.certificate.show = true
            }).catch(resp => {
                if (resp.status == 412) {
                    for (let item of resp.data) {
                        this.input.error[item.key] = item.value
                    }
                }
            }).finally(() => {
                this.loading.preview = false
            })
        },
        saveManualCertificate: function() {
            this.loading.saving = true
            this.setNull(this.input.error)
            KanjiApi.saveManualCertificate({
                kanji: this.input.model.kanji,
                fullName: this.input.model.fullName,
                // birthdate: this.input.model.birthdate,
                meaningId: this.input.model.meaningId,
                guestToken: this.$route.query.token,

            }).then(async resp => {
                console.log(resp)
                localStorage.setItem('token', resp.data.token)
                localStorage.setItem('showRating', true)
                localStorage.setItem('guestKey', this.$route.query.token)
                await this.getProfile()
                this.$router.push('/kanji/convert/certificate')
            }).catch(resp => {
                if (resp.status == 412) {
                    for (let item of resp.data) {
                        this.input.error[item.key] = item.value
                    }
                } else if (resp.status == 4091) { // convert history exist -> require force
                    this.popup.changeKanjiConfirm.show = true
                } else if (resp.status == 4092) { // convert history exist -> use old one
                    this.popup.kanjiDecidedConfirm.show = true
                }
            }).finally(() => {
                this.loading.saving = false
            })
        },
        saveManualCertificateForce: function() {
            this.loading.saving = true
            this.setNull(this.input.error)
            KanjiApi.saveManualCertificate({
                mode: 'force',
                kanji: this.input.model.kanji,
                fullName: this.input.model.fullName,
                // birthdate: this.input.model.birthdate,
                meaningId: this.input.model.meaningId,
                guestToken: this.$route.query.token,

            }).then(async resp => {
                console.log(resp)
                localStorage.setItem('token', resp.data.token)
                localStorage.setItem('showRating', true)
                localStorage.setItem('guestKey', this.$route.query.token)
                await this.getProfile()
                this.$router.push('/kanji/convert/certificate')
            }).catch(resp => {
                if (resp.status == 412) {
                    for (let item of resp.data) {
                        this.input.error[item.key] = item.value
                    }
                }
            }).finally(() => {
                this.loading.saving = false
                this.popup.changeKanjiConfirm.show = false
            })
        },
        saveManualCertificateOld: function() {
            this.loading.saving = true
            this.setNull(this.input.error)
            KanjiApi.saveManualCertificate({
                mode: 'old',
                kanji: this.input.model.kanji,
                fullName: this.input.model.fullName,
                // birthdate: this.input.model.birthdate,
                meaningId: this.input.model.meaningId,
                guestToken: this.$route.query.token,

            }).then(async resp => {
                console.log(resp)
                localStorage.setItem('token', resp.data)
                // localStorage.setItem('token', resp.data.token)
                // localStorage.setItem('guestKey', this.$route.query.token)
                await this.getProfile()
                this.$router.push('/kanji/convert/certificate')
            }).catch(resp => {
                if (resp.status == 412) {
                    for (let item of resp.data) {
                        this.input.error[item.key] = item.value
                    }
                }
            }).finally(() => {
                this.loading.saving = false
                this.popup.kanjiDecidedConfirm.show = false
            })
        },
        getProfile: async function() {
            let guest = (await GuestApi.getProfile() || {}).data
            localStorage.setItem('guestName', guest.kanji)
            localStorage.setItem('guestGender', guest.gender)
            EventBus.$emit('updateNavBar')
        },

        // createUser: function() {
        //     KanjiApi.createUser({
        //         kanji: this.input.model.kanji
        //     }).then(resp => {
        //         this.kanjis = resp.data
        //         this.popup.certificate.show = true
        //     }).catch(resp => {
        //         if (resp.status == 412) {
        //             for (let item of resp.data) {
        //                 this.input.error[item.key] = item.value
        //             }
        //         }
        //
        //     })
        // },
        getMeanings: function() {
            MeaningApi.all().then(resp => {
                this.meanings = resp.data
            })
        },
        getGuestDetail: function() {
            GuestApi.detail({
                token: this.$route.query.token
            }).then(resp => {
                for (let item of Object.keys(this.input.model)) {
                    this.input.model[item] = resp.data[item]
                }
            })
        },
    }
}
</script>

<style scoped lang="scss">

.view-container {
    background-color: white;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 40px 20px 20px 20px;
}

::v-deep .el-form-item {
    //width: 100%;
    display: block;
}

::v-deep .el-select {
    width: calc(100% - 150px);
}

::v-deep .el-form-item__content {
    width: calc(100% - 150px);
}

::v-deep .el-select {
    width: calc(100% - 150px);
}

::v-deep .el-date-editor {
    width: calc(100% - 150px);
}

::v-deep .birthday .el-input__prefix {
    display: none;
}

::v-deep .birthday .el-input__inner {
    padding-left: 15px !important;
}

::v-deep .register-date .el-input__inner {
    padding-left: 15px !important;
}

::v-deep .register-date .el-input__prefix {
    display: none;
}

::v-deep .el-form-item__label{
    line-height: initial;
}

@media screen and (min-width: 992px) {
    .data-form {
        max-width: 800px;
        margin: auto;
    }
}

@media screen and (max-width: 991px) {
    .view-container {
        padding: 20px;
    }
    ::v-deep .el-form-item__content {
        width: 100%;
    }

    ::v-deep .el-select {
        width: 100%;
    }
}


.meaning-option {
    height: auto !important;
}


.meaning-option h3 {
    margin: 0px;
}

.kanji-convert-input {
    text-align: center;
}

.kanji-convert-input .el-form {
    background-color: white;
}

.text-left {
    text-align: left;
}

.meaning-option h3 {
    line-height: initial;
}

::v-deep .birthday .el-input__prefix {
    display: none;
}

::v-deep .birthday .el-input__inner {
    padding-left: 15px !important;
}

.meaning-description {
    white-space: initial;
    max-width: calc(26vw - 40px);
    margin-bottom: 12px;
    line-height: initial;
    font-weight: normal !important;
}


@media screen and (min-width: 992px) { // pc
    .no-nav-view-content {
        background-image: url("~@/assets/top/bg-main.png");
        background-repeat: no-repeat;
        background-size: cover;
        min-height: 100vh;
    }


    .kanji-convert-input .el-form {
        margin-top: 20vh;
        display: inline-block;
        padding: 20px 74px;
        min-width: 26vw;
    }

    .bg-mobile {
        display: none;
    }
}

::v-deep .el-scrollbar__wrap::-webkit-scrollbar {
    width: 7px;
}

//el-select-dropdown__wrap el-scrollbar__wrap

::v-deep .el-scrollbar__wrap::-webkit-scrollbar-thumb {
    background-color: #888888; /* color of the scroll thumb */
    border-radius: 20px; /* roundness of the scroll thumb */
    //border: 1px solid #555555;  /* creates padding around scroll thumb */
}

::v-deep .el-select-dropdown__list {
    overflow: hidden;
}

::v-deep .el-select-dropdown__wrap::-webkit-scrollbar-thumb {
    background-color: #888888; /* color of the scroll thumb */
    border-radius: 20px; /* roundness of the scroll thumb */
    //border: 1px solid #555555;  /* creates padding around scroll thumb */
}

::v-deep .el-select-dropdown__wrap {
    overflow: hidden;
}

::v-deep .el-select-dropdown__list {
    overflow: hidden !important;
}


::v-deep .el-scrollbar__wrap {
    overflow: hidden !important;
}

::v-deep .el-select-dropdown__list:hover {
    overflow: hidden !important;
}


::v-deep .el-select-dropdown__wrap:hover {
    overflow: hidden !important;
}

::v-deep .no-capital-case-placeholder input::placeholder {
    text-transform: initial !important;
}

::v-deep .el-form-item {
    text-align: left;
    width: 100%;
}

@media screen and (max-width: 992px) { // mobile
    .no-nav-view-content {
        height: 100vh;
    }


    .bg-mobile {
        display: block;
        height: 320px;
        background-image: url("~@/assets/top/bg-main.png");
        background-repeat: no-repeat;
        background-size: cover;
    }

    .kanji-convert-input .el-form {
        padding: 20px;
    }

    .meaning-option h3 {
        margin: 5px 0px;
    }

    .meaning-description {
        margin-bottom: 18px;
        max-width: calc(100vw - 100px);
    }


}

.kanji-convert-input {
    text-align: center;
}

.kanji-convert-input .el-form {
    background-color: white;
}


@media screen and (min-width: 992px) { // pc


    .kanji-convert-input .el-form {
        margin-top: 20vh;
        display: inline-block;
        padding: 20px 74px;
        min-width: 26vw;
    }
}

@media screen and (max-width: 992px) { // mobile
    .kanji-convert-input .el-form {
        padding: 20px;
    }
}
</style>
