<template>
    <div class="navbar-wrapper1">
        <div class="navbar">
            <div class="left-menu">
                <a href="/">
                    <img src="@/assets/logo.png" class="logo">
                </a>
            </div>
            <div class="right-menu pc">
                <div>
                    <a v-if="!isLoggedIn" href="/login" class="link-button"
                       style="line-height: 18px; margin-right: 15px; cursor: pointer">
                        <div style="height: 20px; display: inline-block">
                            {{ $t('TEXT0027') }}
                        </div>
                    </a>
                    <el-dropdown v-if="isLoggedIn" style="margin-right: 18px; margin-top: 1px">
                        <el-button style="padding: 9px;">
                            <div style="height: 20px; line-height: 20px; margin-right: 5px; display: inline-block">
                                {{ guestName }}
                            </div>
                            <i class="el-icon-caret-bottom"/>
                        </el-button>

                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item>
                                <div style="display: flex; text-transform: capitalize"
                                     @click="$router.push('/profile')">
                                    <div> {{ $t('TEXT0051') }}</div>
                                </div>
                            </el-dropdown-item>
                            <el-dropdown-item>
                                <div style="display: flex; text-transform: capitalize" @click="logout()">
                                    <div> {{ $t('TEXT0113') }}</div>
                                </div>
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>

                </div>
                <el-dropdown>
                    <el-button style="padding: 9px; text-transform: capitalize">
                        <img :src="require('@/assets/' + (lang.icon || 'english.svg'))"
                             style="display: inline; height: 20px; line-height: 30px; vertical-align: middle">
                        {{ lang.nameLang || 'English' }}
                        <i class="el-icon-caret-bottom"/>
                    </el-button>

                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item v-for="(item, index) in languages" :key="index">
                            <div style="display: flex" @click="setLanguage(item)">
                                <img :src="require('@/assets/'+item.icon)"
                                     style="display: table; height: 20px; line-height: 20px; margin-top: 7px; margin-right: 6px">
                                <div style="text-transform: capitalize"> {{ item.nameLang }}</div>
                            </div>
                        </el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>

            <div class="right-menu mobile" @click="drawer = !drawer">
                <i class="fa fa-bars"></i>
            </div>
        </div>

        <el-drawer
                :visible.sync="drawer"
                :show-close="true"
                size="100%">
            <div class="list">
                <div class="list-item" v-if="isLoggedIn" @click="()=>{ $router.push('/profile');drawer = false }">
                    <img src="@/assets/profile-icon.png">
                    <div>{{ $t('TEXT0051') }}</div>
                </div>
                <div class="list-item"
                     @click="()=>{if(!isLoggedIn) { $router.push('/login');drawer = false }else{ logout()} }">
                    <img src="@/assets/sign-in.png">
                    <div>{{ !isLoggedIn ? $t('TEXT0027') : $t('TEXT0113')}}</div>
                </div>
            </div>
            <div class="list">
                <div class="list-item"
                     @click="showChangeLanguage = !showChangeLanguage">
                    <img src="@/assets/translate.png">
                    <div>{{ $t('TEXT0029') }}</div>
                    <div></div>
                    <i class="el-icon " :class="showChangeLanguage ? 'el-icon-caret-top' : 'el-icon-caret-bottom' "></i>
                </div>
            </div>
            <div v-for="(item, index) in languages" :key="index" class="list">
                <div class="list-item" style="padding-left: 60px;" v-if="showChangeLanguage"
                     @click="()=>{ setLanguage(item); drawer = false }">
                    <img :src="require('@/assets/'+item.icon)">
                    <div> {{ item.nameLang }}</div>
                    <i class="el-icon el-icon-check" v-if="isCurrentLang(item)" style="color: #42b983"/>
                </div>
            </div>
            <el-divider/>
        </el-drawer>

    </div>
</template>

<script>
    import LanguageApi from "@/api/LanguageApi";
    import EventBus from "@/config/EventBus";
    import GuestApi from "@/api/GuestApi";

export default {
    name: "NavBar",
    filters: {},
    computed: {
        // lang: function () {
        //     return JSON.parse(localStorage.getItem('lang') || '{}')
        // }\
        // isLoggedIn() {
        //     return localStorage.getItem('token')
        // }
        guestName() {
            return this.lang.locale == 'en-us' ? "Dear " + this.profile.fullName
                    : this.profile.kanji + '様';
            }

        },
        mounted() {
            EventBus.$on('updateNavBar', this.updateNavBar)
            this.getLanguage()
            this.updateLang()
            this.getProfile()
        },
        data() {
            return {
                profile: {
                    gender: null,
                    firstName: '',
                    fullName: '',
                    kanji: ''
                },
                device: 'pc',
                avatar: '',
                drawer: false,
                // guestName:  localStorage.getItem('guestName') + '様',
                showChangeLanguage: false,
                languages: [],
                lang: {
                    id: null,
                    name: null,
                    nameLang: null,
                    locale: null,
                    icon: null
                },
                isLoggedIn: localStorage.getItem('token')
            }
        },
        methods: {
            logout: function () {
                localStorage.removeItem('token')
                EventBus.$emit('updateNavBar')
                this.$router.push('/')
                // window.location.reload()
            },
            getLanguage: function () {
                LanguageApi.all().then(resp => {
                    this.languages = resp.data
                })
            },
            setLanguage: function (item) {
                localStorage.setItem('lang', JSON.stringify(item))
                this.updateLang()
                console.log(item)
            },
            updateLang: function () {
                console.log("setting lang")
                for (let item of (Object.keys(this.lang))) {
                    this.lang[item] = JSON.parse(localStorage.getItem('lang') || '{}')[item]
                }
                console.log("setting lang")
                this.$i18n.locale = this.lang.locale || 'en-us'
                EventBus.$emit('localeChange', true)
            }, 
            isCurrentLang: function (item) {
                let localLang = JSON.parse(localStorage.getItem('lang') || '{}')
                if (!localLang.locale) {
                    return item.locale == 'en-us'
                } else {
                    return item.locale == localLang.locale
                }
            },
            updateNavBar: function () {
                this.isLoggedIn = localStorage.getItem('token')
                // this.guestName = localStorage.getItem('guestName') + '様'
                this.getProfile()
            },
            getProfile: function () {
                if (this.$route.path.includes('/survey-user/detail/')) {
                    return;
                }

                GuestApi.getProfile().then(resp => {
                    this.profile = resp.data
                }).catch(resp => {
                    console.log(resp)
                })
            },
        }
    }
</script>

<style scoped lang="scss">
    @import "./src/style/variables";

    .navbar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 64px;
        padding: 0px 40px;
    }

    .navbar-wrapper1 {
        background-color: map-get($navBar, bg);
    }

    .left-menu {
        display: flex;
        align-items: center;
        max-height: 40px;
    }

    /*Mobile*/
    .right-menu.mobile {
        max-height: 40px;
        color: white;
        cursor: pointer;
        display: none;
    }

    .fa-bars {
        font-size: 40px;
    }

    .list-item {
        padding: 15px;
        display: flex;
        align-items: center;
        border-top: solid 0.5px #DCDFE6;
    }

    .list-item:hover {
        background-color: #e5e5e5;
    }

    .list-item > img {
        height: 24px;
        margin-left: 10px;
    }

    .list-item > div {
        margin-left: 20px;
        text-transform: capitalize;
    }

    .list-item > i {
        margin-left: auto;
        margin-right: 10px;
        font-weight: bold;
        font-size: 20px;
        color: #808080;
    }


    /*Pc*/
    .right-menu.pc {
        max-height: 40px;
        display: flex;
    }

    .link-button {
        border: 1px solid white;
        padding: 9px;
        display: inline-block;
        border-radius: 4px;
        color: white;
        text-decoration: none;
    }

    .link-button:hover {
        border: 1px solid white;
        background-color: white;
        color: map-get($color, bgPrimary);
    }

    @media screen and (max-width: 992px) {
        .navbar {
            padding: 0px 20px;
        }

        .right-menu.mobile {
            display: flex;
        }

        .right-menu.pc {
            display: none;
        }

    }
</style>
