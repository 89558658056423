<template>
    <div>
        
        <Carousel navigationEnabled
                  loop
                  :navigationNextLabel="nextLabel"
                  :navigationPrevLabel="prevLabel"
                  :perPage="perPage">
                  <Slide v-for="(item, index) in products" :key="index">
                    <div class="product-item">
                        <div class="product-wrap">
                            <img class="product-img" :src="require(`@/assets/product/${item.name}.jpg`)"  />
                        </div>
                        <div class="product-name">
                            <span>{{ $t(item.title) }}</span>
                        </div>
                    </div>
                 </Slide>
        </Carousel>
    </div>
</template>

<script>
import {Carousel, Slide} from 'vue-carousel';

export default {
    name: "Product",
    components: {
        Carousel,
        Slide
    },
    computed: {
        window: function () {
            return window;
        }
    },
    mounted() {
        
        if (window.innerWidth >= 800) {
            this.perPage = 4
        } else if (window.innerWidth >= 500) {
            this.perPage = 3
        } else if (window.innerWidth >= 350) {
            this.perPage = 2
        }  else {
            this.perPage = 1
        }
    },
    watch: {
        'products': function (val) {
            if (val.length <= 0) {
                this.$emit('setHide', this.shops.length <= 0)
            }
        }
    },
    data: function () {
        return {
            products: [
                {
                    name: 'product-1',
                    style: {left:'-20px', bottom:'40%'},
                    title: 'TEXT0325'
                },
                {
                    name: 'product-2',
                    style: {left:'-20px', bottom:'50%'},
                    title: 'TEXT0326'
                },
                {
                    name: 'product-3',
                    style: {left:'-20px', bottom:'30%'},
                    title: 'TEXT0327'
                },
                {
                    name: 'product-4',
                    style: {left:'-20px', bottom:'5%'},
                    title: 'TEXT0328'
                }
            ],
            popup: {
                shopOpenWarning: {
                    show: false,
                    item: null
                }
            },
            perPage: 5,
            nextLabel: '<span class="fa fa-angle-right fa-2x" style="color: #BDBDBD; border: 1px solid #BDBDBD; border-radius: 50%; width: 26px; height: 26px; font-weight: 100; background-color: white"></span>',
            prevLabel: '<span class="fa fa-angle-left fa-2x" style="color: #BDBDBD; border: 1px solid #BDBDBD; border-radius: 50%; width: 25px; height: 25px; font-weight: 100; background-color: white"></span>'
        }
    },
    methods: {
        getPic(index) {
            return '../assets/' + this.products[index].name + '.png';
        }
    }
}
</script>

<style scoped lang="scss">

.divider {
    height: 1px;
    background-color: #dcdfe6;
    margin: 10px;
}

.card-wrapper {
    all: unset;
    color: unset;
    cursor: pointer;
}

::v-deep .VueCarousel-dot-container {
    display: none;
}

::v-deep .VueCarousel-pagination {
    display: none;
}

::v-deep .VueCarousel-navigation-next {
    right: 32px !important;
}

::v-deep .VueCarousel-navigation-prev {
    left: 32px !important;
}


.product-wrap{
    height: 160px;
    display: flex;
    justify-content: center;

}
.paragraph{
    margin-top: 15px;
}
.product-img{
    height: 97%;
}
@media screen and (min-width: 1200px) and (max-width: 1599px) {
    .product-wrap{
    height: 145px !important;
    display: flex;
    justify-content: center;
    }
}
@media screen and (min-width: 900px)  {
    .product-name{
    width:80% !important;
    text-align: center;
}
    .product-wrap{
    height: 190px;
    display: flex;
    justify-content: center;
    }
}
@media screen and (max-width: 500px)  {
    .product-name{
        display: flex;
        width: 95% !important;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
}
.dis{
    display: flex;
    justify-content: space-between;
}

.product-item{
    display: flex;
    flex-direction: column;
    color: #dcdfe6;
}


.product-name{
        margin-left: 10px;
        display: flex;
        width: 90%;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
</style>
