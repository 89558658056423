import ja from "element-ui/src/locale/lang/ja";

export default {
    ...ja,
    TEXT0001: '国籍',
    TEXT0002: '英語名（ファーストネーム）',
    TEXT0003: '性別',
    TEXT0004: '希望する幸運',
    TEXT0005: '確認',
    TEXT0006: '男性',
    TEXT0007: '女性',
    TEXT0008: '漢字変化',
    TEXT0009: 'PDFをダウンロード(無料)',
    TEXT0010: '名入れサービスのご紹介',
    TEXT0011: '検索',
    TEXT0012: '商品一覧',
    TEXT0013: 'ホームページ',
    TEXT0014: '印刷依頼',
    TEXT0015: '個',
    TEXT0016: '合計',
    TEXT0017: '依頼内容',
    TEXT0018: '名前',
    TEXT0019: 'メールアドレス',
    TEXT0020: '電話番号',
    TEXT0021: '住所',
    TEXT0022: '発注する',
    TEXT0023: '注文ありがとうございます。',
    TEXT0024: '新しいパスワード',
    TEXT0025: '確認のパスワード',
    TEXT0026: 'アカウント情報登録',
    TEXT0027: 'ログイン',
    TEXT0028: '登録',
    TEXT0029: '言語の変更',
    TEXT0030: '日本語',
    TEXT0031: '英語',
    TEXT0032: 'パスワード',
    TEXT0033: 'パスワードの再設定はこちら',
    TEXT0034: '送信',
    TEXT0035: 'パスワード再設定の手続き',
    TEXT0036: '送信完了',
    TEXT0037: 'パスワード再設定の為にメールを送信しましたのでご確認してください。',
    TEXT0038: 'トップページへ戻る',
    TEXT0039: 'パスワード再設定',
    TEXT0040: 'パスワード変更完了',
    TEXT0041: '新しいパスワードの設定が完了しました。下記よりログインしてください。',
    TEXT0042: 'ログイン画面',
    TEXT0043: '電話番号（任意）',
    TEXT0044: '住所（任意）',
    TEXT0045: '新規登録',
    TEXT0046: 'あなたの情報をいれてください',
    TEXT0047: 'プライバシーポリシーの合意',
    TEXT0048: '旧パスワード',
    TEXT0049: '保存',
    TEXT0050: 'パスワードの変更',
    TEXT0051: 'プロファイル',
    TEXT0052: '名前（フルネーム）',
    TEXT0053: 'Send password confirm',
    TEXT0054: 'One email will be send to your email',
    TEXT0055: 'アカウント情報の登録',
    TEXT0056: 'プライバシーポリシー ',
    TEXT0057: 'の合意',
    TEXT0058: '英文表記名',
    TEXT0059: '性別',
    TEXT0060: 'フルネーム（英名）例：Micheal Jackson',
    TEXT0061: 'ファーストネーム（英名）例：Micheal',
    TEXT0062: '希望する運勢',
    TEXT0063: '（以下，「当社」といいます。）は，本ウェブサイト上で提供するサービス（以下,「本サービス」といいます。）における，ユーザーの個人情報の取扱いについて，以下のとおりプライバシーポリシー（以下，「本ポリシー」といいます。）を定めます。第1条（個人情報）',
    TEXT0064: '「個人情報」とは，個人情報保護法にいう「個人情報」を指すものとし，生存する個人に関する情報であって，当該情報に含まれる氏名，生年月日，住所，電話番号，連絡先その他の記述等により特定の個人を識別できる情報及び容貌，指紋，声紋にかかるデータ，及び健康保険証の保険者番号などの当該情報単体から特定の個人を識別できる情報（個人識別情報）を指します。第2条（個人情報の収集方法）',
    TEXT0065: '当社は，ユーザーが利用登録をする際に氏名，生年月日，住所，電話番号，メールアドレス，銀行口座番号，クレジットカード番号，運転免許証番号などの個人情報をお尋ねすることがあります。また，ユーザーと提携先などとの間でなされたユーザーの個人情報を含む取引記録や決済に関する情報を,当社の提携先（情報提供元，広告主，広告配信先などを含みます。以下，｢提携先｣といいます。）などから収集することがあります。第3条（個人情報を収集・利用する目的）',
    TEXT0066: '当社が個人情報を収集・利用する目的は，以下のとおりです。',
    TEXT0067: 'この名前（ファーストネーム）が漢字に変わります。',
    TEXT0068: '日本では古来より名前に使用する漢字の総画数がその者の将来の運勢を左右すると信じられてきました。８つの幸運からあなたが最も望むものを１つ選択してください。その幸運を導く画数をもつ漢字の組み合わせを考えます、そして、あなたに最良の漢字名をプレゼントします。',
    TEXT0069: '＊送信をクリックしたらあなたのアドレスにURLを送ります。',
    TEXT0070: 'あなたの情報を入れてください',
    TEXT0071: 'メール送信完了',
    TEXT0072: 'メールが送信されました。 メール内のボタン押下で、後で変換できます。',
    TEXT0073: 'ローディング',
    TEXT0074: 'お問い合わせ',
    TEXT0075: 'お名前',
    TEXT0076: 'お問い合わせ内容',
    TEXT0077: '自分だけでは漢字名を決められないという方は漢字名に込めたい思いや人生で一番大切にしているものなどを教えてください。日本人スタッフが１０種の漢字名の中からおすすめの漢字を連絡します。',
    TEXT0078: '送信する',
    TEXT0079: 'この漢字に決定する',
    TEXT0080: '別の漢字名をつくる',
    TEXT0081: '別の漢字を作りたい方はこちらをクリックしてください。',
    TEXT0082: 'あなたの漢字名が決まりました！おめでとうございます。',
    TEXT0083: 'おめでとうございます！',
    TEXT0084: 'このすてきな漢字名をつかってあなただけのオリジナル商品をつくってみませんか！！',
    TEXT0085: '次へ',
    TEXT0086: 'こちらの漢字命名サービスは満足ですか　',
    TEXT0087: '大変満足',
    TEXT0088: '完了',
    TEXT0089: 'エリアを決定する',
    TEXT0090: 'エリアを指定する',
    TEXT0091: '漢字が決められませんか？管理者に連絡しましょう。',
    TEXT0092: '総画数',
    TEXT0093: 'IDNo',
    TEXT0094: 'IDNoが忘れた場合は',
    TEXT0095: 'こちら',
    TEXT0096: 'IDNoの再表示',
    TEXT0097: 'アカウントに1通のメールが送信されました。 メールを確認してください',
    TEXT0098: '漢字名',
    TEXT0099: '満足度',
    TEXT0100: 'ようこそ日本へ',
    TEXT0101: 'このサイトはあなたの英語名から日本のしきたりに則って最適な漢字名を命名するサービスを提供します。',
    TEXT0102: 'そして、その漢字名をつかって世界に１つだけのオリジナル商品を作るサービスを案内します。',
    TEXT0103: '漢字名を決めたい方は',
    TEXT0104: 'をクリック',
    TEXT0105: 'すでに名前が決まっている方（IDNo発行済の方）は',
    TEXT0106: 'こちら',
    TEXT0107: 'をクリックし、ログイン後、商品紹介画面にお進みくださいアニメーションでサイト紹介',
    TEXT0108: 'IDNoをメールに送信いたしました。メールの内容をご確認お願い致します。',
    TEXT0109: '英訳',
    TEXT0110: '自分だけでは漢字名を決められない方、日本人スタッフにアドバイスをもらいたい方はこちらをクリックしてください',
    TEXT0111: '別の漢字名をつくる',
    TEXT0112: 'お名前に合った漢字名が見つかりません',
    TEXT0113: 'ログアウト',
    TEXT0114: 'アクション日',
    TEXT0115: 'アクションした店舗',
    TEXT0116: '海外発送可能',
    TEXT0117: '海外発送不可',
    TEXT0118: '問い合わせ送信完了',
    TEXT0119: '管理者に問い合わせを送信しました。',
    TEXT0120: '大変不満',
    TEXT0121: '不満',
    TEXT0122: '普通',
    TEXT0123: '満足',
    TEXT0124: '大変満足',
    TEXT0125: 'プロファイル変更完了',
    TEXT0126: 'プロファイルの変更が完了しました。',
    TEXT0127: 'おすすめ店舗',
    TEXT0128: '＊商品注文の際、このIDNoを入力ください',
    TEXT0129: '株式会社泉壽　ウェブサイトポリシー',
    TEXT0130: 'このウェブサイトは泉壽株式会社（以下、「当社」といいます）が管理運営しています。お客様がこのウェブサイト（以下、「当社ウェブサイト」といいます）をご利用になる際には、事前に以下の事項（以下、「本ウェブサイトポリシー」といいます）をお読みいただき、同意された場合にのみご利用ください。なお、本ウェブサイトポリシーは予告なく変更される場合があります。常に最新の内容をご確認いただきますようお願いいたします。',
    TEXT0131: 'また、当社ウェブサイト内で、ウェブサイトの利用条件について個別に定めている場合は、本ウェブサイトポリシーと当該利用条件のいずれもが適用されることをご理解いただき、これらを遵守して当社ウェブサイトをご利用ください。なお、本ウェブサイトポリシーと当該利用条件の定めが異なる場合は、当該利用条件が本ウェブサイトポリシーに優先して適用されるものとします。',
    TEXT0132: '著作権について',
    TEXT0133: '当社ウェブサイトに掲載されている文章、写真、映像、音楽、デザインなど、すべてのコンテンツの著作権は、当社もしくは当社にその利用を認めた権利者に帰属します。これらの著作権は、各国の著作権法、各種条約、その他の法律で保護されております。各コンテンツは、明示された制限に従って、法律により認められた範囲内で、ダウンロードしたり、プリントアウトして使用することを除き、コピー、配信、掲示、送信、削除、変更、翻案等を含む他の利用は固くお断りします。',
    TEXT0134: '免責事項',
    TEXT0135: '当社は、常に細心の注意を払って当社ウェブサイトを運営管理しておりますが、情報および動作の正確性、完全性を保証するものではありません。お客様が当社ウェブサイトを利用されたこと、もしくは何らかの原因により利用することができなかったことによって生じるいかなる損害（ソフトウェア、ハードウェア上のトラブル等を含みます）についても、当社は何ら責任を負うものではありません。',
    TEXT0136: 'リンクについて',
    TEXT0137: '当社ウェブサイトへのリンクは原則自由です。ただし、画像やPDFファイルへの直接リンクはご遠慮ください。なお、リンクされる場合は当社ウェブサイトへのリンクであることを必ず明記するとともに、当社ウェブサイトにリンクを張っているウェブサイト（以下、「リンクサイト」といいます）に掲載されている商品、サービス、会社等の情報が当社との間で提携等の特別な関係があるような誤解を生じるおそれのある記載・表現を差し控えてください。',
    TEXT0138: 'また、リンク用のバナーは用意しておりませんので、テキストからリンクを張っていただきますようお願いします。',
    TEXT0139: 'なお、当社ウェブサイトから、もしくは当社ウェブサイトへリンクを張っているリンクサイトは当社の管理下にあるものではなく、それらをご利用になったことにより生じるいかなる損害についても、当社は何ら責任を負うものではありません。',
    TEXT0140: 'お客様情報の取り扱いについて',
    TEXT0141: '当社は、お客様のプライバシーを尊重し、お客様の情報を大切に保護することを重要な責務と考えております。当社ウェブサイトのご利用に関してお客様から取得する情報（以下、「お客様情報」といいます）を、当社は以下のように取り扱いいたします。',
    TEXT0142: '1. 当社がお客様から取得する情報',
    TEXT0143: '当社は、当社ウェブサイトのご利用に関して以下のお客様情報を取得します。',
    TEXT0144: '1.1　購入情報',
    TEXT0145: '当社は、"漢字命名サービス”を通じてお客様情報（名前、メールアドレス、生年月日、国籍、性別など）を取得します。 ',
    TEXT0146: '1.2　お問い合わせ情報',
    TEXT0147: '当社は、お客様からお問い合わせやご意見を頂戴する際に、連絡先情報等を取得します。',
    TEXT0148: '1.3　当社ウェブサイト上での行動履歴',
    TEXT0149: '当社は、お客様が当社ウェブサイトを利用する際に、当社ウェブサイトの利用履歴、閲覧情報等を取得することがあります。',
    TEXT0150: '1.4　情報端末および接続環境に関する情報',
    TEXT0151: '当社は、お客様の情報端末から、製品モデル、情報端末の設定、IPアドレス、その他の情報端末固有の識別子等の情報を取得することがあります。',
    TEXT0152: '2. 情報の利用目的',
    TEXT0153: '当社は、当社ウェブサイトに関連して取得したお客様情報を以下の目的のために利用します。また、当社は、お客様情報を本ウェブサイトポリシーに規定する目的と関連性のない目的に利用する場合には、あらかじめお客様の同意を取得します。ただし、法令に基づく場合はこの限りではありません。',
    TEXT0154: '2.1　当社サービス等に関するお知らせ',
    TEXT0155: '当社、当社関係会社および当社提携先のサービス等に関する情報、キャンペーン等の各種情報のご提供・ご案内等のため',
    TEXT0156: '2.2　当社サービスの提供等',
    TEXT0157: '当社のサービス等の提供、企画、開発および改良等や、違法行為および不正行為の調査および防止等のため',
    TEXT0158: '2.3　当社サービスのマーケティング活動',
    TEXT0159: '当社のサービス等の営業、販売、マーケティング活動等のため',
    TEXT0160: '2.4　お問い合わせ',
    TEXT0161: 'お問い合わせ内容の確認、ご返答等のため',
    TEXT0162: '2.5　非特定性情報の作成および提供',
    TEXT0163: 'お客様情報を、それ自体では個人を特定できず、かつ通常想定される外部情報との照合でも個人を特定できない形式とし、当該情報を元に特定の個人を識別する行為を禁止したうえで、当社または第三者の業務および製品の利便性向上または製品の参考等を目的として、提携先等の第三者に提供するため',
    TEXT0164: '2.6　統計情報の作成および提供',
    TEXT0165: 'お客様情報を、お客様個人を特定できない統計的な形式に加工し、当社の各種サービス、当社の営業またはマーケティング活動、当社または第三者の業務および製品の利便性向上または製品の参考等を目的として、当社または当社より開示を受けた第三者が利用するため',
    TEXT0166: '3．情報の提供',
    TEXT0167: '当社は、以下の場合にお客様情報を提供することがあります。',
    TEXT0168: '3.1　取り扱いを委託する場合',
    TEXT0169: '当社は、委託先企業に、当社の指示に基づきお客様情報の取り扱いを委託することがあります。',
    TEXT0170: '3.2　お客様の同意を得た場合',
    TEXT0171: '当社は、お客様の同意を得て、お客様情報を第三者に提供することがあります。',
    TEXT0172: '3.3　法令に基づく場合',
    TEXT0173: '法令に基づく場合は、お客様の同意を得ることなく、お客様情報を第三者に提供することがあります。',
    TEXT0174: '4．お客様情報の開示・訂正等',
    TEXT0175: 'お客様は以下のお客様ご相談窓口に対しご請求いただくこと等により、当社ウェブサイトを通じて当社が取得するお客様情報に関して、開示、訂正、追加または削除等を求めることができます。ただし、開示することにより次のいずれかに該当する場合は、その全部または一部を開示しないことがあり、開示しない決定をした場合には、その旨を遅滞なく通知します。',
    TEXT0176: 'ご本人または第三者の生命、身体、財産その他の権利利益を害するおそれがある場合',
    TEXT0177: '当社の業務の適正な実施に著しい支障を及ぼすおそれがある場合',
    TEXT0178: '法令に違反することとなる場合',
    TEXT0179: 'メールでのご質問',
    TEXT0180: '泉壽株式会社　お客様ご相談窓口',
    TEXT0181: 'メールアドレス：shop@azanafuda.com',
    TEXT0182: 'URL：http://azanafuda.co.jp',
    TEXT0183: '5．お客様情報の保護',
    TEXT0184: '当社は、お客様情報を滅失、窃取、悪用、不正アクセス、漏えい、改ざんおよび毀損から保護するために必要な合理的な措置を講じます。',
    TEXT0185: 'クッキー（Cookie）等の使用について',
    TEXT0186: '当社ウェブサイトでは、お客様の利便性の向上やコンテンツの充実、利用状況の統計的把握等を目的として、クッキー（類似の技術を含みます）を使用しております。クッキーとは、ウェブサイトがお客様のコンピューターに送信するデータファイルで、お客様が同じウェブサイトを再度訪れた際に、そのウェブサイトがお客様のコンピューターからのアクセスであることを認識できるようにするためのものです。お客様の承諾を得た場合を除き、当社がクッキーの使用によって個人を特定できる情報を取得することはありません。',
    TEXT0187: 'SNS（ソーシャルネットワークサービス）等の「ボタン」等の設置',
    TEXT0188: '当社ウェブサイトでは、一部のページで下記のSNS等の「ボタン」等を設置しております。これらのSNS等をご利用のお客様が当該SNS等の「ボタン」等の設置されたページを閲覧された場合、お客様が当該ページを閲覧した等の情報が、当該SNS等に対して自動で送信される場合があります。詳細については、各SNS等の下記のポリシーをご確認ください',
    TEXT0189: 'Facebook（データに関するポリシー）',
    TEXT0190: 'Twitter（Twitterのプライバシーポリシー）',
    TEXT0191: 'LINE（LINEプライバシーポリシー）',
    TEXT0192: 'この中からお好きな漢字名を１つお選びください',
    TEXT0193: '初めて漢字名を決める方、または、',
    TEXT0194: '一度漢字名を決めたが別の漢字名に変更したい方は',
    TEXT0195: 'こちら',
    TEXT0196: 'をクリック',
    TEXT0197: '生年月日',
    TEXT0198: '選択されたアンケート',
    TEXT0199: 'アンケートに回答してください。',
    TEXT0200: 'アンケートに既に回答しました。',
    TEXT0201: '名入れサービスのご紹介',
    TEXT0202: '前に決定した漢字名は削除されますがよろしですか?',
    TEXT0203: 'この漢字名はすでに登録済みです',
    TEXT0204: 'エリア指定なし',
    TEXT0205: '選択した項目は存在しません。最初からやり直してください。',
    TEXT0206: '選択したアンケート',
    TEXT0207: '名前鑑定書のダウンロード中です。',
    TEXT0208: '検索中',
    TEXT0209: '当ウエブサイトは製品や参加している各ショップの情報などを提供しています。当ウエブサイトでは商品の販売は行っておりません。',
    TEXT0210: 'お買い物は、お客様と各ショップとの間で直接行っていただくもので、 当社は個々のお買い物には関与していません。',
    TEXT0211: 'お買い物に関してトラブルが生じた場合はお客様とショップとの間で直接解決いただくことになります。',
    TEXT0212: 'ご購入の際は、必ずショップのWebサイトで価格・送料・発送方法・利用規定などをご確認下さい。',
    TEXT0213: '当社では商品を販売しておりません',
    TEXT0214:'漢字命名スタート',
    TEXT0215:'日本の様々な物事について興味を持っていることは何ですか',
    TEXT0216:'複数回答可',
    TEXT0217:'アンケート',
    TEXT0218:'数分かかります',
    TEXT0219:'。。。',
    TEXT0220: 'フルネーム',
    TEXT0221: '年',
    TEXT0222: '月',
    TEXT0223: '日',
    TEXT0224:'新規証明書登録',
    TEXT0225:'フルネーム（英名）',
    TEXT0226:'漢字名',
    TEXT0227:'希望する運勢',
    TEXT0228:'登録日',
    TEXT0229:'プリビュー',
    TEXT0230:'登録',
    TEXT0231:'その他',
    TEXT0232:'フルネーム',
    TEXT0233:'カタカナに変換',
    TEXT0234:'カタカナに変えたい英語名',
    TEXT0235:'カタカナの変換結果は下記の通りです。',
    TEXT0236:'英語名フルネーム',
    TEXT0237:'変換エラーが発生しました。あなたの名前用にデータベースを修正します。メールアドレスをお知らせください。データベースの準備が整ったらすぐにお知らせします。',
    TEXT0238:'キャンセル',
    TEXT0239: '管理者に情報を送信しました。',
    TEXT0240: 'このカタカナ名をつかって漢字名をつくれるよ！！',
    TEXT0241: '漢字変換',
    TEXT0242: 'ファーストネーム、ミドルネーム、セカンドネームはそれぞれ別に入力してください。',
    TEXT0243: '名前書道サービス',
    TEXT0244: '書道とは芸術的に表現する日本独自の伝統の一つだと言われています',
    TEXT0245: '書道は当時の武士や貴族にとっては大切な教養の1つであるとされていました。その他多くの文化や芸術、娯楽などと同様に、時代が進むにつれて書道も武士や貴族だけのものではなく、庶民の間にも広く伝わるようになっていきます。',
    TEXT0246: '手筆アート依頼',
    TEXT2047: '手筆アートサービスのご依頼です。 1 回あたりの料金は ${cost}です。 支払うことに同意しますか?',
    TEXT2048: 'あなたの漢字名の情報が入ったQRコードです。',
    TEXT0249: '証明書に移動する',
    TEXT0250: 'ダウンロード(無料)',
    TEXT0251: 'スキャンはこちら',
    TEXT0252: 'QRコードを取得するにはこちら',
    TEXT0253: '必ず選択肢を指定してください。',
    TEXT0254: '必ず回答を入力してください。',
    TEXT0255: '確認',
    TEXT0256: 'このアンケットを送信します。よろしいでしょうか？',
    TEXT0257: 'アンケット回答完了',
    TEXT0258: 'アンケットをご回答ありがとうございました。',
    TEXT0259: 'エラー',
    TEXT0260: '必ずすべての質問を回答してください。',
    TEXT0261: 'アンケートにご回答いただきありがとうございます。',
    TEXT0262: 'スペースを挿入しないでください。',
    TEXT0263: '注文詳細',
    TEXT0264: '漢字名',
    TEXT0265: '注文の状況',
    TEXT0266: 'お届け予定日',
    TEXT0267: '価格',
    TEXT0268: '特定商取引法に基づく表記',
    TEXT0269: '会社名',
    TEXT0270: '株式会社泉壽',
    TEXT0271: '販売責任者',
    TEXT0272: '小泉慶展',
    TEXT0273: '所在地',
    TEXT0274: '〒173-0002　東京都板橋区稲荷台18-11',
    TEXT0275: '電話番号',
    TEXT0276: '03-5939-6097',
    TEXT0277: 'メールアドレス',
    TEXT0278: 'info@azanafuda.com',
    TEXT0279: 'ウエブサイト',
    TEXT0280: 'https://kanji-japan.tokyo/',
    TEXT0281: 'お支払方法',
    TEXT0282: 'クレジットカード決済',
    TEXT0283: '商品代金以外の必要金額',
    TEXT0284: 'なし',
    TEXT0285: '商品・サービスのお届け時期',
    TEXT0286: '毛筆アートは完成しだい、{date}日以内に再度送信させていただきます。',
    TEXT0287: 'ご注文のキャンセルについて',
    TEXT0288: '原則として注文確定後のキャンセル、ご注文内容の変更は受け付けておりません。',
    TEXT0289: '販売価格（消費税込み価格）',
    TEXT0290: '{cost}米ドル',
    TEXT0291: '結果画像',
    TEXT0292: 'アーティスト紹介',
    TEXT0293: '安達彦也（あだちひこなり）',
    TEXT0294: '1969年生まれ愛知県出身',
    TEXT0295: '武蔵野美術大学造形学部卒業',
    TEXT0296: '毛筆デザインなどのよる空間演出を得意とし、数多くの毛筆アート作品を創出し続ける新進気鋭のアーティストである。',
    TEXT0297: '日本の有名な毛筆アーティストであるMr.ADACHIが漢字名から発せられるメッセージを受けてその漢字名に魂を吹き込みます。',
    TEXT0298: 'そして、あなたのためにワクワクする唯一無二の作品を１つ１つ丁寧に書き上げます。',
    TEXT0299: 'どうぞ楽しみにお待ちください！！',
    TEXT0300: 'データは',
    TEXT0300_1: 'JPG',
    TEXT0300_2: '形式でお送りします。',
    TEXT0301: '使い方は自由自在、例えばTシャツにプリントしたり、TATOOの原画として使用したり・・・',
    TEXT0302: '価格(毛筆アート作成)：',
    TEXT0302_1: 'ドル',
    TEXT0303: 'ご注文から納入までに期間：',
    TEXT0303_1: '日',
    TEXT0304: '毛筆アートがあなたの漢字名に新たな息吹を吹き込みます。',
    TEXT0305: 'Get kanji name and bring in happiness!',
    TEXT0306: 'Hachisen',
    TEXT0307: 'Our web site will offer you a wide choice of kanji names based on your original English Name.',
    TEXT0308: 'Choose one of them and good fortune will come your way.',
    TEXT0309: '決済',
    TEXT0310: 'どうぞ　ダウンロードしてください。',
    TEXT0311: '戻る',
    TEXT0312: '支払い完了',
    TEXT0313: 'エラー',
    TEXT0314: '手筆アート作成お支払い手続きが完了しました。',
    TEXT0315: 'お支払いに失敗しました。',
    TEXT0316: '依頼待ち',
    TEXT0317: '作成中',
    TEXT0318: '作成済',
    TEXT0319: '完了',
    TEXT0324: '毛筆アート作成サービスの紹介(有料)',
    TEXT0325: 'Tシャツ イメージ画像1',
    TEXT0326: 'Tシャツ イメージ画像2',
    TEXT0327: 'タトゥー　イメージ画像1',
    TEXT0328: 'タトゥー　イメージ画像2',
    TEXT0329: '毛筆アート　例',
    TEXT0330: '漢字交換は無料',
    TEXT0331: 'Generate Japanese Kanji Name for free.',
    TEXT0332: 'もう一つのＱＲコード',
    TEXT0333: 'QRコード1 ',
    TEXT0333_1: 'タトゥーシール機用',
    TEXT0334: 'QRコード2',
    TEXT0334_1: 'お客様アンケート用',
    TEXT0335: 'これはお客様アンケートに使用するQRコードです',
    TEXT0336: '6. Googleアナリティクスの使用について',
    TEXT0337: `当サイトでは、より良いサービスの提供、またユーザビリティの向上のため、Googleアナリティクスを使用し、
当サイトの利用状況などのデータ収集及び解析を行っております。 その際、「Cookie」を通じて、Googleがお客様のIPアドレスなどの情報を収集する場合がありますが、
「Cookie」で収集される情報は個人を特定できるものではありません。 収集されたデータはGoogleのプライバシーポリシーにおいて管理されます。
なお、当サイトのご利用をもって、上述の方法・目的においてGoogle及び当サイトが行うデータ処理に関し、 お客様にご承諾いただいたものとみなします。
     
Googleのプライバシーポリシー`,
    TEXT0338: '漢字交換開始',
    TEXT0339: '入力内容確認',
    TEXT0340: '入力内容に誤りはありませんか?',
    TEXT0341: 'はい',
    TEXT0342: 'いいえ',
    TEXT0343: '漢字名の確認',
    TEXT0344: 'この漢字に決定しますか?',
}
