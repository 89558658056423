import axios from 'axios'

const service = axios.create({
    baseURL: location.protocol + '//' + process.env.VUE_APP_API_PREFIX + location.hostname + process.env.VUE_APP_API_PORT,
    timeout: 300000 // request timeout
})
service.defaults.timeout = 300000;
axios.defaults.timeout = 300000;
service.interceptors.request.use(
    config => {
        // do something before request is sent
        // if (store.getters.token) {
        //     config.headers['authorization'] = getToken()
        // }
        config.headers['Accept-Language'] = (JSON.parse(localStorage.getItem('lang') || '{}').locale || 'en-us')
        config.headers['authorization'] = localStorage.getItem('token') || ''
        return config
    },
    error => {
        return Promise.reject(error)
    }
)

service.interceptors.response.use(
    response => {
        const resData = response.data
            // if the custom code is not 200, it is judged as an error.
        if (resData.status !== 200) { 
            //case respone binary
            if (resData.constructor.name === 'Blob') return resData
            return Promise.reject(resData)
        } else {
            return resData
        }
    }, error => {
        return Promise.reject(error)
    }
)

export default service;