<template>
    <div class="survey">
        <div class="container">
            <div class="store-name">
                <p>Store name/code</p>
                <p>Survey for Shop</p>
            </div>
            <div class="name">
                <p>English Name</p>
                <p>AnhNCT</p>
            </div>

            <div class="kanji">
                <p>Kanji Name</p>
                <p>亜无覇</p>
            </div>

            <div class="kanata">
                <p>Danh pháp kanji</p>
                <p>123</p>
            </div>
        </div>

       <div class="body">
        <el-row :gutter="20">
          <el-col :span="6"><div class="grid-content ">
            <el-radio v-model="radio1" label="1" border> Survey A
            </el-radio>
            
          </div></el-col>
          <el-col :span="6"><div class="grid-content ">
            <el-radio v-model="radio1" label="2" border>Survey B</el-radio>
          </div></el-col>
          <el-col :span="6"><div class="grid-content ">
            <el-radio v-model="radio1" label="3" border>Survey C</el-radio>
          </div></el-col>
          <el-col :span="6"><div class="grid-content ">
            <el-radio v-model="radio1" label="4" border>Survey D</el-radio>
          </div></el-col>

          <el-col :span="6"><div class="grid-content ">
            <el-radio v-model="radio1" label="100" border>Survey E</el-radio>
          </div></el-col>
          <el-col :span="6"><div class="grid-content ">
            <el-radio v-model="radio1" label="5" border>Survey F</el-radio>
          </div></el-col>
          <el-col :span="6"><div class="grid-content ">
            <el-radio v-model="radio1" label="6" border>Survey G</el-radio>
          </div></el-col>
          <el-col :span="6"><div class="grid-content ">
            <el-radio v-model="radio1" label="7" border>Survey H</el-radio>
          </div></el-col>

          <el-col :span="6"><div class="grid-content ">
            <el-radio v-model="radio1" label="8" border>Survey J</el-radio>
          </div></el-col>
          <el-col :span="6"><div class="grid-content ">
            <el-radio v-model="radio1" label="9" border>Survey K</el-radio>
          </div></el-col>
          <el-col :span="6"><div class="grid-content ">
            <el-radio v-model="radio1" label="10" border>Survey T</el-radio>
          </div></el-col>
          <el-col :span="6"><div class="grid-content ">
            <el-radio v-model="radio1" label="11" border>Survey Y</el-radio>
          </div></el-col>

          <el-col :span="6"><div class="grid-content ">
            <el-radio v-model="radio1" label="12" border>Survey Q</el-radio>
          </div></el-col>
          <el-col :span="6"><div class="grid-content ">
            <el-radio v-model="radio1" label="13" border>Survey W</el-radio>
          </div></el-col>
          <el-col :span="6"><div class="grid-content ">
            <el-radio v-model="radio1" label="14" border>Survey R</el-radio>
          </div></el-col>
          <el-col :span="6"><div class="grid-content ">
            <el-radio v-model="radio1" label="15" border>Survey V</el-radio>
          </div></el-col>

          <el-col :span="6"><div class="grid-content ">
            <el-radio v-model="radio1" label="16" border>Survey A</el-radio>
          </div></el-col>
          <el-col :span="6"><div class="grid-content ">
            <el-radio v-model="radio1" label="17" border>Survey 1</el-radio>
          </div></el-col>
          <el-col :span="6"><div class="grid-content ">
            <el-radio v-model="radio1" label="18" border>Survey 2</el-radio>
          </div></el-col>
          <el-col :span="6"><div class="grid-content ">
            <el-radio v-model="radio1" label="19" border>Survey 3</el-radio>
          </div></el-col>
        </el-row>
       </div>

        <el-button type="primary"
                            @click="select"
                               style="margin: 1em"
                              >Create</el-button>
    </div>
</template>

<script>

export default {
    name: "SurveyUser",
    
    async mounted() {
    
    },
    data() {
        return {
          radio1: '1',
        }
      
    },
    methods: {
        select() {
          console.log("hello");
          this.$router.push('/survey-user/detail');
       
        }
    }
}
</script>

<style scoped lang="scss">

::v-deep .el-radio__inner {
  display: none;
}

.survey {
    display: block;
    width: 70% !important;
    margin: 20px auto;
    background-color: white;
}
.store-name {
    display: flex;
    column-gap: 50px;
    font-weight: bold;
    font-size: 25px;
}
.container {
    padding: 20px;
}
.name {
    display: flex;
    column-gap: 50px;
}

.kanji {
    display: flex;
    column-gap: 66px;
}

.kanata {
    display: flex;
    column-gap: 38px;
}


.body {
    margin: 10px;
    padding-bottom: 10px;
    margin-right: 257px;
    margin-left: 257px;
}
.el-row {
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
}
.el-col {
  border-radius: 4px;
}

.bg-purple {
  background-color: #44BCFF;
  border-color: #44BCFF;
}

.grid-content {
  border-radius: 4px;
  margin-bottom: 15px;
}
.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}

</style>