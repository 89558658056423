<template>
    <div>
        <NavBar style="position: absolute; top: 0px; left: 0px; background-color: transparent; width: 100%"/>
        <RouterView/>
    </div>
</template>

<script>
import NavBar from "@/layout/component/NavBar";
export default {
    name: "NoNavBarLayout",
    components: {NavBar}
}
</script>

<style scoped lang="scss">
::v-deep .navbar-wrapper{
    position: fixed;
    width: 100%;
    background: transparent;
}


</style>
