var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-dialog',{staticStyle:{"margin-top":"8vh","text-align":"left"},attrs:{"title":_vm.title,"visible":_vm.value},on:{"close":function (){ _vm.$emit('input', false); _vm.$emit('close', true); _vm.setNull(_vm.input.model); this$1.setNull(this$1.input.error); }}},[_c('ElForm',{staticClass:"sent-mail-convert-katakana-form"},[_c('el-form-item',{staticClass:"required",attrs:{"error":_vm.input.error.firstName,"label":_vm.$t('TEXT0236')}},[_c('el-input',{attrs:{"maxlength":"128"},model:{value:(_vm.input.model.firstName),callback:function ($$v) {_vm.$set(_vm.input.model, "firstName", $$v)},expression:"input.model.firstName"}})],1),_c('el-form-item',{staticClass:"required",attrs:{"error":_vm.input.error.email,"label":_vm.$t('TEXT0019')}},[_c('el-input',{attrs:{"maxlength":"128"},model:{value:(_vm.input.model.email),callback:function ($$v) {_vm.$set(_vm.input.model, "email", $$v)},expression:"input.model.email"}})],1),_c('p',[_vm._v(_vm._s(_vm.$t('TEXT0237')))]),_c('div',{staticClass:"button-action"},[_c('ElButton',{staticClass:"button-cancel",attrs:{"type":"info"},on:{"click":function($event){
var this$1 = this;
$event.preventDefault();return (function (){
                      _vm.$emit('ok', true);
                      _vm.$emit('input', false);
                      _vm.$emit('close', true)
                      _vm.setNull(_vm.input.model)
                      this$1.setNull(this$1.input.error)
                    })($event)}}},[_vm._v(" "+_vm._s(_vm.$t('TEXT0238'))+" ")]),_c('ElButton',{staticClass:"button-sent",attrs:{"type":"primary","loading":_vm.loading.convert},on:{"click":function($event){$event.preventDefault();return _vm.sentMail()}}},[_vm._v(_vm._s(_vm.$t('TEXT0078'))+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }