export default {
    ROLE: {
        ADMIN: 'ADMIN',
        USER: 'USER'
    },
    ROLE_NAME: {
        ADMIN: 'ADMIN',
        USER: 'USER'
    },
    GENDER: {
        MALE: 'TEXT0006',
        FEMALE: 'TEXT0007',
        OTHER: 'TEXT0231'
    },
    ACTION_TYPE: {
        VIEW_SHOP: 'VIEW_SHOP',
        VIEW_CATEGORY: 'VIEW_CATEGORY'
    },
    VIEW_SETTING: {
        FULL: 'FULL',
        STORE_AND_SUBCATEGORY: 'STORE_AND_SUBCATEGORY',
        STORE_ONLY: 'STORE_ONLY',
        NONE: 'NONE',
    },
    ORDER_STATUS: {
        'TODO': {
            text: 'TEXT0316',
            value: 'TODO'
        },
        'WRITING': {
            text: 'TEXT0317',
            value: 'WRITING'
        },
        'RESOLVE': {
            text: 'TEXT0318',
            value: 'RESOLVE'
        },
        'DONE': {
            text: 'TEXT0319',
            value: 'DONE'
        }
    },
}
