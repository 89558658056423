import Vue from 'vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'
import App from './App.vue'
import router from './router'
import ja from '@/locales/ja'
import en from '@/locales/en'
import VueI18n from 'vue-i18n'
import VueHtmlToPaper from 'vue-html-to-paper';

const options = {
        name: '_blank',
        specs: [
            'fullscreen=no',
            'titlebar=no',
            'scrollbars=no'
        ],
        styles: [
            'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
            'https://unpkg.com/kidlat-css/css/kidlat.css'
        ]
    }
    // import Vue from 'vue';
import VueCarousel from 'vue-carousel';
import moment from 'moment'

Vue.use(VueCarousel);

Vue.use(VueHtmlToPaper, options);
Vue.use(VueI18n)
const messages = {
    'en-us': en,
    'ja-jp': ja
}

const i18n = new VueI18n({
    locale: 'en-us', // set locale,
    fallbackLocale: 'en-us',
    messages, // set locale messages
})

Vue.filter('datetimeFilter', function(value) {
    if (value) {
        if (i18n.locale == 'ja-jp') {
            return moment(value).format('yyyy年MM月DD日 HH時mm分')
        } else {
            return moment(value).format('yyyy-MM-DD HH:mm')
        }
    }
    return null
})



const myMixin = {
    data() {
        return {
            charPattern: /^[^\s]+(\s+[^\s]+)*$/,
            phonePattern: /^(?:\d{10,12}|\d{3}-\d{3}-\d{4}|\d{2}-\d{4}-\d{4}|\d{3}-\d{4}-\d{4})$/,
            // email with 64 (octets) in the "local part"
            emailPattern: /^(?=.{1,64}@)[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
            spacePattern: /^[^\s]+$/
        }
    },
    methods: {
        setNull(obj) {
            if (obj === null || obj === undefined || typeof obj !== 'object') {
                return
            } else if (Array.isArray(obj)) {
                for (let i = 0; i < obj.length; i++) {
                    if (typeof obj[i] !== 'object') {
                        obj[i] = null
                    } else {
                        this.setNull(obj[i])
                    }
                }
            } else {
                for (const item of Object.keys(obj)) {
                    if (typeof obj[item] !== 'object') {
                        obj[item] = null
                    } else {
                        this.setNull(obj[item])
                    }
                }
            }
        },
        setToValue: function(obj, path, value) {
            var schema = obj
            var pList = path.split(/[[].]+/)
            var len = pList.length
            for (var i = 0; i < len - 1; i++) {
                var elem = pList[i]
                if (!schema[elem]) schema[elem] = {}
                schema = schema[elem]
            }

            schema[pList[len - 1]] = value
        },
        getApiBase: function() {
            return location.protocol + '//' + process.env.VUE_APP_API_PREFIX + location.hostname + process.env.VUE_APP_API_PORT
        },
        trimObject(obj) {
            for (const key of Object.keys(obj)) {
                if (obj[key] != null && typeof obj[key] === 'string') {
                    obj[key] = obj[key].trim()
                } else if (obj[key] != null && typeof obj[key] === 'object') {
                    for (const key2 of Object.keys(obj[key])) {
                        if (obj[key][key2] != null && typeof obj[key][key2] === 'string') {
                            obj[key][key2] = obj[key][key2].trim()
                        } else if (obj[key][key2] != null && typeof obj[key][key2] === 'object') {
                            for (const key3 of Object.keys(obj[key][key2])) {
                                if (obj[key][key2][key3] != null && typeof obj[key][key2][key3] === 'string') {
                                    obj[key][key2][key3] = obj[key][key2][key3].trim()
                                } else if (obj[key][key2][key3] != null && typeof obj[key][key2][
                                        [key3]
                                    ] === 'object') {
                                    // by pass
                                }
                            }
                            // by pass
                        }
                    }
                }
            }
            return obj
        },
    }
}

Vue.config.productionTip = false
Vue.use(ElementUI, {
    i18n: (key, value) => i18n.t(key, value)
});
Vue.mixin(myMixin)
new Vue({
    router,
    i18n,
    render: function(h) {
        return h(App)
    }
}).$mount('#app')