import axios from './../config/axios'

export default {
    login: function (data) {
        return axios({
            url: "/api/guest/guest/login",
            method: 'post',
            data: data
        });
    },
    resetId: function (data) {
        return axios({
            url: "/api/guest/guest/reset-id",
            method: 'post',
            data: data
        });
    },
    detail: function (data) {
        return axios({
            url: "/api/guest/guest/detail",
            method: 'post',
            data: data
        });
    },
    getProfile: function (data) {
        return axios({
            url: "/api/guest/guest/profile",
            method: 'post',
            data: data
        });
    },
    updateProfile: function (data) {
        return axios({
            url: "/api/guest/guest/profile/update",
            method: 'post',
            data: data
        });
    },
    // getConvertResult>function ()
}
