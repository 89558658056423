<template>
    <div style="width: 100%">
        <ElForm class="convert-katakana-form">
            <h3 class="title33">{{ $t('TEXT0233') }}</h3>
            <el-form-item :error="input.error.firstName" :label="$t('TEXT0234')" class="required">
                <el-input
                        v-model="input.model.firstName"
                        maxlength="32"
                >
                </el-input>
              <div v-if="!input.error.firstName" class="el-form-item__error info-romaji">
                {{ $t('TEXT0242') }}
              </div>
            </el-form-item>
            <el-form-item :error="input.error.countryId" :label="$t('TEXT0001')" class="required">
                <el-select :placeholder="$t('TEXT0001')" v-model="input.model.countryId" style="width: 100%">
                    <el-option v-for="(item, index) in countries" :key="index" :label="item.name" :value="item.id"/>
                </el-select>
            </el-form-item>
            <ElButton type="primary" class="button-convert"
                      :loading="loading.convert"
                      @click.prevent="convert">{{ $t('TEXT0011') }}
            </ElButton>
            <el-form-item v-if="result" class="last-item" :label="$t('TEXT0235')">
                <el-input class="result-katakana" v-model="result" readonly></el-input>
              <div class="to-top">
                <img class="convert-image" src="@/assets/character.png"  alt="asd"/>
                <div>
                  <div class="text">{{ $t('TEXT0240') }}</div>
                  <el-button type="primary" class="button-to-top" @click="()=>{ $router.push('/instruction')}">
                    {{ $t('TEXT0241') }}
                  </el-button>
                </div>
              </div>

            </el-form-item>
        </ElForm>

      <SentMailConvertKatakanaDialog :title="$t('TEXT0233')" v-model="popup.convertFail.show"
                                     :convert-katakana-name="input.model.firstName"
                                     :country-id="input.model.countryId"
                                     @sentComplete="showSucess()"/>
      <SuccessDialog :title="$t('TEXT0071')" :message="$t('TEXT0239')" v-model="popup.sentSuccess.show" />
    </div>
</template>

<script>
import ConvertKatakanaApi from "@/api/ConvertKatakanaApi";
import CountryApi from "@/api/CountryApi";
import EventBus from "@/config/EventBus";
import SuccessDialog from "@/components/SuccessDialog";
import SentMailConvertKatakanaDialog from "@/components/SentMailConvertKatakanaDialog";

export default {
    name: "ConvertKatakana",
    components: { SuccessDialog, SentMailConvertKatakanaDialog },
    mounted() {
        this.getCountries()
        EventBus.$on('localeChange', () => {
            this.getCountries()
            this.setNull(this.input.error)
        })
    },
    data() {
        return {
            input: {
                model: {
                    firstName: null,
                    countryId: null
                },
                error: {
                    firstName: null,
                    countryId: null
                },
                variable: {
                    passwordType: 'password'
                }
            },
            loading: {
                convert: false
            },
            popup: {
                convertFail: {
                  show: false
                },
                sentSuccess: {
                  show: false
                }
            },
            result: null,
            countries: []
        }
    },
    methods: {
        convert: function () {
            this.loading.convert = true
            this.setNull(this.input.error)
            this.result = null
            ConvertKatakanaApi.romajiKatakanaConvert(this.trimObject(this.input.model)).then(resp => resp.data).then(resp => {
                console.log('resp', resp)
                if (resp) {
                  this.result = resp.katakana;
                } else {
                  this.popup.convertFail.show = true;
                }
            }).catch(resp => {
                if (resp.status === 412) {
                    for (let item of resp.data) {
                        console.log(item.key)
                        console.log(item.value)
                        this.input.error[item.key] = item.value
                    }
                    this.input.error.__ob__.dep.notify()
                }
            }).finally(() => {
                this.loading.convert = false
            })

        },
        getCountries: function() {
            CountryApi.all().then(resp => {
                this.countries = resp.data
            })
        },
        showSucess: function () {
            console.log('emit')
            this.popup.sentSuccess.show = true
        }
    }
}
</script>

<style scoped lang="scss">
@import "./src/style/variables.scss";
//

//
::v-deep .fa-imput-icon .el-input__prefix {
    font-size: 20px;
    left: 12px;
}

//
::v-deep .el-input--prefix .el-input__inner {
    padding-left: 40px !important;
}

.convert-katakana-form {
  max-width: 544px;
  padding: 20px 32px 42px 32px;
  background-color: white;
  margin: auto;
  margin-top: 5%;
  margin-bottom: 5%;
  h3 {
    text-align: center;
    margin: 2rem auto;
  }
  .button-convert {
    width: 100%;
    margin: 0.5rem 0 1rem 0;
  }
  .last-item {
    margin-bottom: 0;
  }
  ::v-deep .el-input.result-katakana .el-input__inner {
    background-color: #F5F7FA;
    border-color: #E4E7ED;
    font-weight: bold;
    margin-bottom: 1rem;
  }
  ::v-deep .to-top {
    display: inline-flex;
    margin-top: 40px;
    div.text {
      font-size: 14px;
      color: #828282;
      text-align: left;
      margin-bottom: 2rem;
    }
    .convert-image {
      width: 60%;
      height: 100%;
    }
    .button-to-top {
      max-height: 40px;
      margin: 0 auto;
      display: block;

    }
  }
  .info-romaji {
    color: #828282 !important;
  }
}

@media screen and (max-width: 992px) { // mobile
    ::v-deep .el-input--prefix .el-input__inner {
        padding-left: 45px !important;
    }
    .convert-katakana-form {
      h3 {
        text-align: left;
      }
      ::v-deep .to-top {
        display: block;
        margin-top: 30px;
        .convert-image {
          display: block;
          margin: 0 auto;
          width: 78%;
          height: 100%;
          text-align: center;
          margin-bottom: 3rem;
        }
        div.text {
          margin: 1rem auto 2rem auto;
          display: block;
          width: fit-content;
        }
      }
    }
}

</style>
