<template>
    <el-dialog :visible="value"
               class="policy-dialog"
               :title="$t('TEXT0056')"
               @close="$emit('input', false)">
        <div v-for="(item, index) in policies"
             style="margin-bottom: 20px; color: #4F4F4F; white-space: pre-line;"
             :class="item.class"
             :key="index + item.code">{{ $t(item.code) }}
            <template v-if="item.links">
                <div v-for="(item, index) in item.links" :key="index + item.code">
                    <a style="margin-bottom: 20px;"
                    :href="item.link"
                    target="_blank"
                    :class="item.class"
                    >{{ item.code ? $t(item.code) : item.link }}
                    </a>
                </div>
            </template>
        </div>
        

    </el-dialog>
</template>

<script>
export default {
    name: "SecurityPolicy",
    props: {
        value: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            policies: [{
                code: 'TEXT0129',
                class: 'h1'
            }, {
                code: 'TEXT0130',
                class: 'text'
            }, {
                code: 'TEXT0131',
                class: 'text'
            }, {
                code: 'TEXT0132',
                class: 'h2'
            }, {
                code: 'TEXT0133',
                class: 'text'
            }, {
                code: 'TEXT0134',
                class: 'h2'
            }, {
                code: 'TEXT0135',
                class: 'text'
            }, {
                code: 'TEXT0136',
                class: 'h2'
            }, {
                code: 'TEXT0137',
                class: 'text no-margin'
            }, {
                code: 'TEXT0138',
                class: 'text no-margin'
            }, {
                code: 'TEXT0139',
                class: 'text'
            }, {
                code: 'TEXT0140',
                class: 'h2'
            }, {
                code: 'TEXT0141',
                class: 'text'
            }, {
                code: 'TEXT0142',
                class: 'n1'
            }, {
                code: 'TEXT0143',
                class: 'text'
            }, {
                code: 'TEXT0144',
                class: 'n11'
            }, {
                code: 'TEXT0145',
                class: 'text'
            }, {
                code: 'TEXT0146',
                class: 'n11'
            }, {
                code: 'TEXT0147',
                class: 'text'
            }, {
                code: 'TEXT0148',
                class: 'n11'
            }, {
                code: 'TEXT0149',
                class: 'text'
            }, {
                code: 'TEXT0150',
                class: 'n11'
            }, {
                code: 'TEXT0151',
                class: 'text'
            }, {
                code: 'TEXT0152',
                class: 'n1'
            }, {
                code: 'TEXT0153',
                class: 'text'
            }, {
                code: 'TEXT0154',
                class: 'n11'
            }, {
                code: 'TEXT0155',
                class: 'text'
            }, {
                code: 'TEXT0156',
                class: 'n11'
            }, {
                code: 'TEXT0157',
                class: 'text'
            }, {
                code: 'TEXT0158',
                class: 'n11'
            }, {
                code: 'TEXT0159',
                class: 'text'
            }, {
                code: 'TEXT0160',
                class: 'n11'
            }, {
                code: 'TEXT0161',
                class: 'text'
            }, {
                code: 'TEXT0162',
                class: 'n11'
            }, {
                code: 'TEXT0163',
                class: 'text'
            }, {
                code: 'TEXT0164',
                class: 'n11'
            }, {
                code: 'TEXT0165',
                class: 'text'
            }, {
                code: 'TEXT0166',
                class: 'n1'
            }, {
                code: 'TEXT0167',
                class: 'text'
            }, {
                code: 'TEXT0168',
                class: 'n11'
            }, {
                code: 'TEXT0169',
                class: 'text'
            }, {
                code: 'TEXT0170',
                class: 'n11'
            }, {
                code: 'TEXT0171',
                class: 'text'
            }, {
                code: 'TEXT0172',
                class: 'n11'
            }, {
                code: 'TEXT0173',
                class: 'text'
            }, {
                code: 'TEXT0174',
                class: 'n1'
            }, {
                code: 'TEXT0175',
                class: 'text'
            }, {
                code: 'TEXT0176',
                class: 'text no-margin'
            }, {
                code: 'TEXT0177',
                class: 'text no-margin'
            }, {
                code: 'TEXT0178',
                class: 'text'
            }, {
                code: 'TEXT0179',
                class: 'text-bold'
            }, {
                code: 'TEXT0180',
                class: 'text'
            }, {
                code: 'TEXT0181',
                class: 'text-bold'
            }, {
                code: 'TEXT0182',
                class: 'text-bold'
            }, {
                code: 'TEXT0183',
                class: 'n1'
            }, {
                code: 'TEXT0184',
                class: 'text'
            }, {
                code: 'TEXT0185',
                class: 'text-bold'
            }, {
                code: 'TEXT0186',
                class: 'text'
            }, {
                code: 'TEXT0187',
                class: 'text-bold'
            }, {
                code: 'TEXT0188',
                class: 'text',
                links: [{
                    code: 'TEXT0189',
                    link: 'https://www.facebook.com/privacy/explanation',
                    class: 'text'
                }, {
                    code: 'TEXT0190',
                    link: 'https://twitter.com/ja/privacy',
                    class: 'text'
                }, {
                    code: 'TEXT0191',
                    link: 'https://terms.line.me/line_rules/',
                    class: 'text'
                }]
            }, {
                code: 'TEXT0336',
                class: 'n1'
            }, {
                code: 'TEXT0337',
                class: 'text',
                links: [{
                    link: 'https://policies.google.com/privacy',
                    class: 'text'
                }, {
                    link: 'https://policies.google.com/technologies/partner-sites',
                    class: 'text'
                }]
            }],
        }
    }
}
</script>

<style scoped lang="scss">
.h1 {
    font-size: 20px;
    font-weight: bold;
}

.h2 {
    font-size: 18px;
    font-weight: bold;
}

.n1 {
    font-size: 16px;
    font-weight: bold;
}

.n11 {
    font-size: 14px;
    font-weight: bold;
}

.text-bold {
    font-size: 14px;
    font-weight: bold;
}

.text{
    font-size: 14px;
    line-height: 25px;
}

.no-margin{
    margin-bottom: 0px !important;
}

.policy-dialog {
    overflow: hidden;
}

::v-deep .el-dialog__body{
    overflow: auto !important;
    max-height: 60vh !important;
}



@media screen and (max-width: 992px) { // mobile
    ::v-deep .el-dialog {
        width: calc(100% - 40px);
        margin: 0px 20px;
    }

    ::v-deep .el-dialog__body::-webkit-scrollbar{
        width: 7px;
    }

    ::v-deep .el-dialog__body::-webkit-scrollbar-thumb {
        background-color: #888888;    /* color of the scroll thumb */
        border-radius: 20px;       /* roundness of the scroll thumb */
        //border: 1px solid #555555;  /* creates padding around scroll thumb */
    }
}
</style>
