import axios from "@/config/axios";

export default {
    romajiKatakanaConvert: function(data) {
        return axios({
            url: "/api/convert-katakana/guest/convert",
            method: 'post',
            data: data
        });
    },
    
    sentmailKatakana: function(data) {
        return axios({
            url: "/api/convert-katakana/guest/question",
            method: 'post',
            data: data
        });
    },
}
