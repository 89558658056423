<template>
    <el-dialog :visible="value"
               class="policy-dialog"
               :title="$t('TEXT0268')"
               @close="$emit('input', false)">
               <div>
                <table 
                bgcolor="#999999" 
                border="0" 
                cellpadding="9" 
                cellspacing="1" 
                style="font-size:13px;
                text-align:left"> 
                <tr>
                    <th bgcolor="#EEEEEE">{{ $t('TEXT0269') }}</th>
                    <td bgcolor="#FFFFFF">{{ $t('TEXT0270') }}</td>
                </tr> 
                <tr>
                    <th bgcolor="#EEEEEE">{{ $t('TEXT0271') }}</th>
                    <td bgcolor="#FFFFFF">{{ $t('TEXT0272') }}</td>
                </tr> 
                <tr>
                    <th bgcolor="#EEEEEE">{{ $t('TEXT0273') }}</th>
                    <td bgcolor="#FFFFFF">{{ $t('TEXT0274') }}</td>
                </tr> 
                <tr>
                    <th bgcolor="#EEEEEE">{{ $t('TEXT0275') }}</th>
                    <td bgcolor="#FFFFFF">{{ $t('TEXT0276') }}</td>
                </tr> 
                <tr>
                    <th bgcolor="#EEEEEE">{{ $t('TEXT0277') }}</th>
                    <td bgcolor="#FFFFFF">{{ $t('TEXT0278') }}</td>
                </tr> 
                <tr><th bgcolor="#EEEEEE">{{ $t('TEXT0279') }}</th>
                    <td bgcolor="#FFFFFF">{{ $t('TEXT0280') }}</td>
                </tr> 
                <tr>
                    <th bgcolor="#EEEEEE">{{ $t('TEXT0281') }}</th>
                    <td bgcolor="#FFFFFF">{{ $t('TEXT0282') }}</td>
                </tr> 
                <tr>
                    <th bgcolor="#EEEEEE">{{ $t('TEXT0283') }}</th>
                    <td bgcolor="#FFFFFF">{{ $t('TEXT0284') }}</td>
                </tr> 
                <tr>
                    <th bgcolor="#EEEEEE">{{ $t('TEXT0285') }}</th>
                    <td bgcolor="#FFFFFF">{{ $t('TEXT0286' , { date: date }) }}</td>
                </tr> 
                <tr>
                    <th bgcolor="#EEEEEE">{{ $t('TEXT0287') }}</th>
                        <td bgcolor="#FFFFFF">{{ $t('TEXT0288') }}</td>
                </tr> 
                <tr>
                    <th bgcolor="#EEEEEE">{{ $t('TEXT0289') }}</th>
                    <td bgcolor="#FFFFFF">{{ $t('TEXT0290', { cost: cost}) }}</td>
                </tr>
                </table>
               </div>

    </el-dialog>
</template>

<script>
export default {
    name: "PaymentPolicy",
    props: {
        value: {
            type: Boolean,
            required: true
        },
        cost: {
            type: Number,
            required: true
        }, 
        date: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            
        }
    }
}
</script>

<style scoped lang="scss">
.h1 {
    font-size: 20px;
    font-weight: bold;
}

.h2 {
    font-size: 18px;
    font-weight: bold;
}

.n1 {
    font-size: 16px;
    font-weight: bold;
}

.n11 {
    font-size: 14px;
    font-weight: bold;
}

.text-bold {
    font-size: 14px;
    font-weight: bold;
}

.text{
    font-size: 14px;
    line-height: 25px;
}

.no-margin{
    margin-bottom: 0px !important;
}

.policy-dialog {
    overflow: hidden;
}

::v-deep .el-dialog__body{
    overflow: auto !important;
    max-height: 60vh !important;
}


@media screen and (max-width: 992px) { // mobile
    ::v-deep .el-dialog {
        width: calc(100% - 40px);
        margin: 0px 20px;
        margin-top: 10vh !important;
    }
    ::v-deep .el-dialog__title {
        width: calc(100% - 40px);
        display: inline-block;
    }
    ::v-deep .el-dialog__body::-webkit-scrollbar{
        width: 7px;
    }

    ::v-deep .el-dialog__body::-webkit-scrollbar-thumb {
        background-color: #888888;    /* color of the scroll thumb */
        border-radius: 20px;       /* roundness of the scroll thumb */
        //border: 1px solid #555555;  /* creates padding around scroll thumb */
    }
}
</style>
