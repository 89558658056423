<template>
    <div>
        <h4 class="title-exp">{{ $t('TEXT0329') }}</h4>
        <Carousel navigationEnabled
                  loop
                  :navigationNextLabel="nextLabel"
                  :navigationPrevLabel="prevLabel"
                  :perPage="perPage">
            <Slide v-for="(item, index) in brushs" :key="index">
                    <div class="brush-wrap">
                        <div class="brush-item">
                            <img class="brush-img" :src="require(`@/assets/${item.name}.png`)"  />
                        <div class="">
                                <img :style="[{position: 'absolute', height: 18+'%' , width: 'auto'}, item.style]" :src="require(`@/assets/${item.child}.png`)">
                            </div>
                        </div>
                    </div>
            </Slide>
        </Carousel>
    </div>
</template>

<script>
import {Carousel, Slide} from 'vue-carousel';

export default {
    name: "Brush",
    components: {
        Carousel,
        Slide
    },
    computed: {
        window: function () {
            return window;
        }
    },
    mounted() {
        
        if (window.innerWidth >= 1700) {
            this.perPage = 5
        } else if (window.innerWidth >= 1200) {
            this.perPage = 4
        } else if (window.innerWidth >= 992) {
            this.perPage = 3
        } else if (window.innerWidth >= 500) {
            this.perPage = 2
        } else {
            this.perPage = 1
        }
    },
    watch: {
        'brushs': function (val) {
            if (val.length <= 0) {
                this.$emit('setHide', this.shops.length <= 0)
            }
        }
    },
    data: function () {
        return {
            brushs: [
                {
                    name: 'brush-1',
                    path: '../assets/brush-1.png',
                    style: {left:'-20px', bottom:'40%'},
                    child: 'Ofa',
                },
                {
                    name: 'brush-2',
                    path: '../assets/brush-2.png',
                    style: {left:'-20px', bottom:'50%'},
                    child:'Nicole'
                },
                {
                    name: 'brush-3',
                    path: '../assets/brush-1.png',
                    style: {left:'-20px', bottom:'30%'},
                    child:'Kieran'
                },
                {
                    name: 'brush-4',
                    path: '../assets/brush-1.png',
                    style: {left:'-20px', bottom:'5%'},
                    child:'Jazmine'
                },
                {
                    name: 'brush-5',
                    path: '../assets/brush-1.png',
                    style: {left:'-20px', bottom:'48%'},
                    child:'Erick'
                }
            ],
            popup: {
                shopOpenWarning: {
                    show: false,
                    item: null
                }
            },
            perPage: 5,
            nextLabel: '<span class="fa fa-angle-right fa-2x" style="color: #BDBDBD; border: 1px solid #BDBDBD; border-radius: 50%; width: 26px; height: 26px; font-weight: 100; background-color: white"></span>',
            prevLabel: '<span class="fa fa-angle-left fa-2x" style="color: #BDBDBD; border: 1px solid #BDBDBD; border-radius: 50%; width: 25px; height: 25px; font-weight: 100; background-color: white"></span>'
        }
    },
    methods: {
        getPic(index) {
            return '../assets/' + this.brushs[index].name + '.png';
        }
    }
}
</script>

<style scoped lang="scss">

.divider {
    height: 1px;
    background-color: #dcdfe6;
    margin: 10px;
}

.card-wrapper {
    all: unset;
    color: unset;
    cursor: pointer;
}

::v-deep .VueCarousel-dot-container {
    display: none;
}

::v-deep .VueCarousel-pagination {
    display: none;
}

::v-deep .VueCarousel-navigation-next {
    right: 32px !important;
}

::v-deep .VueCarousel-navigation-prev {
    left: 32px !important;
}
.brush-wrap{
    height: 280px;
    display: flex;
    justify-content: center;
}
.brush-item{
    position: relative;
}
.paragraph{
    margin-top: 30px;
}
.brush-img{
    height: 100%;
    width: auto;
}
.title-exp{
    color: #dcdfe6;;
    font-size: 18px;
    font-weight: 600;
    margin: 5px 0 25px;
    padding-left: 20px;
    

}
@media screen and (max-width: 500px)  {
    .title-exp{
        color: #dcdfe6;
        font-size: 16px!important;
        font-weight: 600;
        margin: 25px 0 25px;
    }
}
</style>
