import axios from "@/config/axios";

export function getDetailOrder(data){
    return axios({
        url: "/api/order-art/detail/user",
        method: 'post',
        data
    });
}

export function downloadOrderImage(id){
        return axios({
            url: `/api/order-art/download/${id}`,
            method: 'get',
            responseType: 'blob',
            headers: {
                accept: 'image/png'
            }
        })
    
}
