import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Top from '@/views/kanji/Top'
import SurveyUser from '../views/surveyUser/SurveyUser'
import axios from '../config/axios'
import EventBus from '../config/EventBus'
import Survey from '../views/Survey'
import QRcode from '../views/QRcode'
import SurveyDetail from '../views/surveyUser/detailSurvey'
import PrivacyPolicy from '../views/legal/PrivacyPolicy'
import CertificateManualInsert from '../views/certificate/manual/CertificateManualInsert'
import ConvertKatakana from '../views/convertKatakana/ConvertKatakana'
import WelcomeKatakana from '@/views/WelcomeKatakana'
import ErrorPage from "../views/payment/ErrorPage"
import Success from "../views/payment/Success"
const Login = () =>
    import('@/views/Login')
const RegisterConfirm = () =>
    import('@/views/account/RegisterConfirm')
const KanjiConvert = () =>
    import('@/views/kanji/KanjiConvertResult')

const KanjiConvertResult = () =>
    import('@/views/kanji/KanjiConvertResult')
const KanjiConvertCertificate = () =>
    import('@/views/kanji/KanjiConvertCertificate')
const IdReset = () =>
    import('@/views/IdReset')
const Profile = () =>
    import('@/views/account/Profile')
const Print = () =>
    import('@/views/Print')
const Catalog = () =>
    import('@/views/Catalog')
const OTPCode = () =>
    import('@/views/OTPCode')

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'Home',
    component: Home
},
{
    path: '/top',
    name: 'Top',
    component: Top,
    meta: {
        layout: 'NoNavBarLayout'
    }
},
{
    path: '/survey-user',
    name: 'Survey User',
    component: SurveyUser,
    meta: {
        layout: 'NoNavBarLayout2'
    }
},
{
    path: '/survey-user/detail/:id',
    name: 'Survey User Detail',
    component: SurveyDetail,

},
{
    path: '/kanji/convert/certificate/print',
    name: 'CertificatePrint',
    component: Print,
    meta: {
        layout: 'PrintLayout',
        requireAuth: true
    }
},
{
    path: '/login',
    name: 'Login',
    component: Login
},
{
    path: '/id/reset',
    name: 'IdReset',
    component: IdReset
},
{
    path: '/register/confirm',
    name: 'RegisterConfirm',
    component: RegisterConfirm
},
{
    path: '/profile',
    name: 'Profile',
    meta: {
        requireAuth: true
    },
    component: Profile
},
{
    path: '/kanji/convert',
    name: 'KanjiConvert',
    component: KanjiConvert
},
{
    path: '/survey',
    name: 'Survey',
    component: Survey,
    meta: {
        layout: 'NoNavBarLayout2'
    }
},
{
    path: '/qrcode',
    name: 'QRcode',
    component: QRcode,
    meta: {
        layout: 'NoNavBarLayout2',
        requireAuth: true
    }
},
{
    path: '/kanji/convert/result',
    name: 'KanjiConvertResult',
    component: KanjiConvertResult
},
{
    path: '/kanji/convert/certificate',
    name: 'KanjiConvertCertificate',
    meta: {
        requireAuth: true
    },
    component: KanjiConvertCertificate
},
{
    path: '/catalog',
    name: 'Catalog',
    component: Catalog
},
{
    path: '/legal/privacy-policy',
    name: 'PrivayPolicy',
    component: PrivacyPolicy
},
{
    path: '/certificate/manual/insert',
    name: 'CertificateManualInsert',
    component: CertificateManualInsert
},
{
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
        return import(/* webpackChunkName: "about" */ '../views/About.vue')
    }
},
{
    path: '/convert-katakana',
    name: 'ConvertKatakana',
    component: ConvertKatakana
},
{
    path: '/welcome-convert-katakana',
    name: 'WelcomeConvertKatakana',
    component: WelcomeKatakana
},
{
    path: '/instruction',
    // eslint-disable-next-line no-unused-vars
    beforeEnter(to, from, next) {
        window.location.href = 'https://azanafuda.tokyo'
    }
},
{
    path: '/success',
    name: 'Success',
    component: Success,
},
{
    path: '/failed',
    name: 'Error',
    component: ErrorPage,
},
{
    path: '/otp-checking',
    name: 'OTPCode',
    component: OTPCode,
    meta: {
        layout: 'NoNavBarLayout2',
    }
},
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach(async (to, from, next) => {
    console.log('to: ')
    console.log(to)
    const token = localStorage.getItem('token') || null
    if (token != null) {
        console.log('start check token')
        try {
            const response = await axios.get('/api/guest/token/check')
            if (!response || !response.data) { // token invalid
                localStorage.removeItem('token')
                EventBus.$emit('updateNavBar')
                next('/')
            }
            console.log('response: ' + response)
        } catch (e) {
            localStorage.removeItem('token')
            EventBus.$emit('updateNavBar')
            next('/')
        }
    }

    console.log('token: ' + (token != null))
    console.log('token: ' + (token == null))
    console.log(token)
    if (token == null) { // not login yet
        if (to.meta.requireAuth) {
            console.log("case 1")
            next('/')
        } else {
            next()
        }
    } else {
        if (to.path === '/') {
            console.log('case 2')
            next('/kanji/convert/certificate')
        } else if (to.path == '/top' || to.path == '/login' || to.path == '/convert-katakana') {
            console.log('case 3')
            localStorage.removeItem('token')
            EventBus.$emit('updateNavBar')
            next()
        } else if (to.path == '/kanji/convert/result') {
            console.log('case 4')
            localStorage.removeItem('token')
            EventBus.$emit('updateNavBar')
            if (from != null && from.path == '/kanji/convert/certificate') {
                next('/top')
            } else {
                next()
            }
        } else {
            console.log('case 5')
            next()
        }
    }
})

export default router
