<template>
    <el-dialog :title="title"
               style="margin-top: 8vh; text-align: center"
               @close="()=>{ $emit('close', true) }"
               :visible="value">
        {{ message }}
        <slot name="content"></slot>
        <br/>
        <div style="text-align: center; margin-top: 20px">
            <el-button @click.prevent="()=>{$emit('yes', true); $emit('close', true)}"
                       :type="yesBtnType">
                {{ yesLabel }}
            </el-button>
            <el-button @click.prevent="()=>{$emit('no', true); $emit('close', true)}"
                       :type="noBtnType">
                {{ noLabel }}
            </el-button>
        </div>
    </el-dialog>
</template>

<script>
export default {
    name: "ConfirmingDialog",
    props: {
        value: {
            type: Boolean,
            default: false
        },
        title: {
            type: undefined,
            default: undefined
        },
        message: {
            type: undefined,
            default: undefined
        },
        yesBtnType: {
            type: undefined,
            default: "primary"
        },
        yesLabel: {
            type: String,
            default: "yes"
        },
        noBtnType: {
            type: undefined,
            default: "primary"
        },
        noLabel: {
            type: String,
            default: "no"
        }
    },
}
</script>

<style scoped lang="scss">
::v-deep .el-dialog {
    width: 400px;
}
@media screen and (max-width: 992px) { // mobile
    ::v-deep .el-dialog {
        width: calc(100% - 20px);
    }
}
</style>
