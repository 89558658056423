<template>
    <div class="page" style="position: relative">
        <img src="@/assets/certificate/certificate-bg3.png"
             style="width: 210mm; height: 296.3mm; margin: auto; display: block">
        <div class="convert-text"
             style="position: absolute; left: 0px; right: 0px;  text-align: center; width: 100%; color: #4f4f4f">
            <div style="font-size: 32px; font-family: uidigi; font-weight: bold">CERTIFICATE</div>
            <div style=" text-align: center; width: 100%; font-size: 20px; margin-top: 3mm; font-family: uidigi; font-weight: 400; color: #444444">
                認定証
            </div>
            <div class="name" style="; font-family: uidigi; font-weight: bold"> {{
                    "Dear " + profile.fullName
                }}
            </div>
            <div style="margin-top: 3mm; font-size: 14px; font-family: uidigi; font-weight: bold">
                {{ 'IDNo: ' + profile.id }}
            </div>
            <div class="result">
                <div style="font-size: 13px; font-weight: bold; color: rgb(100, 100, 100); font-family: uidigi">
                    あなたの漢字名を命名させていただきます。
                </div>
                <table style="margin-left: auto; margin-right: auto; margin-top: 20px; width: 180px"
                       cellspacing="0">
                    <tr v-for="(item, index) in kanjis" :key="index">
                        <td style="width: 74px; color: #444444; font-weight: bold; padding: 10px 0px; font-family: uidigi;"
                            :style="{'font-size': '22px'}">
                            {{ item.kanji }}
                        </td>
                        <td style="border-right: 1px solid #4f4f4f; text-transform: lowercase; font-size: 14px; font-family: uidigi; font-weight: 600; color: rgb(100, 100, 100)">
                            {{ item.meaning }}
                        </td>
                    </tr>
                </table>

                <table style="margin-left: auto; margin-right: auto; margin-top: 10px; margin-bottom: 20px; width: 180px"
                       cellspacing="0">
                    <tr>
                        <td style="width: 70px; font-size:16px; font-family: uidigi; font-weight: 600; color: rgb(100, 100, 100)">
                            総画数
                        </td>
                        <td style="border-right: 1px solid #4f4f4f; font-size: 14px; font-family: uidigi; font-weight: 600; color: rgb(100, 100, 100)">
                            {{
                                kanjis.reduce((a, b) => a + b.strokeCount, 0)
                            }}
                        </td>
                    </tr>
                </table>
                <div style="border: 1px solid #4f4f4f; margin-top: 20px; padding: 5px; width:  105mm; margin: auto; text-align: left; ; font-family: uidigi">
                    <div style="font-size : 14px; line-height: 24px">
                        {{ '総画' + kanjis.reduce((a, b) => a + b.strokeCount, 0) + '画の運勢' }}
                    </div>
                    <div style="font-size: 11px; line-height: 18px;  font-family: uidigi">
                        {{ 'あなたが希望する' + meaning.meaning + '運上昇の総画です。' }}
                    </div>
                    <div style="font-size: 11px; line-height: 16px;  font-family: uidigi"> {{ stroke.description }}
                    </div>
                </div>
                <div style="font-size: 14px; margin-top: 20px; width: 100%; font-family: uidigi"> {{ date }}

                </div>
            </div>
        </div>
        <div style="font-size: 22px; font-weight: bold; color: #444444; text-align: center; left: 0px; right: 0px; position: absolute; bottom: 50mm; font-family: uidigi">
            株式会社 泉壽
        </div>
        <div style="font-size: 18px; color: rgb(79, 79, 79); font-weight: 300; text-align: center; left: 0px; right: 0px; position: absolute; bottom: 40mm; font-family: uidigi">
            Senjyu co.,ltd

        </div>

    </div>
</template>

<script>
import KanjiApi from "@/api/KanjiApi";
import GuestApi from "@/api/GuestApi";
import axios from "axios";
// import EventBus from "@/config/EventBus";

export default {
    name: "Certificate",
    computed: {
        date: function () {
            var today = new Date();
            var dd = String(today.getDate()).padStart(2, '0');
            var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            var yyyy = today.getFullYear();

            // today = mm + '/' + dd + '/' + yyyy;
            today = yyyy + '年' + mm + '月' + dd + '日';
            // document.write(today);
            return today
        },
        certificate() {
            console.log("kanji: ")
            console.log(this.kanjis)
            return {
                kanjiFontWeight: this.kanjis.length < 3 ? '24px' : (this.kanjis.length < 6 ? '18px' : '10px'),
                descriptionTitleFontWeight: this.kanjis.length < 3 ? '18px' : (this.kanjis.length < 6 ? '14px' : '8px'),
                descriptionFontWeight: this.kanjis.length < 3 ? '14px' : (this.kanjis.length < 6 ? '10px' : '8px'),
                padding: this.kanjis.length < 3 ? '2px' : '0px',
            }
        },
        gender() {
            return this.profile.gender;
        }
    },
    mounted() {

        this.getProfile(() => {
            this.getConvertResult(() => {
                let title = document.title;
                document.title = "certificate"
                // if (window.matchMedia) {
                //     this.gggg = "setup match query "
                //     var mediaQueryList = window.matchMedia('print');
                //     mediaQueryList.addListener(function (mql) {
                //         if (mql.matches) {
                //             // beforePrint();
                //         } else {
                //             setTimeout(() => {
                //                 this.$router.push("/kanji/convert/certificate");
                //             }, 4000)
                //         }
                //     });
                // } else {
                this.gggg = "set up on after print"
                window.onafterprint = () => {
                    setTimeout(() => {
                        document.title = title;
                        this.$router.push("/kanji/convert/certificate");
                        console.log("Printing completed...");
                    }, 1500)

                }
                // }
                setTimeout(() => {
                    // window.print()
                }, 1500)

                // window.onafterprint = () => {
                //     setTimeout(() => {
                //         document.title = title;
                //         this.$router.push("/kanji/convert/certificate");
                //     }, 6000)
                // }
                // // setTimeout(()=>{
                //     window.print()
                // // },1500)
            })
        })
        // this.downloadCertificate()
    },
    data() {
        return {
            kanjis: [],
            stroke: {},
            meaning: {},
            profile: {},
        }
    },
    methods: {
        getConvertResult: function (next) {
            KanjiApi.getConvertResult({
                token: localStorage.getItem('token')
            }).then(resp => {
                console.log(resp);
                this.kanjis = resp.data.kanjis
                this.stroke = resp.data.stroke
                this.meaning = resp.data.meaning
                next()
            })
        },
        getProfile: function (next) {
            GuestApi.getProfile().then(resp => {
                this.profile = resp.data
                next()
            })
        },
        downloadCertificate: function () {
            axios.post(location.protocol + '//' + process.env.VUE_APP_API_PREFIX + location.hostname + process.env.VUE_APP_API_PORT + "/api/kanji/user/download-certificate", {}, {
                headers: {
                    Authorization: localStorage.getItem('token'),

                },
                responseType: 'blob',
            }).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'certificate.pdf'); //or any other extension
                document.body.appendChild(link);
                link.click();
            })
        }
    }
}
</script>

<style scoped lang="scss">
html, body {
    background-color: red
}


@page {
    size: A4;
    margin: 0;
}

//@supports (-webkit-touch-callout: none) {
//    @page {
//        size: A4;
//        margin: -100mm -100mm;
//    }
//}

//@supports not (-webkit-touch-callout: none) {
//    @page {
//        size: A4;
//        margin: 0;
//    }
//}

@media print {
    html, body {
        width: 210mm;
        height: 297mm;
        background-color: red;
    }
    .page {
        margin: 0;
        border: initial;
        border-radius: initial;
        width: initial;
        min-height: initial;
        box-shadow: initial;
        background: initial;
        page-break-after: always;
    }

    //@supports (-webkit-touch-callout: none) {
        .page img {
            height: 206mm;
        }
    //}
}

.content {
    text-align: center;
    background: #44BCFF 95%;
    padding: 20px 0px;
    color: white;

}

.content a:visited {
    color: white;
}

.page {
    //height: 885px;
    margin: auto;
}

.page .convert-text {
    top: 55mm;
}

.page > .convert-text > .name {
    margin-top: 27mm;
    font-size: 24px;
}

.page > .convert-text > .result {
    margin-top: 18mm;
}

td {
    border-top: 1px solid #4f4f4f;
    border-left: 1px solid #4f4f4f;
    //margin: 0px;
    padding: 2px;
}

tr:last-child td {
    border-bottom: 1px solid #4f4f4f;
}


</style>
