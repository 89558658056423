<template>
    <div class="no-nav-view-content">
        <div class="bg-mobile">
            <div class="intro-wrap">
                    <p style="margin: 0;">{{ $t("TEXT0305") }}</p>
                    <h1 class="top-title">{{ $t("TEXT0306") }}</h1>
                    <p class="intro">{{ $t("TEXT0307") }} {{ $t("TEXT0308") }}</p>
                    <p class="hight-light">{{ $t("TEXT0330") }}</p>
                </div>
        </div>
        <div class="kanji-convert-input">
            <div class="left-layout">
                <div class="intro-wrap">
                    <p class="title">{{ $t("TEXT0305") }}</p>
                    <h1 class="top-title">{{ $t("TEXT0306") }}</h1>
                    <p class="intro">{{ $t("TEXT0307") }} {{ $t("TEXT0308") }}</p>
                    <p class="hight-light">{{ $t("TEXT0330") }}</p>
                </div>
            </div>
            <div class="right-layout">
            <el-form ref="form" class="kanji-convert-form">
                <el-form-item>
                    <h3 class="title-register">{{ $t("TEXT0070") }}</h3>
                </el-form-item>
                <el-form-item :error="input.error.fullName">
                    <el-input
                        v-model="input.model.fullName"
                        class="no-capital-case-placeholder"
                        :placeholder="$t('TEXT0060')"
                    />
                </el-form-item>
                <el-form-item :error="input.error.firstName">
                    <el-input
                        v-model="input.model.firstName"
                        class="no-capital-case-placeholder"
                        :placeholder="$t('TEXT0061')"
                    >
                        <el-tooltip
                            class="item tooltip"
                            :tabindex="-1"
                            slot="suffix"
                            effect="dark"
                            :content="$t('TEXT0067')"
                            placement="top-end"
                        >
                            <i class="el-input__icon el-icon-question pointer"></i>
                        </el-tooltip>
                    </el-input>
                </el-form-item>
                <el-form-item :error="input.error.email">
                    <el-input v-model="input.model.email" :placeholder="$t('TEXT0019')" />
                </el-form-item>

                <el-form-item :error="input.error.countryId">
                    <el-select
                        popper-class="sl-tall"
                        v-model="input.model.countryId"
                        style="width: 100%"
                        :placeholder="$t('TEXT0001')"
                    >
                        <el-option
                            v-for="(item, index) in countries"
                            :key="index"
                            :label="item.name"
                            :value="item.id"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item v-if="config.showGender" :error="input.error.gender">
                    <el-select
                        v-model="input.model.gender"
                        style="width: 100%"
                        :placeholder="$t('TEXT0059')"
                    >
                        <el-option
                            v-for="(val, key, index) in gender"
                            :key="index"
                            :value="key"
                            :label="$t(val)"
                        />
                    </el-select>
                </el-form-item>

                <el-form-item v-if="config.showBirthday" :error="input.error.birthdate">
                    <div
                        style="
                        display: flex;
                        justify-content: flex-end;
                        max-width: fit-content;
                        "
                    >
                        <el-row :gutter="16">
                            <el-col :span="8">
                                <el-select
                                    v-model="input.model.year"
                                    style="width: 100%"
                                    collapse-tags
                                    :placeholder="$t('TEXT0221')"
                                >
                                    <el-option
                                        v-for="item in years"
                                        :key="item.index"
                                        :label="item.value"
                                        :value="item.index"
                                    >
                                    </el-option>
                                </el-select>
                            </el-col>
                            <el-col :span="8">
                                <el-select
                                    v-model="input.model.month"
                                    collapse-tags
                                    style="width: 100%"
                                    :placeholder="$t('TEXT0222')"
                                >
                                    <el-option
                                        v-for="item in months"
                                        :key="item.index"
                                        :label="item.value"
                                        :value="item.index"
                                    >
                                    </el-option>
                                </el-select>
                            </el-col>
                            <el-col :span="8">
                                <el-select
                                    v-model="input.model.day"
                                    collapse-tags
                                    style="width: 100%"
                                    :placeholder="$t('TEXT0223')"
                                >
                                    <el-option
                                        v-for="item in days"
                                        :key="item.index"
                                        :label="item.value"
                                        :value="item.index"
                                    >
                                    </el-option>
                                </el-select>
                            </el-col>
                        </el-row>
                    </div>
                </el-form-item>

                <el-form-item :error="input.error.meaningId">
                    <el-select
                        popper-class="sl-tall"
                        v-model="input.model.meaningId"
                        style="width: 100%"
                        :placeholder="$t('TEXT0062')"
                    >
                        <el-option
                            v-for="(item, index) in meanings"
                            :key="index"
                            :label="item.meaning"
                            class="meaning-option"
                            :value="item.id"
                        >
                            <h3 class="meaning-title">{{ item.meaning }}</h3>
                            <div class="meaning-description">{{ item.description }}</div>
                        </el-option>
                    </el-select>
                    <el-tooltip
                        class="tooltip out-tooltip"
                        :tabindex="-1"
                        slot="default"
                        effect="dark"
                        :content="$t('TEXT0068')"
                        placement="top-end"
                    >
                        <i class="el-input__icon el-icon-question pointer"></i>
                    </el-tooltip>
                </el-form-item>

                <el-form-item class="text-left">
                    <el-checkbox class="" v-model="input.data.agreeTerm">
                        <a class="policy" href="#" @click.prevent="popup.securityPolicy.show = true">{{
                            $t("TEXT0056")
                        }}</a>
                        <span class="policy">{{ $t("TEXT0057") }}</span>
                    </el-checkbox>
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        :loading="input.data.loading"
                        :disabled="!input.data.agreeTerm"
                        style="width: 100%"
                        @click="openConfirmingPopup"
                    >{{ $t("TEXT0034") }}
                    </el-button>
                </el-form-item>
                <el-form-item class="text-left" style="margin-bottom: 0">
                    <div class="white-color">{{ $t("TEXT0069") }}</div>
                </el-form-item>
            </el-form>
        </div>
        </div>
        <SecurityPolicy v-model="popup.securityPolicy.show" />
        <ConfirmingDialog
            v-model="popup.inputConfirming.show"
            :title="$t('TEXT0339')"
            :message="$t('TEXT0340')"
            :yes-label="$t('TEXT0341')"
            :no-label="$t('TEXT0342')"
            :yes-btn-type="'info'"
            :no-btn-type="'primary'"
            @close="closeConfirmingPopup"
            @yes="closeConfirmingPopup"
            @no="convertRomajiKanji"
        />
        <SuccessDialog
            :title="$t('TEXT0338')"
            :message="$t('TEXT0072')"
            v-model="popup.convertSuccess.show"
            @close="goToKanjiConvertResult"
            @ok="goToKanjiConvertResult"
        />
    </div>
</template>

<script>
import MeaningApi from '@/api/MeaningApi'
import CountryApi from '@/api/CountryApi'
import constant from '@/config/constant'
import SecurityPolicy from '@/views/kanji/SecurityPolicy'
import KanjiApi from '@/api/KanjiApi'
import ConfirmingDialog from '@/components/ConfirmingDialog'
import SuccessDialog from '@/components/SuccessDialog'
import EventBus from '@/config/EventBus'
import ConfigApi from '@/api/ConfigApi'


export default {
    name: 'Top',
    components: { ConfirmingDialog, SuccessDialog, SecurityPolicy },
    computed: {
        gender: function() {
            return constant.GENDER
        },
        datePickerOption: function() {
            return {
                disabledDate(time) {
                    return time.getTime() > Date.now()
                }
            }
        },
        years() {
            const year = new Date().getFullYear()
            const arr = []
            for (let i = 0; i <= year - 1900; i++) {
                arr.push({
                    index: year - i,
                    value: year - i
                })
            }
            return arr
        },
        days() {
            const arr = []
            for (let i = 1; i <= 31; i++) {
                arr.push({
                    index: i,
                    value: i
                })
            }
            return arr
        },
        months() {
            const arr = []
            for (let i = 1; i <= 12; i++) {
                arr.push({
                    index: i,
                    value: i
                })
            }
            return arr
        }
    },
    created() {
        this.getConfig()
    },
    data() {
        return {
            input: {
                model: {
                    fullName: null,
                    firstName: null,
                    email: null,
                    countryId: null,
                    gender: null,
                    birthdate: null,
                    month: null,
                    year: null,
                    day: null,
                    meaningId: null
                },
                error: {
                    fullName: null,
                    firstName: null,
                    email: null,
                    countryId: null,
                    gender: null,
                    birthdate: null,
                    meaningId: ''
                },
                data: {
                    agreeTerm: false,
                    loading: false
                }
            },
            config: {},
            popup: {
                securityPolicy: {
                    show: false
                },
                inputConfirming: {
                  show: false
                },
                convertSuccess: {
                    show: false
                }
            },
            meanings: [],
            countries: [],
            editable: false,
            convertUrl: null
        }
    },
    mounted() {
        this.getMeanings()
        this.getCountries()
        EventBus.$on('localeChange', () => {
            if (this.$route.path === '/top') {
                this.getMeanings()
                this.getCountries()
                this.setNull(this.input.error)
            }
        })
    },
    methods: {
        getMeanings: function() {
            MeaningApi.all().then((resp) => {
                this.meanings = resp.data
            })
        },
        getCountries: function() {
            CountryApi.all().then((resp) => {
                this.countries = resp.data
            })
        },
        getConfig: function() {
            ConfigApi.getConfig().then((resp) => {
                this.config = resp.data
            })
        },
        openConfirmingPopup: function () {
            this.popup.inputConfirming.show = true;
        },
        closeConfirmingPopup: function () {
            this.popup.inputConfirming.show = false;
        },
        convertRomajiKanji: function() {
            this.popup.inputConfirming.show = false;
            if (
                this.input.model.day == null &&
                this.input.model.month == null &&
                this.input.model.year == null
            ) {
                this.input.model.birthday = null
            } else {
                const day =
                    this.input.model.day >= 10
                        ? this.input.model.day
                        : `0${this.input.model.day}`
                const month =
                    this.input.model.month >= 10
                        ? this.input.model.month
                        : `0${this.input.model.month}`
                this.input.model.birthdate = `${this.input.model.year}-${month}-${day}`
            }
            this.input.data.loading = true
            this.setNull(this.input.error)
            KanjiApi.romajiKanjiConvert(this.trimObject(this.input.model))
                .then((resp) => {
                    this.popup.convertSuccess.show = true
                    localStorage.setItem('guestKey', resp.data.key)
                    this.convertUrl = resp.data.url;
                })
                .catch((resp) => {
                    if (resp.status === 412) {
                        for (let i = 0; i < resp.data.length; i++) {
                            this.setToValue(
                                this.input.error,
                                resp.data[i].key,
                                resp.data[i].value
                            )
                        }
                    }
                })
                .finally(() => {
                    this.input.data.loading = false
                })
        },
        goToKanjiConvertResult: function() {
          window.location.href = this.convertUrl;
      }
    }
}
</script>

<style scoped lang="scss">
.meaning-option {
  height: auto !important;
}

.meaning-option h3 {
  margin: 0px;
}




.kanji-convert-input .el-form {
  background-color: white;
}

.text-left {
  text-align: left;
}

.meaning-option h3 {
  line-height: initial;
}

::v-deep .birthday .el-input__prefix {
  display: none;
}

::v-deep .birthday .el-input__inner {
  padding-left: 15px !important;
}

.meaning-description {
  white-space: initial;
  max-width: calc(24vw - 40px);
  margin-bottom: 12px;
  line-height: initial;
  font-weight: normal !important;
}

@media screen and (min-width: 1024px) {
  // pc
  .kanji-convert-input {
    // text-align: center;
    display: flex;
    align-items: center;
    // height: 100vh;
    }

  .no-nav-view-content {
    background-image: url("~@/assets/top/bg-main.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
  }

  .kanji-convert-input .el-form {

    display: inline-block;
    padding: 20px 30px;
    width: 70%;
  }

  .policy{
    color: white;
  }
  .left-layout{
    flex:1;
    }

    .right-layout{
        flex:1;
        text-align: center;
        margin-top: 15vh;
    }

  .intro-wrap{
    display: flex;
    margin: 20px 40px 20px 120px;
    justify-content: flex-start;
    flex-direction: column;
    padding: 40px;
    height: 100%;
  }

  .title{
    font-size: 28px;
    font-weight: 500;
    color: #fff;
    margin: 28px 0;
  }

  .hight-light{
    color: #e2b321;
    font-weight: 600;
    font-size: 21px;
    margin: 18px 0;
    line-height: 35px;
}

  .top-title{
    font-size: 62px;
    margin: 24px 0;
    color: #fff;
  }
  .intro{
    font-size: 18px;
    font-weight: 300;
    color: #fff;
    margin: 18px 0;
    line-height: 35px;
    
  }

  .kanji-convert-input .el-form {
    background-color: transparent;
    border: 2px solid rgba(255, 255, 255, 0.5);
    border-radius: 20px;
    // backdrop-filter: invert(0.2);
    filter: brightness(0.9);
    backdrop-filter: blur(10px);
    }
    .title-register{
        color: #fff;
        font-weight: normal;
    }

  .bg-mobile {
    display: none;
  }
}
.white-color{
    color: #fff;
}

::v-deep .el-scrollbar__wrap::-webkit-scrollbar {
  width: 7px;
}

//el-select-dropdown__wrap el-scrollbar__wrap

::v-deep .el-scrollbar__wrap::-webkit-scrollbar-thumb {
  background-color: #888888; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
  //border: 1px solid #555555;  /* creates padding around scroll thumb */
}

::v-deep .el-select-dropdown__list {
  overflow: hidden;
}

::v-deep .el-select-dropdown__wrap::-webkit-scrollbar-thumb {
  background-color: #888888; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
  //border: 1px solid #555555;  /* creates padding around scroll thumb */
}

::v-deep .el-select-dropdown__wrap {
  overflow: hidden;
}

::v-deep .el-select-dropdown__list {
  overflow: hidden !important;
}

::v-deep .el-scrollbar__wrap {
  overflow: hidden !important;
}

::v-deep .el-select-dropdown__list:hover {
  overflow: hidden !important;
}

::v-deep .el-select-dropdown__wrap:hover {
  overflow: hidden !important;
}

::v-deep .no-capital-case-placeholder input::placeholder {
  text-transform: initial !important;
}
@media  (min-width: 600px) and (max-width: 1024px){
    .intro{
    max-width: 70%;
  }
  
}
@media screen and (max-width: 1024px) {
  // mobile
  .kanji-convert-input {
    text-align: center;
    }
  .no-nav-view-content {
    height: 100vh;
  }
  .intro-wrap{
    padding: 0 0 20px;
    margin-right: 20px;
    margin-top: 5vh;
  }
  .policy{
    color: black;
  }
  .bg-mobile {
    height: 260px;
    background-image: url("~@/assets/top/bg-main.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    padding: 70px 30px 30px 30px;
    color: white;
    font-weight: 500;
  }

  .kanji-convert-input .el-form {
    padding: 20px;
  }
  
  .meaning-option h3 {
    margin: 5px 0px;
  }

  .meaning-description {
    margin-bottom: 18px;
    max-width: calc(100vw - 100px);
  }

  .left-layout{
    display: none;
  }
  .hight-light{
    color: #e2b321;
    font-weight: 600;
    font-size: 17px;
    margin: 18px 0;
    line-height: 35px;
}
  
}

</style>
