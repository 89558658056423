<template>
    <RouterView/>
</template>

<script>
export default {
    name: "PrintLayout"
}
</script>

<style scoped>

</style>
